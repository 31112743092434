import moment from "moment";
import { useEffect, useState } from "react";
import CustomInputText from "../../Components/FormComponents/CustomInputText/CustomInputText";
import { useAuthContext } from "../../contexts/Authcontext/Authcontext";
import MainButton from "../../Components/MainButton/MainButton";
import { useLoaderContext } from "../../contexts/LoaderContext/LoaderContext";
import useFirebaseCRUD from "../../hooks/useFirebaseCRUD";
import GoBackButton from "../../Components/GoBackBtn/GoBackBtn";
import { BiChevronLeft } from "react-icons/bi";
import { useTranslation } from "react-i18next";
import DetailOptionCard from "../../Components/DetailOptionCard/DetailOptionCard";
import CustomModal from "../../Components/CustomModal/CustomModal";
import DetailBankAccountForm from "../../Components/DetailBankAccountForm/DetailBankAccountForm";
import DetailShippingAddressForm from "../../Components/DetailShippingAddressForm/DetailShippingAddressForm";
import styles from './Account.module.css';

import { Toast } from "primereact/toast";
import useAuth from "../../hooks/useAuth";
import CustomSelect from "../../Components/FormComponents/CustomSelect/CustomSelect";
import { useGlobalContext } from "../../contexts/GlobalContext/GlobalContext";
import toastFunction from "../../utils/toastFunction"
import OptionWithTitleAndText from "../../Components/OptionWithTitleAndText/OptionWithImgAndText.jsx";
import useMatchProvinceAndCity from "../../hooks/useMatchProvinceAndCity.js";
import { defProvinces } from "../../utils/defProvinces.js";
import { Divider } from "primereact/divider"
import CustomInputSwitch from "../../Components/FormComponents/CustomInputSwitch/CustomInputSwitch.jsx";
import InfoBox from "../../Components/InfoBox/InfoBox.jsx";
import copyValue from "../../utils/copyValue.js";
// componente de pagina
export default function Account() {

  const { toast, updateUserDataInAuth } = useAuth();
  const { userData, userSellerOwnerData } = useAuthContext();
  const userOwnerData = userSellerOwnerData || userData;
  console.log(userOwnerData)
  const { countries, waUrlHook } = useGlobalContext();
  const { globalLoading, setGlobalLoading } = useLoaderContext();
  const { updateDocument } = useFirebaseCRUD();
  const { t } = useTranslation("Account");

  const [names, setNames] = useState(userData?.first_names) || "";
  const [surnames, setSurnames] = useState(userData?.last_names || "");
  const [cellphone, setCellphone] = useState(userData?.main_cellphone);


  const [businessName, setBusinessName] = useState(userData?.business_name || "");
  const [email, setEmail] = useState(userData?.email || "");
 
  const [selectedBankAccount, setSelectedBankAccount] = useState(null);
  const [visibleBankAccountModal, setVisibleBankAccountModal] = useState(false);

  const [sellerBankAccounts, setSellerBankAccounts] = useState([]);
  const [sellerShippingAddresses, setSellerShippingAddresses] = useState([]);
  
  const [sellerOwnerData, setSellerOwnerData] = useState(null);

  const [selectedShippingAddress, setSelectedShippingAddress] = useState(null);
  const [visibleShippingAddressModal, setVisibleShippingAddressModal] = useState(false);
  const isInsider = userData?.user_custom_claims?.type === "insider"  ? true : false;
  const isMaster = userData?.user_custom_claims?.type === "master" && userData?.user_custom_claims.is_master && userData.user_custom_claims.isAdmin
  const isCoworker = !userData?.isAdmin  && userData?.related_seller_id && userData?.is_coworker
  const collectionName = isMaster ? "master_user" : isInsider ? "insider_users" : "seller_users"; 

  const [selectedTypeSeller, setSelectedTypeSeller] = useState(!isInsider && !isMaster? userData?.ux_type : {});
  const [country, setCountry] = useState(userOwnerData?.country || { name: "Colombia", code: "+57" });
  
  const [city, setCity] = useState(userOwnerData?.city);
  const [ shippingReturnCity, setShippingReturnCity] = useState(userOwnerData?.shipping_return_city);

  const [ province, setProvince ] = useState(userOwnerData?.province || {});
  const [ shippinReturnProvince, setShippinReturnProvince ] = useState(userOwnerData?.shipping_return_province || {});
  
  const [ businessAddress, setBusinessAddress ] = useState(userOwnerData?.business_address || null);
  const [ shippingReturnBusinessAddress, setShippingReturnBusinessAddress ] = useState(userOwnerData?.shipping_return_business_address || "");

  const { zones, cities} = useMatchProvinceAndCity(province);

  const { cities: shippingReturnCities } = useMatchProvinceAndCity(shippinReturnProvince);

  const [ isSupplier, setIsSupplier ] = useState(userOwnerData?.is_supplier || null)

  const userNewValues = { 
    ...userData,
    email,
    first_names: names,
    last_names: surnames,
    business_name: businessName,
    business_address: businessAddress,
    shipping_return_business_address: shippingReturnBusinessAddress,

    province: province,
    shipping_return_city: shippingReturnCity??null,
    shipping_return_province: shippinReturnProvince?? null,
    
    user_full_name: names + " " + surnames,
    city,
    country,
    country_name: country?.name?? null,
    country_code: country?.code?? null,
    main_cellphone: cellphone,
    full_number: country?.code + cellphone,
    ux_type:selectedTypeSeller,
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setGlobalLoading(true);
    const documentId = userData?.uid;
    userNewValues.is_supplier = isSupplier;
  
    if(!userData?.sign_up_with_phone) {
      const properties = {
        phoneNumber: userNewValues.full_number
      };
      const result = await updateUserDataInAuth(collectionName, userData.uid, properties, {...userNewValues, sign_up_with_phone: true});
      console.log(result)
      setGlobalLoading(false);
      if (result.data.success) {
        toastFunction(toast, "succes", "Se actualizaron los datos con exito", "", 3000)
      }
    }else {
      const result = await updateDocument(collectionName, documentId, userNewValues);
      setGlobalLoading(false);
      if (result) {
        toast?.current?.show({
          severity: "success",
          summary: "Se actualizaron los datos con exito",
          detail: "",
        });
      }else{
        toast?.current?.show({
          severity: "error",
          summary: "Hubo un error al actualizar",
          detail: "",
        });
      }
    }
  };
  const openBankAccountModal = (account) => {
     setVisibleBankAccountModal(true);
    if (account.id) {
      setSelectedBankAccount(account);
    } else {
      setSelectedBankAccount({});
    }
  };
  const closeBankAccountModal = () => {
    setVisibleBankAccountModal(false);
  };
  const openShippingAddressModal = (account) => {
    setVisibleShippingAddressModal(true);
   if (account.id) {
     setSelectedShippingAddress(account);
   } else {
     setSelectedShippingAddress({});
   }
  };
  const closeShippingAddressModal = () => {
    setVisibleShippingAddressModal(false);
  };

  const copyValues = async (text) => {
    copyValue(text, toast);
  };

  const shareReferralCode = async () => {
    const message = `Hola, te comparto mi codigo de registro *${userOwnerData?.own_referrer_code}* \n\n *https://supplaii.com/signup/?referralCode=${userOwnerData?.own_referrer_code}*`;
    waUrlHook("withoutPhone", null, message);
  }

  useEffect(() => {
    setGlobalLoading(true);
    if((isCoworker || isInsider) && userSellerOwnerData) {
      setSellerOwnerData(userSellerOwnerData);
      setBusinessName(userSellerOwnerData?.business_name)
      setSellerShippingAddresses(userSellerOwnerData?.shipping_address)
      setSellerBankAccounts(userSellerOwnerData?.bank_accounts)
    } else {
      setSellerOwnerData(userData);
      setSellerBankAccounts(userData?.bank_accounts)
      setSellerShippingAddresses(userData?.shipping_address)
    }
    setGlobalLoading(false);
  }, [userData, userSellerOwnerData]);

  return (
    <article>
      
      <div className="mb-4">
        <GoBackButton icon={BiChevronLeft} text={t("labelGoBackBtn")} />
      </div>
      <div>
        <form onSubmit={handleUpdate}>
          <div className="grid p-4">
            <span>
              <span className="font-bold"> BUSINESS_ID: </span> {userNewValues?.business_id}
            </span>
            <span>
              <span className="font-bold"> BUSINESS_NAME:  </span> {userNewValues?.business_name}
            </span>
            <span>
              <span className="font-bold"> SELLER_ID: </span> {userNewValues?.uid}
            </span>
          </div>
          <CustomInputText
            required={true}
            floatLabel={true}
            type="text"
            value={businessName}
            placeholder={t("input-placeholders.businessName")}
            onChange={(e) => setBusinessName(e.target.value)}
            disabled={isCoworker || isInsider}
          />
          <CustomInputText
            required={true}
            floatLabel={true}
            type="text"
            value={names}
            placeholder={t("input-placeholders.firstNames")}
            onChange={(e) => setNames(e.target.value)}
          />
          <CustomInputText
            required={true}
            floatLabel={true}
            type="text"
            value={surnames}
            placeholder={t("input-placeholders.lastNames")}
            onChange={(e) => setSurnames(e.target.value)}
          />
          <CustomSelect required={false} floatLabel={true} optionLabel="name" options={countries} valueTemplate="" itemTemplate="" value={country} placeholder="Pais" onChange={(e) => setCountry(e.value)} />
          <Divider/>

          {/* DIRECCION DEL NEGOCIO */}
    <h5>Direccion del negocio</h5>
          <CustomSelect
            floatLabel={true}
            optionLabel={"province_name"}
            options={defProvinces(zones)}
            valueTemplate={""}
            itemTemplate={""}
            value={province}
            placeholder={t("input-placeholders.province")}
            onChange={(e) => {
              setProvince(e.target.value)
            }}
            disabled={globalLoading || isCoworker || isInsider}

          />
            <CustomSelect
              floatLabel={true}
              optionLabel={"city_name"}
              options={cities}
              valueTemplate={""}
              itemTemplate={""}
              value={city}
              placeholder={t("input-placeholders.city")}
              onChange={(e)=> setCity(e.target.value)}
              disabled={globalLoading  || !province || isCoworker || isInsider}

            />
            <CustomInputText
              required={true}
              floatLabel={true}
              type="text"
              value={businessAddress}
              placeholder={t("input-placeholders.businessAddress")}
              onChange={(e) => setBusinessAddress(e.target.value)}
              disabled={globalLoading || isCoworker || isInsider}
            />
          <Divider/>
          {/* DIRECCION PARA DEVOLUCIONES*/}
         {isSupplier && ( <div>
            <h5>Direccion para devoluciones</h5>

                  <CustomSelect
                    floatLabel={true}
                    optionLabel={"province_name"}
                    options={defProvinces(zones)}
                    valueTemplate={""}
                    itemTemplate={""}
                    value={shippinReturnProvince}
                    placeholder={t("input-placeholders.province")}
                    onChange={(e) => {
                      setShippinReturnProvince(e.target.value)
                    }}
                    disabled={globalLoading || isCoworker || isInsider}
                  />
                    <CustomSelect
                      floatLabel={true}
                      optionLabel={"city_name"}
                      options={shippingReturnCities}
                      valueTemplate={""}
                      itemTemplate={""}
                      value={shippingReturnCity}
                      placeholder={t("input-placeholders.city")}
                      onChange={(e)=> setShippingReturnCity(e.target.value)}
                      disabled={globalLoading || isCoworker || isInsider || !province}
                    />
                    <CustomInputText
                      required={true}
                      floatLabel={true}
                      type="text"
                      value={shippingReturnBusinessAddress}
                      placeholder={t("input-placeholders.shippingReturnBusinessAddress")}
                      onChange={(e) => setShippingReturnBusinessAddress(e.target.value)}
                      disabled={globalLoading || isCoworker || isInsider }
                    />
          </div>)}
          <Divider/>

          <CustomInputText
            required={true}
            floatLabel={true}
            disabled={userData?.sign_up_with_phone}
            type="number"
            value={cellphone}
            placeholder={`${userData?.country_code ?? ""} ${t("input-placeholders.cellphone")}`}
            onChange={(e) => setCellphone(e.target.value)}
          />
          <CustomInputText
            floatLabel={true}
            disabled={userData?.sign_up_with_email}
            type="text"
            value={email}
            placeholder={t("input-placeholders.email")}
            onChange={(e) => setEmail(e.target.value)}
          />

            <div className ={styles.inputSwitch}>
                <CustomInputSwitch checked={isSupplier} onChange={()=>setIsSupplier(!isSupplier)} />
                <label onClick={() => setIsSupplier(true)} className='clickable' style={{color: "var(--font-body-color)"}}>¿Eres proveedor?</label>
            </div>
           <h5 className='mt-2'>¿Con que tipo de vendedor de identificas mas?</h5>
           <div className='gridCardContainer mt-4 mb-6 '>
            <OptionWithTitleAndText
              optionData={{
                data: "online_seller",
                id: "online_seller",
                isSelected: selectedTypeSeller,
                selectFunction: setSelectedTypeSeller,
                title: "Online",
                text: "Tienes experiencia en ecommerce y manejo de herramientas digitales, gestionando ventas y tiendas través de plataformas en línea."
              }}
            />
            <OptionWithTitleAndText 
              optionData={{
                data: "independent_seller",
                id: "independent_seller",
                isSelected: selectedTypeSeller,
                selectFunction: setSelectedTypeSeller,
                title: "Independiente",
                text: "Trabajas por tu cuenta y manejas ventas directas sin una plataforma específica"
              }}
            />
            <OptionWithTitleAndText 
              optionData={{
                data: "catalog_seller",
                id: "catalog_seller",
                isSelected: selectedTypeSeller,
                selectFunction: setSelectedTypeSeller,
                title: "Catálogo",
                text: "Vendes productos a través de catálogos físicos o digitales, típicamente trabajando con compañías de ventas directas o multinivel"
              }}   
            />
          </div>
          <div className="mt-4 mb-4">
            <InfoBox labelBtn={"COMPARTIR CODIGO DE REFERIDO"} text={userOwnerData?.own_referrer_code} copyCode={() => copyValues(userOwnerData?.own_referrer_code)} onClick={() => shareReferralCode()} />
          </div>
          <div className="mt-8">
            <MainButton loading={globalLoading} label={t("updateData")} />
          </div>
        </form>
      </div>
      <div className="mt-12">
        <h3 className="mb-2"> {t("labelMyBanks")}</h3>
        {sellerBankAccounts?.length > 0 ?
          sellerBankAccounts?.map((account, index) => {
            const createdAt = new Date(
              account?.created_at?.seconds * 1000 +
                account?.created_at?.nanoseconds / 1000000
            );
            return (
              <DetailOptionCard
                bold_text={account?.alias_account}
                regular_text={account?.number_account}
                statusLabel={`${t("dateLabel")} ${moment(createdAt).format(t("dateFormat"))}`} // Aquí se formatea la fecha
                key={index}
                onClick={() => openBankAccountModal(account)}
                hidde_btn={false}
              />
            );
          }) : <h4></h4>}
        <CustomModal
          visible={visibleBankAccountModal}
          setVisible={setVisibleBankAccountModal}
          object={selectedBankAccount}
          editMode={t("updateBankAccount")}
          createMode={t("createBankAccount")}
          content={
            <DetailBankAccountForm
              onClose={setVisibleBankAccountModal}
              selectedBankAccount={selectedBankAccount}
              userData={sellerOwnerData}
              labelDelete={t("deleteBankAccount")}
              toast={toast}
              isInsider={isInsider}
              isMaster={isMaster}
            />
          }
          onClose={closeBankAccountModal}
        />
         <CustomModal
          visible={visibleShippingAddressModal}
          setVisible={setVisibleShippingAddressModal}
          object={selectedShippingAddress}
          editMode={t("updateShippingAddress")}
          createMode={t("createShippingAddress")}
          content={
            <DetailShippingAddressForm
              selectedShippingAddress={selectedShippingAddress}
              userData={sellerOwnerData}
              labelDelete={t("deleteShippingAddress")}
              onClose={setVisibleShippingAddressModal}
              toast={toast}
              isInsider={isInsider}
              isMaster={isMaster}
            />
          }
          onClose={closeShippingAddressModal}
        />
        <div className="mt-8">
          <MainButton
            loading={globalLoading}
            label={t("addBankAccount")}
            onClick={openBankAccountModal}
          />
        </div>
      </div>
      <div className="mt-12">
        <h3 className="mb-2"> {t("labelMyShippingAddress")}</h3>
        {sellerShippingAddresses?.length > 0 ?
          sellerShippingAddresses?.map((shippingAddress, index) => {
            const createdAt = new Date(
              shippingAddress?.created_at?.seconds * 1000 +
                shippingAddress?.created_at?.nanoseconds / 1000000
            );
            return (
              <DetailOptionCard
                bold_text={shippingAddress?.address_label}
                regular_text={`${shippingAddress?.country?.name} / ${shippingAddress.city?.city_name} `}
                statusLabel={`${t("dateLabel")} ${moment(createdAt).format(t("dateFormat"))}`} // Aquí se formatea la fecha
                key={index}
                onClick={() => openShippingAddressModal(shippingAddress)}
                hidde_btn={false}
              />
            );
          }) : <h4></h4>}
  
        <div className="mt-8">
          <MainButton
            loading={globalLoading}
            label={t("addShippingAddress")}
            onClick={openShippingAddressModal}
          />
        </div>
      </div>
      <Toast ref={toast} />;

    </article>
  );
}
