import styles from './CustomInputNumber.module.css';
import { InputNumber } from 'primereact/inputnumber';

export default function CustomInputNumber({key, onInput, id, floatLabel, value, useGrouping, placeholder, onChange, minFractionDigits, maxFractionDigits, min, max, prefix, suffix, mode, currency, locale, disabled, required, onBlur, onFocus}) {
  
  if(floatLabel){
    return (
      <div className={styles.floatLabelContainer}>
        <span className="p-float-label">
          <InputNumber
          key={key}
            id={id}
            value={value}
            useGrouping={useGrouping}
            onChange={onChange}
            className={styles.customFormComponent}
            minFractionDigits={minFractionDigits}
            maxFractionDigits={maxFractionDigits}
            min={min}
            max={max}
            onBlur={onBlur}
            onFocus={onFocus}
            prefix={prefix}
            suffix={suffix}
            mode={mode}
            currency={currency}
            locale={locale}
            disabled={disabled}
            required={required}
            autoComplete="off"
            onInput={onInput}

          />
          <label htmlFor={id}>{placeholder}</label>
        </span>
      </div>

    )
  }else{
    return (
      <InputNumber
      key={key}

        value={value}
        placeholder={placeholder}
        onChange={onChange}
        className={styles.customFormComponent} 
        minFractionDigits={minFractionDigits}
        maxFractionDigits={maxFractionDigits}
        min={min}
        max={max}  onInput={onInput}

        onBlur={onBlur}
        onFocus={onFocus}
        prefix={prefix}
        suffix={suffix}
        mode={mode}
        currency={currency}
        locale={locale}
        disabled={disabled}
        required={required}
        autoComplete="off"
      />
    )
  }

}
