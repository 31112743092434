/* eslint-disable react/prop-types */
import { Button } from "primereact/button"; // Asegúrate de importar el botón correctamente
import CustomInputText from "../../../Components/FormComponents/CustomInputText/CustomInputText";
import { useTranslation } from "react-i18next";
import CustomSelect from "../../../Components/FormComponents/CustomSelect/CustomSelect";
import CustomModal from "../../../Components/CustomModal/CustomModal";
import WhatsAppCRM from "../../../Components/WhatsAppCRM/WhatsAppCRM";
import { CHATMESSAGE, INITIALNUMBEROFCHATS } from "../../../Components/WhatsAppCRM/utils/CRMconstants";
import { useEffect, useRef, useState } from "react";
import { useChatMenu } from "../../../Components/WhatsAppCRM/ChatWindow/ChatMenuContext";
import useFirebaseCRUD from "../../../hooks/useFirebaseCRUD";
import toastFunction from "../../../utils/toastFunction";
import { Toast } from "primereact/toast";
import useOverlayPanel from "../../../hooks/useOverlayPanel";
import DetailProductItemForm from "../../../Components/DetailProductItemForm/DetailProductItemForm";
import { useAuthContext } from "../../../contexts/Authcontext/Authcontext";
import { collection, doc, getDoc, query, where } from "firebase/firestore";
import { db, storage } from "../../../firebase/firebase";
import { OverlayPanel } from "primereact/overlaypanel";
import CustomTable from "../../../Components/CustomTable/CustomTable";
import { OrderitemsTableColumns } from "../../MyOrders/scripts/OrderTableColumns";
import { deleteObject, getDownloadURL, listAll, ref, uploadBytes } from "firebase/storage";
import generateValidObjectName from "../../../utils/generateValidObjectName";
import { generateRandomId } from "../../../utils/generateRandomId";
import isValidUrl from "../../../utils/isValidUrl";
import { fetchFromCloudFunction } from "../../../services/cloudFunctinons/fetchFromCloudFunction";
import { FileUpload } from 'primereact/fileupload';
import { download_pdf_file } from "../../../utils/downloadGuidePdf/pdfBase64";
import CustomTextArea from "../../../Components/FormComponents/CustomTextArea/CustomTextArea";


const actionsHistoryObject = {
  task_id: null,
  payload: null,
  status: 'done',
  executed_at: new Date(),
};

const shouldDisableTaskBasedOnPrevious = (pqrData, taskId) => {
  const tasksDone = pqrData?.tasks_done || [];
  const taskNumber = Number(taskId);
  if ( pqrData?.solution?.solution && pqrData?.PQR_type == "CAMBIO") {
    return false
  }
  // console.log(`\n🔍 Validando tarea: ${taskId}`);
  // console.log(`📌 Tareas completadas:`, tasksDone);

  if (taskNumber === 1) {
      // console.log("✅ Primera tarea, nunca se deshabilita.");
      return false;
  }

  const previousTaskId = String(taskNumber - 1);
  const isPreviousTaskDone = tasksDone.includes(previousTaskId);
  
  // console.log(`🔄 Tarea anterior (${previousTaskId}) completada:`, isPreviousTaskDone);

  return !isPreviousTaskDone;
};

const shouldDisableTaskBasedOnCompletion = (pqrData, taskId) => {
  const tasksDone = pqrData?.tasks_done || [];
  const taskOccurrencesInHistory = pqrData?.actions_history?.filter(action => action?.task_id === taskId) || [];
  const isTaskInDone = tasksDone.includes(taskId);
  const isTaskInHistory = taskOccurrencesInHistory.length > 0;

  // console.log(`✅ La tarea ${taskId} está en tasks_done:`, isTaskInDone);
  // console.log(`📜 La tarea ${taskId} tiene ${taskOccurrencesInHistory.length} apariciones en actions_history:`, isTaskInHistory);

  return (isTaskInDone && isTaskInHistory);
};



// eslint-disable-next-line no-unused-vars
export function ActionRevisarCambio(pqrs, extraPayload = {}) {
  const handleClick = async () => {
    // const docRef = doc(db, "pqrs", pqrs?.id); // Referencia al documento
    // const payload = {
    //   revisado: true,
    //   ...extraPayload
    // };

    try {
      // await updateDoc(docRef, {
      //   revisado: true,
      //   updatedAt: serverTimestamp()
      // });

      // await updateDoc(docRef, {
      //   actionHistory: arrayUnion({
      //     taskId: "cambio-1",
      //     payload,
      //     status: "done",
      //     executeAt: serverTimestamp()
      //   })
      // });

      console.log("Acción 'revisar cambio' ejecutada y registrada");
    } catch (error) {
      console.error("Error al ejecutar", error);
      throw error;
    }
  };

  return (
    <Button
      label="Acción"
      onClick={handleClick}
      className=" p-button-outlined"
    />
  );
}

export function ActionContactarCliente({ pqrData, rowData, toast }) {
  const { 
    handleMessageSelect, 
    handleSidebarSelect, 
    setIsWidgetView, 
    setFocusTextArea, 
    setNumberChatDocuments, 
    setTextValue 
  } = useChatMenu();

  const { addToArray } = useFirebaseCRUD();
  const [visibleWhastappCRMModal, setVisibleWhastappCRMModal] = useState(false);

  // Crear una copia local del objeto para evitar modificarlo globalmente
  const actionsHistoryData = { ...actionsHistoryObject, task_id: rowData.id };


  const handleOpenWhatsappCRM = async () => {
    try {
      setNumberChatDocuments(INITIALNUMBEROFCHATS);
      setIsWidgetView(true);
      await handleMessageSelect(pqrData?.relatedOrder?.customer_phone_number, true);
      console.log("Después de handleMessageSelect");
      setVisibleWhastappCRMModal(true);
      setFocusTextArea(true);
      handleSidebarSelect(CHATMESSAGE);
      await addToArray("pqrs", pqrData.id, "actions_history", actionsHistoryData);
      await addToArray("pqrs", pqrData.id, "tasks_done", actionsHistoryData.task_id);
    } catch (error) {
      console.error("Error en handleOpenWhatsappCRM:", error);
      toastFunction(toast, "error", `La tarea ${rowData.id} no pudo ser completada`, error);
    }
  };

  const handleOpenWhatsappCRMWithMessage = async () => {
    console.log("Seteando mensaje inicial...");
    setTextValue("mensaje foo");
    await handleOpenWhatsappCRM();
  };

  // Validación de si la tarea ya ha sido marcada como completada
  const validationShouldDisableTaskBasedOnPrevious  =  shouldDisableTaskBasedOnPrevious (pqrData, rowData.id)
  const validationShouldDisableTaskBasedOnCompletion =  shouldDisableTaskBasedOnCompletion (pqrData, rowData.id)
  const validationTaskIsCheckedInPqr = validationShouldDisableTaskBasedOnPrevious || validationShouldDisableTaskBasedOnCompletion;

  return (
    <>
      {!validationTaskIsCheckedInPqr ? (
        <div className="flex gap-4">
          <Button
            label="Contactar con mensaje inicial"
            onClick={handleOpenWhatsappCRMWithMessage}
            className="p-button-outlined"
          />
          <Button
            label="Abrir chat interno"
            onClick={handleOpenWhatsappCRM}
            className="p-button-outlined"
          />
        </div>
      ) : (
        <div>
          <p className="text-base">Cliente contactado</p>
        </div>
      )}
      
      <CustomModal
        visible={visibleWhastappCRMModal}
        setVisible={setVisibleWhastappCRMModal}
        object={{}}
        content={<WhatsAppCRM />}
        onClose={() => setVisibleWhastappCRMModal(false)}
      />

      <Toast ref={toast} />
    </>
  );
}

export function ActionSeleccionarTipoPQR({pqrData, rowData, toast}) {
  const { updateDocument } = useFirebaseCRUD();
  const updateSelectionType = async (type) => {
    try {
      const resultUpdateSelectionType = await updateDocument("pqrs", pqrData.id, {PQR_type: type})
      if (resultUpdateSelectionType) {toastFunction(toast, "success", "Seleccion de tipo de pqr exitosa", ` Tarea ${rowData?.task_id} completada`)} 
    } catch (error) {
      toastFunction(toast, "error", "No se pudo seleccionar el tipo de PQR", ` Tarea ${rowData?.task_id} no pudo ser completada`)
    }
  }
 

  const options = [
    { label: "Cambio", type: "CAMBIO" },
    { label: "Garantia", type: "GARANTIA" },
    { label: "Retracto", type: "RETRACTO" },
  ];
  
  return (
    <div className="flex gap-4">
      {options.map(({ label, type,  }) =>
        (pqrData?.PQR_type === "RECIBIDO" || pqrData?.PQR_type === type) && (
          <Button
            key={type}
            label={label}
            onClick={() => updateSelectionType(type)}
            className="p-button-outlined"
            disabled={pqrData?.PQR_type !== "RECIBIDO"}
          />
        )
      )}
    </div>
  );
  
}

export function ActionCerrarPorTiempoEspera({pqrData, rowData, toast}) {
  const { updateDocument, addToArray  } = useFirebaseCRUD();


  const handleCerrarPorTiempo = async () => {
    const actionsHistoryData = { ...actionsHistoryObject, task_id: rowData.id, payload: `Solución ${rowData.id} cerrado por tiempo de espera ` };
    try {
    // Agregar datos a Firestore
      await updateDocument("pqrs", pqrData.id, {
        closed_by_exceeded_timeout: true,
      });

      await addToArray("pqrs", pqrData.id, "actions_history", actionsHistoryData);
      await addToArray("pqrs", pqrData.id, "tasks_done", rowData.id); 
      toastFunction(toast, "success", "PQR cerrado con exito", "", 3000)
    } catch (error) {
      toastFunction(toast, "error", "La tarea no pudo ser completada con exito", "", 3000)
    }
  }; 
  const validationShouldDisableTaskBasedOnPrevious  =  shouldDisableTaskBasedOnPrevious (pqrData, rowData.id)
  const validationShouldDisableTaskBasedOnCompletion =  shouldDisableTaskBasedOnCompletion (pqrData, rowData.id)
  const validationTaskIsCheckedInPqr = validationShouldDisableTaskBasedOnPrevious || validationShouldDisableTaskBasedOnCompletion;
  return (
    <Button
      label="Cerrar PQR"
      onClick={handleCerrarPorTiempo}
      className=" p-button-outlined"
      disabled={validationTaskIsCheckedInPqr}
    />
  );
}

export function ActionAñadirSolucionPedido({ rowData, pqrData, toast }) {

  const { addToArray, updateDocument } = useFirebaseCRUD();
  const { t } = useTranslation("MyOrders");
  const [orderIdToFreeze, setOrderIdToFreeze] = useState(pqrData?.solution?.frozen_order_id  || "");

  const handleAñadirSolucionPedido = async (answer) => {
    try {
      if (orderIdToFreeze && orderIdToFreeze !=="" ) {
        const documentRef = doc(db, "orders", orderIdToFreeze); // Reemplaza 'nombre_coleccion' con el nombre de tu colección
        // Obtiene el documento
        const orderExists = await getDoc(documentRef);
        // Verificar si la orden existe en Firestore
        if (!orderExists.exists()) {
          toastFunction(toast, "warn", "La orden no existe en el sistema.");
          return;
        }
      }


      // Datos de historial de acciones
      const actionsHistoryData = { ...actionsHistoryObject, task_id: rowData.id, payload: `Solución ${answer}` };
      // Agregar datos a Firestore
      await addToArray("pqrs", pqrData.id, "actions_history", actionsHistoryData);
      await addToArray("pqrs", pqrData.id, "tasks_done", rowData.id);
      
      if (answer) {
        await updateDocument("orders", orderIdToFreeze, { frozen_order_by_PQR: answer });
        await updateDocument("pqrs", pqrData.id, { solution: {frozen_order_id: orderIdToFreeze, solution: true} });

      }else {
        await updateDocument("pqrs", pqrData.id, { solution: {frozen_order_id: null, solution: false} });
      }

      toastFunction(toast, "success", `La tarea ${rowData?.id} fue completada`, "", 3000);
    } catch (error) {
      console.error("Error en handleAñadirSolucionPedido:", error);
      toastFunction(toast, "error", `La tarea ${rowData.id} no pudo ser completada`, error, 3000);
    }
  };

  const openOrderInNewTab = () => {
    window.open(`myOrders?checkoutId=${pqrData?.checkout_id}`, "_blank");
  };

  // Validaciones
  const validationShouldDisableTaskBasedOnPrevious  =  shouldDisableTaskBasedOnPrevious (pqrData, rowData.id)
    const validationShouldDisableTaskBasedOnCompletion =  shouldDisableTaskBasedOnCompletion (pqrData, rowData.id)
    const validationTaskIsCheckedInPqr = validationShouldDisableTaskBasedOnPrevious || validationShouldDisableTaskBasedOnCompletion;

  const pqrHasSolution = pqrData?.solution?.solution;

  return (
    <div className="flex gap-4">
      <CustomInputText
        value={orderIdToFreeze || ""}
        onChange={(e) => setOrderIdToFreeze(e.target.value)}
        placeholder={t("input-placeholders.idOrder")}
        disabled={validationTaskIsCheckedInPqr}
        inColumTable={true}
      />
      <Button
        label="Sí"
        onClick={() => handleAñadirSolucionPedido(true)}
        className="p-button-outlined"
        disabled={validationTaskIsCheckedInPqr}
      />
      <Button
        label="No"
        onClick={() => handleAñadirSolucionPedido(false)}
        className="p-button-outlined"
        disabled={validationTaskIsCheckedInPqr}
      />
      {pqrHasSolution && <>
        <Button
          label="Ir al pedido congelado"
          onClick={openOrderInNewTab}
          className="p-button-outlined"
          disabled={!pqrHasSolution}
        />
        </>
      }
    <Toast ref={toast}/>
    </div>
  );
}

export function ActionIngresarItems({rowData, pqrData, toast}) {
  const { t } = useTranslation("MyOrders");
  const { getDocumentsByQuery, addToArray } = useFirebaseCRUD();
  const [visibleDetailProductItemForm, setVisibleItemForm] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [selectedOrderRowData, setSelectedOrderRowData] = useState({});
  const { userData } = useAuthContext();
  const isInsider = userData?.user_custom_claims?.type === "insider" || userData?.user_custom_claims?.type === "master"  ? true : false;
  const { opItems } = useOverlayPanel();
  const [filteredSellerStores, setFilteredSellerStores] = useState([]);

  const openItemOverlayPanel = async (e) => {
      console.log("pqrData", pqrData)
    setSelectedOrderRowData(pqrData?.relatedOrder); // Guardar los datos de la fila seleccionada en el estado
    if (isInsider) {
      const q = query(collection(db, 'seller_users'),
        where("uid", "==", pqrData?.relatedOrder?.related_seller_id),
     );
      const sellerOwnerData = await getDocumentsByQuery(q);
      const sellerOwnerStores = sellerOwnerData[0]?.seller_stores;
      setFilteredSellerStores(sellerOwnerStores);
    }
    opItems.current.toggle(e); // // Abre el overlay
  };

  const closeItemModal = () => {
    setVisibleItemForm(false);
  };

  const handleEdit = (rowData) => {
    setVisibleItemForm(true);
    if (rowData.id) {
      setSelectedItem(rowData);
    } else {
      setSelectedItem({});
    }
  };

  const checkMissingItems = (pqrData, selectedOrderRowData) => {
    // console.log("pqrData", pqrData?.line_items)
    // console.log("selectedOrderRowData", selectedOrderRowData)
    if (!selectedOrderRowData?.line_items || !pqrData?.line_items) {
      console.log("⚠️ No hay datos en selectedOrderRowData o pqrData.");
      return false;
    }
  
    // console.log("📦 Productos en selectedOrderRowData:", selectedOrderRowData.line_items);
    // console.log("📦 Productos en pqrData:", pqrData.line_items);
  
    // Crear un mapa para acumular la cantidad de cada producto en selectedOrderRowData
    const selectedCounts = selectedOrderRowData.line_items.reduce((acc, item) => {
      acc[item.product_id] = (acc[item.product_id] || 0) + item.quantity;
      return acc;
    }, {});
  
    // Crear un mapa para acumular la cantidad de cada producto en pqrData
    const pqrCounts = pqrData.line_items.reduce((acc, item) => {
      acc[item.product_id] = (acc[item.product_id] || 0) + item.quantity;
      return acc;
    }, {});
  
    // console.log("📊 Cantidades en selectedOrderRowData:", selectedCounts);
    // console.log("📊 Cantidades en pqrData:", pqrCounts);
  
    let hasMissingItems = false;
  
    // Comparar ambos mapas y verificar si falta algún producto
    Object.keys(selectedCounts).forEach((productId) => {
      const selectedQuantity = selectedCounts[productId] || 0;
      const pqrQuantity = pqrCounts[productId] || 0;
      const missingQuantity = selectedQuantity - pqrQuantity;
  
      // console.log(`📌 Producto ID: ${productId} - Necesarios: ${selectedQuantity}, En pqrData: ${pqrQuantity}, Faltan: ${missingQuantity}`);
  
      if (missingQuantity > 0) {
        hasMissingItems = true;
      }
    });
  
    // console.log("🔎 Resultado: ¿Faltan productos?", hasMissingItems);
    return hasMissingItems;
  };

  const handleCheckTask = async () => {
    const actionsHistoryData = { ...actionsHistoryObject, task_id: rowData.id, payload: `Solución ${rowData.id}` };
    // Agregar datos a Firestore
    await addToArray("pqrs", pqrData.id, "actions_history", actionsHistoryData);
    await addToArray("pqrs", pqrData.id, "tasks_done", rowData.id); 
  }

    // Validaciones
  // const validationTaskIsCheckedInPqr =
  //   pqrData.tasks_done?.includes(rowData?.id) &&
  //   pqrData?.actions_history?.some((actionHistory) => actionHistory?.task_id === rowData?.id);

  const validationShouldDisableTaskBasedOnPrevious  =  shouldDisableTaskBasedOnPrevious (pqrData, rowData.id)
  const validationShouldDisableTaskBasedOnCompletion =  shouldDisableTaskBasedOnCompletion (pqrData, rowData.id)
  const validationTaskIsCheckedInPqr = validationShouldDisableTaskBasedOnPrevious || validationShouldDisableTaskBasedOnCompletion;
  // console.log("validationTaskIsCheckedInPqr", validationShouldDisableTaskBasedOnPrevious , validationShouldDisableTaskBasedOnCompletion )

  return (
    <div className="flex gap-4">
      <Button
            type="button"
            icon="pi pi-search"
            label={pqrData?.line_items?.length}
            onClick={(e) => openItemOverlayPanel(e)}
            onMouseEnter={(e) => openItemOverlayPanel(e)}
            // onMouseLeave={(e) => {
            //   // Cierra el overlay si el ratón sale del área del botón
            //   if (opItems?.current && !opItems?.current?.container?.contains(e.relatedTarget)) {
            //     closeOverlayPanel();
            //   }
            // }}
          />
          <Button
            label="Completar tarea"
            onClick={handleCheckTask}
            className="p-button-outlined"
            disabled={validationTaskIsCheckedInPqr}
          />
          <CustomModal
            visible={visibleDetailProductItemForm}
            setVisible={setVisibleItemForm}
            object={selectedItem}
            editMode={t("editItemProduct")}
            createMode={t("createItemProduct")}
            content={
              <DetailProductItemForm
                pqrData={pqrData}
                lineItemsInPqr={true}
                filteredSellerStores={filteredSellerStores}
                productItemData={selectedItem}
                toast={toast}
                onClose={closeItemModal}
                selectedOrderRowData={selectedOrderRowData}
                onCloseOverlay={openItemOverlayPanel}
                // orders={[selectedOrderRowData]}
                setSelectedOrderRowData={setSelectedOrderRowData}
              />
            }
            onClose={closeItemModal}
          />
        <div className="card flex flex-column align-items-center gap-3">
          <OverlayPanel ref={opItems} showCloseIcon closeOnEscape={false} dismissable={false}>
            <CustomTable 
              selectionMode={"single"}
              className="custom-table"
              columns={OrderitemsTableColumns({t, handleEdit, selectedOrderRowData, isPqr:true, validationTaskIsCheckedInPqr})}
              data={pqrData?.line_items}
              action={() => {}} 
            />

            <div className="ml-10 mt-6 mb-4">
              <Button
                icon="pi pi-plus-circle"
                onClick={handleEdit}
                className="p-button-rounded p-button-outline"
                disabled={!checkMissingItems(pqrData, pqrData.relatedOrder)}
                />
            </div>
          </OverlayPanel>
        </div>
    </div>
  );
}

export function ActionPoliticaEnvioPQR({rowData, pqrData, toast}) {
  const {addToArray, updateDocument} = useFirebaseCRUD();
  const { t } = useTranslation("MyOrders");
  const [shippingArrivesFromCustomer, setShippingArrivesFromCustomer] = useState(pqrData?.shipping_policy_arrives|| null);
  const [shipingToCustomer, setShipingToCustomer] = useState(pqrData?.shipping_policy_comes || null);

  const shippingDirection = [
    {code: 1, label: "Origen"},
    {code: 2, label: "Destino"},
  ];

  const handleCheckTask = async () => {
    const actionsHistoryData = { ...actionsHistoryObject, task_id: rowData.id, payload: `Solución ${rowData.id}` };
    try {
    // Agregar datos a Firestore
      await updateDocument("pqrs", pqrData.id, {
        shipping_policy_arrives: { ...shippingArrivesFromCustomer },
        shipping_policy_comes: { ...shipingToCustomer },
        pqr_shipping_policy_resend_guide: shipingToCustomer?.code === 1? "origen" : "destino" ,
      });
      await addToArray("pqrs", pqrData.id, "actions_history", actionsHistoryData);
      await addToArray("pqrs", pqrData.id, "tasks_done", rowData.id); 
      toastFunction(toast, "success", "Tarea completada con exito", "", 3000)
    } catch (error) {
      toastFunction(toast, "error", "La tarea no pudo ser completada con exito", "", 3000)
    }
  };
  
  // const validationTaskIsCheckedInPqr =
  //   pqrData.tasks_done?.includes(rowData?.id) &&
  //   pqrData?.actions_history?.some((actionHistory) => actionHistory?.task_id === rowData?.id);
  const validationShouldDisableTaskBasedOnPrevious  =  shouldDisableTaskBasedOnPrevious (pqrData, rowData.id)
  const validationShouldDisableTaskBasedOnCompletion =  shouldDisableTaskBasedOnCompletion (pqrData, rowData.id)
  const validationTaskIsCheckedInPqr = validationShouldDisableTaskBasedOnPrevious || validationShouldDisableTaskBasedOnCompletion;

  return (
    <div className="flex gap-4">
      <div className="grid">

      <label>{t("input-placeholders.shipingArrivesFromCustomer")}</label>
      <CustomSelect
        optionLabel={"label"}
        options={shippingDirection}
        value={shippingArrivesFromCustomer}
        onChange={(e) => setShippingArrivesFromCustomer(e.value)}
        placeholder={t("input-placeholders.rta")}
        disabled={validationTaskIsCheckedInPqr}
        inColumTable={true}
      />
      </div>

      <div className="grid">
        <label>{t("input-placeholders.shipingToCustomer")}</label>
        <CustomSelect
          optionLabel={"label"}
          options={shippingDirection}
          value={shipingToCustomer}
          onChange={(e) => setShipingToCustomer(e.value)}
          placeholder={t("input-placeholders.rta")}
          disabled={validationTaskIsCheckedInPqr}
          inColumTable={true}
        />

      </div>
      <Button
        label="Completar tarea"
        onClick={handleCheckTask}
        className=" p-button-outlined"
        disabled={!shippingArrivesFromCustomer || !shipingToCustomer || validationTaskIsCheckedInPqr }
      />
      <Toast ref={toast}/>
      <div>
     
    </div>
    </div>
  );
}

export function ActionCargarGuiaEnviadaPorCliente({rowData, pqrData, toast}) {

  const { t } = useTranslation("MyOrders");
  const { updateDocument, addToArray } = useFirebaseCRUD();
  const fileUploadRef = useRef(null);


  const [customerGuideValues, setCustomerGuideValues] = useState(() => ({
      id: generateRandomId(),
      created_at: new Date(),
      guide_number: pqrData?.guide_customer_values?.guide_number || null,
      shipping_company: pqrData?.guide_customer_values?.shipping_company || null,
      file: pqrData?.guide_customer_values?.file || null,
      file_name: pqrData?.guide_customer_values?.file_name || null,
  }));

  const [showInputFile, setShowInputFile] = useState(!pqrData?.guide_customer_values?.file ? true :false);

  const handleCheckTask = async () => {
    const downloadURL = await uploadFileToFirebaseStorage(customerGuideValues.file, pqrData.id)
    setCustomerGuideValues((prevValues) => ({
      ...prevValues,
      file: downloadURL,
    }));
    const actionsHistoryData = { ...actionsHistoryObject, task_id: rowData.id, payload: `Solución ${rowData.id}` };
    try {
    // Agregar datos a Firestore
      await updateDocument("pqrs", pqrData.id, {
        guide_customer_values: {...customerGuideValues, file: downloadURL},
      
      });
      await addToArray("pqrs", pqrData.id, "actions_history", actionsHistoryData);
      await addToArray("pqrs", pqrData.id, "tasks_done", rowData.id); 
      toastFunction(toast, "success", "Tarea completada con exito", "", 3000)
    } catch (error) {
      toastFunction(toast, "error", "La tarea no pudo ser completada con exito", "", 3000)
    }
  };

  const uploadFileToFirebaseStorage = async (file, pqrdId) => {
    try {
        const storageRef = ref(storage);
        if(!file?.name) return null
        const validFileName = generateValidObjectName(file?.name);
        const filePath = `pqrs/${pqrdId}/guia_enviada_por_cliente/${validFileName}`;
        const fileRef = ref(storageRef, filePath);

       // Borrar toda la carpeta antes de subir el nuevo archivo
        const folderRef = ref(storageRef, `pqrs/${pqrdId}`);
        const folderContent = await listAll(folderRef);
        await Promise.all(folderContent.items.map(item => deleteObject(item)));

        // Subir el nuevo archivo
        await uploadBytes(fileRef, file);
        const downloadURL = await getDownloadURL(fileRef);
        toastFunction(toast, "success", "Archivo subido con exito", "", 3000)
        return downloadURL;
    } catch (error) {
        toastFunction(toast, "error", "El archivo no pudo ser subido con exito", error, 3000)
        console.error('Error al subir el archivo:', error);
        return null;
    }
  };

  const handleFileDelete = () => {
    setShowInputFile(true);
    if (fileUploadRef.current) {
      fileUploadRef.current.clear(); // 🔥 Borra el archivo en el componente
    }
    setCustomerGuideValues((prevValues) => ({
        ...prevValues,
        file: null,
        file_name: null,
    }));
  };   

  const openFileInNewTab = (fileUrl) => {
    if (!fileUrl) {
      console.error("❌ URL no válida");
      return;
    }
  
    window.open(fileUrl, "_blank");
  };

  const handleFileChange = (event) => {
    setShowInputFile(false);
    const uploadedFile = event.files?.[0]; // Obtiene el primer archivo
    if (!uploadedFile) return;

    setCustomerGuideValues((prevState) => ({
      ...prevState,
      file: uploadedFile,
      file_name: uploadedFile?.name,
    }));
  
  };


    const validationShouldDisableTaskBasedOnPrevious  =  shouldDisableTaskBasedOnPrevious (pqrData, rowData.id)
    const validationShouldDisableTaskBasedOnCompletion =  shouldDisableTaskBasedOnCompletion (pqrData, rowData.id)
    const validationTaskIsCheckedInPqr = validationShouldDisableTaskBasedOnPrevious || validationShouldDisableTaskBasedOnCompletion;
  return (
    <div className="flex  justify-center items-center gap-2">
        <div className="grid">
          <label>{t("input-placeholders.guideNumber")}</label>
          <CustomInputText
            value={customerGuideValues?.guide_number || ""}
            onChange={(e) => setCustomerGuideValues((prevState) => ({
              ...prevState, 
              guide_number: e.target.value
            }))}
            disabled={validationTaskIsCheckedInPqr}
            inColumTable={true}
          />
        </div>
      <div className="grid">
        <label>{t("input-placeholders.shippingCompany")}</label>
        <CustomInputText
          value={customerGuideValues?.shipping_company || ""}
          onChange={(e) => {
            const newValue = e.target.value;

            setCustomerGuideValues((prevState) => ({
              ...prevState,
              shipping_company: newValue,
            }));

          }}
          disabled={validationTaskIsCheckedInPqr}
          inColumTable={true}
        />

      </div>
      <div>
        {showInputFile && ( <FileUpload
            ref={fileUploadRef}
            mode="basic"
            name="demo[]"
            accept="image/*"
            maxFileSize={1000000}
            customUpload={true}
            auto={false}
            chooseLabel="Seleccionar archivo"
            onSelect={handleFileChange}
          />)}

          {customerGuideValues?.file_name && (
            <div>
              <p className="text-base">Archivo seleccionado: {customerGuideValues?.name || customerGuideValues?.file_name}</p>
            </div>
          )}
      </div>

      <div className="flex gap-4">
        {isValidUrl(customerGuideValues?.file) && (
          <Button
            label="Descargar"
            onClick={() => openFileInNewTab(customerGuideValues?.file)}
            className=" p-button-outlined"
            disabled={!customerGuideValues?.file}
          />
          )
        }
          <Button
          label="Borrar"
          onClick={() => handleFileDelete()}
          className=" p-button-outlined"
          disabled={!customerGuideValues?.file}
        />
       
        
          <Button handleCheckTask
            label={"Completar tarea"}
            onClick={handleCheckTask}
            className=" p-button-outlined"
            disabled={!customerGuideValues.guide_number || !customerGuideValues.shipping_company}
          />
      </div>
      <Toast ref={toast}/>
    </div>
  );
}

export function ActionProcedePQR({rowData, pqrData, toast}) {
  const { t } = useTranslation("MyOrders");
  const { updateDocument, addToArray} = useFirebaseCRUD();
  const [ observationValue, setObservationValue ] = useState(pqrData?.pqr_proceeding?.observation || null);
  const  handleProcedePQR = async (answer) => {
    const actionsHistoryData = { ...actionsHistoryObject, task_id: rowData.id, payload: `Solución ${answer ? "SI": "NO" } ${rowData.id} - ${observationValue}` };
    try {
        if(answer) {
          if (pqrData?.solution?.solution) {
            const frozenOrderId =  pqrData?.solution?.frozen_order_id;
            await updateDocument("orders", frozenOrderId, { frozen_order_by_PQR: false });
          }
        }
      // Agregar datos a Firestore
        await updateDocument("pqrs", pqrData.id, {
          pqr_proceeding: {observation: observationValue, pqr_proceeding: answer},
        
        });
        await addToArray("pqrs", pqrData.id, "actions_history", actionsHistoryData);
        await addToArray("pqrs", pqrData.id, "tasks_done", rowData.id); 
        toastFunction(toast, "success", "Tarea completada con exito", "", 3000)
      } catch (error) {
        toastFunction(toast, "error", "La tarea no pudo ser completada con exito", "", 3000)
      }
  };


  const validationShouldDisableTaskBasedOnPrevious  =  shouldDisableTaskBasedOnPrevious (pqrData, rowData.id)
  const validationShouldDisableTaskBasedOnCompletion =  shouldDisableTaskBasedOnCompletion (pqrData, rowData.id)
  const validationTaskIsCheckedInPqr = validationShouldDisableTaskBasedOnPrevious || validationShouldDisableTaskBasedOnCompletion;
  return (
    <div className=" flex gap-4">
      <CustomInputText
        value={observationValue || ""}
        onChange={(e) => setObservationValue( e.target.value)}
        placeholder={t("input-placeholders.observation")}
        // disabled={!rowEditStates[rowData.id]}
        inColumTable={true}
        disabled={validationTaskIsCheckedInPqr}
      />
      <Button
        label="Sí"
        onClick={() => handleProcedePQR(true)}
        className="p-button-outlined"
        disabled={validationTaskIsCheckedInPqr}
      />
      <Button
        label="No"
        onClick={() => handleProcedePQR(false)}
        className="p-button-outlined"
        disabled={validationTaskIsCheckedInPqr}
      />
      <Toast ref={toast}/>
    </div>
  );
}

export function ActionEmpacar({rowData, pqrData, toast}) {
  const { t } = useTranslation("MyOrders");
  const {addToArray} = useFirebaseCRUD();
  const titlePDF = `test`;


  const handleDownloadPDF = () => {
    download_pdf_file(pqrData.add_resend_guide_file, titlePDF);
  }
  // Funcion para manejar la genera  cion de guias inidividuales
  const handleGenerateIndividualGuide = async (rowData) => {
    let endpoint = "APIGuides";
    console.log("ActionEmpacar pqrData", pqrData, rowData)

      try {
        const quoteRes = await fetchFromCloudFunction(endpoint, pqrData, "POST");
    console.log("quoteRes ", quoteRes)
        if (quoteRes?.data?.success === true) {
          toastFunction(
            toast,
            "success",
            `${t("Respuesta exitosa, revisa los registros de la orden")} ${pqrData.order_number}`,
            // quoteRes?.data?.msg,
            "quoteRes?.data?.msg",
            8000
          );
        } else  {
          toastFunction(
            toast,
            "warn",
            `${pqrData.order_number} ${t("toast-summary.generateGuidePQRFailed")} `,
            `${t("toast-summary.generateGuidePQRFailedDetail")} `,
            15000
          );
          toastFunction(
            toast,
            "warn",
            `${pqrData.order_number} ${quoteRes?.data?.message}`,
            ``,
            15000
          );
        } 
      } catch (error) {
        console.error("Error creando la cotizacion:", error);
        return { error: "local/error" };
      }
  };

  const handleCheckTask = async () => {
  const actionsHistoryData = { ...actionsHistoryObject, task_id: rowData.id };
    try {
      await addToArray("pqrs", pqrData.id, "actions_history", actionsHistoryData);
      await addToArray("pqrs", pqrData.id, "tasks_done", rowData.id); 
      toastFunction(toast, "success", "Tarea completada con exito", "", 3000)

    } catch (error) {
      toastFunction(toast, "error", "La tarea no pudo ser completada con exito", "", 3000)
      
    }
  }
  const validationShouldDisableTaskBasedOnPrevious  =  shouldDisableTaskBasedOnPrevious (pqrData, rowData.id)
  const validationShouldDisableTaskBasedOnCompletion =  shouldDisableTaskBasedOnCompletion (pqrData, rowData.id)
  const validationTaskIsCheckedInPqr = validationShouldDisableTaskBasedOnPrevious || validationShouldDisableTaskBasedOnCompletion;

  return (
    <div className="flex gap-4">
      <Button
        label="Marcar como empacado"
        onClick={handleGenerateIndividualGuide}
        className=" p-button-outlined"
        disabled={validationTaskIsCheckedInPqr}
      />
      {pqrData?.add_resend_guide_file && (
          <Button
            label="Descargar pdf"
            onClick={handleDownloadPDF}
            className=" p-button-outlined"

          />
      )}
       <Button
        label="Marcar tarea como completada"
        onClick={handleCheckTask}
        className=" p-button-outlined"
        disabled={validationTaskIsCheckedInPqr}

      />
    </div>
  );
}

export function ActionCargarGuiaEnviadaHaciaCliente({rowData, pqrData, toast}) {
  const { t } = useTranslation("MyOrders");
  // pqrData?.add_resend_guide_number
  // pqrData?.add_resend_guide_shipping_agency
  const { updateDocument, addToArray } = useFirebaseCRUD();
  const fileUploadRef = useRef(null);


  const [shippingGuideToCustomerValues, setShippingGuideToCustomerValues] = useState(() => ({
      id: generateRandomId(),
      created_at: new Date(),
      guide_number:  (pqrData?.add_resend_guide_number || pqrData?.shipping_to_customer_values?.add_resend_guide_number) || null,
      shipping_company: (pqrData?.shipping_to_customer_values?.add_resend_guide_shipping_agency || pqrData?.add_resend_guide_shipping_agency) || null,
      file: pqrData?.shipping_to_customer_values?.file || null,
      file_name: pqrData?.shipping_to_customer_values?.file_name || null,
  }));

  // console.log("shippingGuideToCustomerValues", shippingGuideToCustomerValues?.add_resend_guide_number, pqrData)
  const [showInputFile, setShowInputFile] = useState(!pqrData?.shipping_to_customer_values?.file ? true :false);

  const handleCheckTask = async () => {
    const downloadURL = await uploadFileToFirebaseStorage(shippingGuideToCustomerValues.file, pqrData.id)
    setShippingGuideToCustomerValues((prevValues) => ({
      ...prevValues,
      file: downloadURL,
  }));
    const actionsHistoryData = { ...actionsHistoryObject, task_id: rowData.id, payload: `Solución ${rowData.id}` };
    try {
    // Agregar datos a Firestore
      await updateDocument("pqrs", pqrData.id, {
        shipping_to_customer_values: {...shippingGuideToCustomerValues, file: downloadURL},
        add_resend_guide_number: shippingGuideToCustomerValues?.guide_number,
        add_resend_guide_shipping_agency: shippingGuideToCustomerValues?.shipping_company
      });

      await addToArray("pqrs", pqrData.id, "actions_history", actionsHistoryData);
      await addToArray("pqrs", pqrData.id, "tasks_done", rowData.id); 
      toastFunction(toast, "success", "Tarea completada con exito", "", 3000)
    } catch (error) {
      toastFunction(toast, "error", "La tarea no pudo ser completada con exito", "", 3000)
    }
  };

  const uploadFileToFirebaseStorage = async (file, pqrdId) => {
    try {
        const storageRef = ref(storage);
        const validFileName = generateValidObjectName(file?.name);
        const filePath = `pqrs/${pqrdId}/guia_enviada_hacia_cliente/${validFileName}`;
        const fileRef = ref(storageRef, filePath);

       // Borrar toda la carpeta antes de subir el nuevo archivo
        const folderRef = ref(storageRef, `pqrs/${pqrdId}`);
        const folderContent = await listAll(folderRef);
        await Promise.all(folderContent.items.map(item => deleteObject(item)));

        // Subir el nuevo archivo
        await uploadBytes(fileRef, file);
        const downloadURL = await getDownloadURL(fileRef);
        toastFunction(toast, "success", "Archivo subido con exito", "", 3000)
        return downloadURL;
    } catch (error) {
        toastFunction(toast, "error", "El archivo no pudo ser subido con exito", error, 3000)
        console.error('Error al subir el archivo:', error);
        return null;
    }
  };

  const handleFileDelete = () => {
    setShowInputFile(true);
    if (fileUploadRef.current) {
      fileUploadRef.current.clear(); // 🔥 Borra el archivo en el componente
    }
    setShippingGuideToCustomerValues((prevValues) => ({
        ...prevValues,
        file: null,
        file_name: null,
    }));
  };   

  const openFileInNewTab = (fileUrl) => {
    if (!fileUrl) {
      console.error("❌ URL no válida");
      return;
    }
  
    window.open(fileUrl, "_blank");
  };

  const handleFileChange = (event) => {
    setShowInputFile(false);
    const uploadedFile = event.files?.[0]; // Obtiene el primer archivo
    if (!uploadedFile) return;

    setShippingGuideToCustomerValues((prevState) => ({
      ...prevState,
      file: uploadedFile,
      file_name: uploadedFile?.name,
    }));
  
  };

  const validationShouldDisableTaskBasedOnPrevious  =  shouldDisableTaskBasedOnPrevious (pqrData, rowData.id)
  const validationShouldDisableTaskBasedOnCompletion =  shouldDisableTaskBasedOnCompletion (pqrData, rowData.id)
  const validationTaskIsCheckedInPqr = validationShouldDisableTaskBasedOnPrevious || validationShouldDisableTaskBasedOnCompletion;

    const isCoordinadoraShippingCompany = shippingGuideToCustomerValues.shipping_company = "Coordinadora";

    return (
    <div className="flex  justify-center items-center gap-2">
        <div className="grid">
          <label>{t("input-placeholders.guideNumber")}</label>
          <CustomInputText
            value={shippingGuideToCustomerValues?.guide_number || ""}
            onChange={(e) => setShippingGuideToCustomerValues((prevState) => ({
              ...prevState, 
              guide_number: e.target.value
            }))}
            disabled={validationTaskIsCheckedInPqr || !isCoordinadoraShippingCompany}
            inColumTable={true}
          />
        </div>
      <div className="grid">
        <label>{t("input-placeholders.shippingCompany")}</label>
        <CustomInputText
          value={shippingGuideToCustomerValues?.shipping_company || ""}
          onChange={(e) => {
            const newValue = e.target.value;

            setShippingGuideToCustomerValues((prevState) => ({
              ...prevState,
              shipping_company: newValue,
            }));

          }}
          disabled={validationTaskIsCheckedInPqr || !isCoordinadoraShippingCompany}
          inColumTable={true}
        />

      </div>
      <div>
        {showInputFile && ( <FileUpload
            ref={fileUploadRef}
            mode="basic"
            name="demo[]"
            accept="image/*"
            maxFileSize={1000000}
            customUpload={true}
            auto={false}
            chooseLabel="Seleccionar archivo"
            onSelect={handleFileChange}
          />)}

          {shippingGuideToCustomerValues?.file_name && (
            <div>
              <p className="text-base">Archivo seleccionado: {shippingGuideToCustomerValues?.name || shippingGuideToCustomerValues?.file_name}</p>
            </div>
          )}
      </div>

      <div className="flex gap-4">
        {isValidUrl(shippingGuideToCustomerValues?.file) && (
          <Button
            label="Descargar"
            onClick={() => openFileInNewTab(shippingGuideToCustomerValues?.file)}
            className=" p-button-outlined"
            disabled={!shippingGuideToCustomerValues?.file}
          />
          )
        }
          <Button
          label="Borrar"
          onClick={() => handleFileDelete()}
          className=" p-button-outlined"
          disabled={!shippingGuideToCustomerValues?.file}
        />
       
        
          <Button handleCheckTask
            label={"Completar tarea"}
            onClick={handleCheckTask}
            className=" p-button-outlined"
            disabled={!shippingGuideToCustomerValues.guide_number || !shippingGuideToCustomerValues.shipping_company}
          />
      </div>
      <Toast ref={toast}/>
    </div>
  );
}

export function ActionEnviarGuiaEnvioHaciaCliente({rowData, pqrData, toast}) {
  const { 
    handleMessageSelect, 
    handleSidebarSelect, 
    setIsWidgetView, 
    setFocusTextArea, 
    setNumberChatDocuments, 
    setTextValue,
    textValue
  } = useChatMenu();
  const { t } = useTranslation("MyOrders");

  const { addToArray } = useFirebaseCRUD();
  const [visibleWhastappCRMModal, setVisibleWhastappCRMModal] = useState(false);

  // Crear una copia local del objeto para evitar modificarlo globalmente
  const actionsHistoryData = { ...actionsHistoryObject, task_id: rowData.id };


  // console.log("pqrData", pqrData)
  const initialMessageValue = `Hola el ${pqrData?.PQR_type || "Tipo de PQR"} 
    fue despachado por medio de la transportadora ${pqrData?.shipping_to_customer_values?.shipping_company || "Transportadora" },
    con el numero de guia ${pqrData?.shipping_to_customer_values?.guide_number || "Numero de guia"}`;
  
  
  useEffect(() => {
    setTextValue(initialMessageValue)
  }, [pqrData])
  
  const handleOpenWhatsappCRMWithMessage = async () => {
    await handleOpenWhatsappCRM();
  };

  const handleOpenWhatsappCRM = async () => {
    try {
      setNumberChatDocuments(INITIALNUMBEROFCHATS);
      setIsWidgetView(true);
      await handleMessageSelect(pqrData?.relatedOrder?.customer_phone_number, true);
      console.log("Después de handleMessageSelect");
      setVisibleWhastappCRMModal(true);
      setFocusTextArea(true);
      handleSidebarSelect(CHATMESSAGE);
      await addToArray("pqrs", pqrData.id, "actions_history", actionsHistoryData);
      await addToArray("pqrs", pqrData.id, "tasks_done", actionsHistoryData.task_id);
    } catch (error) {
      console.error("Error en handleOpenWhatsappCRM:", error);
      toastFunction(toast, "error", `La tarea ${rowData.id} no pudo ser completada`, error);
    }
  };

  const handleCheckTask = async () => {
    const actionsHistoryData = { ...actionsHistoryObject, task_id: rowData.id };
      try {
        await addToArray("pqrs", pqrData.id, "actions_history", actionsHistoryData);
        await addToArray("pqrs", pqrData.id, "tasks_done", rowData.id); 
        toastFunction(toast, "success", "Tarea completada con exito", "", 3000)
  
      } catch (error) {
        toastFunction(toast, "error", "La tarea no pudo ser completada con exito", "", 3000)
        
      }
    }
  // Validación de si la tarea ya ha sido marcada como completada
  // const validationTaskIsCheckedInPqr = (pqrData.tasks_done?.includes(rowData?.id) &&  pqrData?.actions_history?.some(actionHistory => actionHistory?.task_id === rowData?.id))
  const validationShouldDisableTaskBasedOnPrevious  =  shouldDisableTaskBasedOnPrevious (pqrData, rowData.id)
  const validationShouldDisableTaskBasedOnCompletion =  shouldDisableTaskBasedOnCompletion (pqrData, rowData.id)
  const validationTaskIsCheckedInPqr = validationShouldDisableTaskBasedOnPrevious || validationShouldDisableTaskBasedOnCompletion;
  return (
    <div className="flex gap-4">

      <div className="">
          <label>{t("input-placeholders.initialMessageContact")}</label>
          <CustomTextArea
            value={textValue|| ""}
            onChange={(e) => setTextValue((prevState) => ({
              ...prevState, 
              guide_number: e.target.value
            }))}
            disabled={validationTaskIsCheckedInPqr }
            inColumTable={true}
          />
        </div>
      {!validationShouldDisableTaskBasedOnCompletion ? (
        <div className="flex gap-4">
          <Button
            label="Contactar con mensaje inicial"
            onClick={handleOpenWhatsappCRMWithMessage}
            className="p-button-outlined"
            disabled={validationTaskIsCheckedInPqr}
          />
        </div>
      ) : (
        <div>
          <p>Cliente contactado</p>
        </div>
      )}
      <CustomModal
        visible={visibleWhastappCRMModal}
        setVisible={setVisibleWhastappCRMModal}
        object={{}}
        content={<WhatsAppCRM />}
        onClose={() => setVisibleWhastappCRMModal(false)}
      />

    <Button
          label="Marcar tarea como completada"
          onClick={handleCheckTask}
          className=" p-button-outlined"
          disabled={validationTaskIsCheckedInPqr}

        />
      <Toast ref={toast} />
    </div>
  );
}

export function ActionCargarCuentaBancariaCliente({rowData, pqrData, toast}) {
  const [showInputFile, setShowInputFile] = useState(!pqrData?.customer_bank_account_values?.file ? true :false);
  
  const [customerBankAccountValues, setCustomerBankAccountValues] = useState({
    id: generateRandomId(),
    created_at: new Date(),
    file: pqrData?.customer_bank_account_values?.file || null,
    file_name: pqrData?.customer_bank_account_values?.file_name || null,
    quantity_to_refund: pqrData?.customer_bank_account_values?.quantity_to_refund || 0
  });
  const { updateDocument, addToArray } = useFirebaseCRUD();
  const fileUploadRef = useRef(null);


  
  const handleCheckTask = async () => {
    const downloadURL = await uploadFileToFirebaseStorage(customerBankAccountValues.file, pqrData.id)
    setCustomerBankAccountValues((prevValues) => ({
      ...prevValues,
      file: downloadURL,
   }));
    const actionsHistoryData = { ...actionsHistoryObject, task_id: rowData.id, payload: `Solución ${rowData.id}` };
    try {
    // Agregar datos a Firestore
      await updateDocument("pqrs", pqrData.id, {
        customer_bank_account_values: {...customerBankAccountValues, file: downloadURL},
      });

      await addToArray("pqrs", pqrData.id, "actions_history", actionsHistoryData);
      await addToArray("pqrs", pqrData.id, "tasks_done", rowData.id); 
      toastFunction(toast, "success", "Tarea completada con exito", "", 3000)
    } catch (error) {
      toastFunction(toast, "error", "La tarea no pudo ser completada con exito", "", 3000)
    }
  };

  const uploadFileToFirebaseStorage = async (file, pqrdId) => {
    try {
        const storageRef = ref(storage);
        const validFileName = generateValidObjectName(file?.name);
        const filePath = `pqrs/${pqrdId}/guia_enviada_hacia_cliente/${validFileName}`;
        const fileRef = ref(storageRef, filePath);

       // Borrar toda la carpeta antes de subir el nuevo archivo
        const folderRef = ref(storageRef, `pqrs/${pqrdId}`);
        const folderContent = await listAll(folderRef);
        await Promise.all(folderContent.items.map(item => deleteObject(item)));

        // Subir el nuevo archivo
        await uploadBytes(fileRef, file);
        const downloadURL = await getDownloadURL(fileRef);
        toastFunction(toast, "success", "Archivo subido con exito", "", 3000)
        return downloadURL;
    } catch (error) {
        toastFunction(toast, "error", "El archivo no pudo ser subido con exito", error, 3000)
        console.error('Error al subir el archivo:', error);
        return null;
    }
  };

  const handleFileDelete = () => {
    setShowInputFile(true);
    if (fileUploadRef.current) {
      fileUploadRef.current.clear(); // 🔥 Borra el archivo en el componente
    }
    setCustomerBankAccountValues((prevValues) => ({
        ...prevValues,
        file: null,
        file_name: null,
    }));
  };   

  const openFileInNewTab = (fileUrl) => {
    if (!fileUrl) {
      return;
    }
  
    window.open(fileUrl, "_blank");
  };

  const handleFileChange = (event) => {
    setShowInputFile(false);
    const uploadedFile = event.files?.[0]; // Obtiene el primer archivo
    if (!uploadedFile) return;

    setCustomerBankAccountValues((prevState) => ({
      ...prevState,
      file: uploadedFile,
      file_name: uploadedFile?.name,
    }));
  
  };

  const validationShouldDisableTaskBasedOnPrevious  =  shouldDisableTaskBasedOnPrevious (pqrData, rowData.id)
    const validationShouldDisableTaskBasedOnCompletion =  shouldDisableTaskBasedOnCompletion (pqrData, rowData.id)
    const validationTaskIsCheckedInPqr = validationShouldDisableTaskBasedOnPrevious || validationShouldDisableTaskBasedOnCompletion;

    return (
    <div className="flex  justify-center items-center gap-2">
      <div>
        {showInputFile && ( <FileUpload
            ref={fileUploadRef}
            mode="basic"
            name="demo[]"
            accept="image/*"
            maxFileSize={1000000}
            customUpload={true}
            auto={false}
            chooseLabel="Seleccionar archivo"
            onSelect={handleFileChange}
          />)}

          {customerBankAccountValues?.file_name && (
            <div>
              <p className="text-base">Archivo seleccionado: {customerBankAccountValues?.name || customerBankAccountValues?.file_name}</p>
            </div>
          )}
      </div>

      <div className="flex gap-4">
        {isValidUrl(customerBankAccountValues?.file) && (
          <Button
            label="Descargar soporte"
            onClick={() => openFileInNewTab(customerBankAccountValues?.file)}
            className=" p-button-outlined"
            disabled={!customerBankAccountValues?.file}
          />
          )
        }
        <Button
          label="Borrar"
          onClick={() => handleFileDelete()}
          className=" p-button-outlined"
          disabled={!customerBankAccountValues?.file}
        />
        <Button handleCheckTask
          label={validationTaskIsCheckedInPqr ? "Subir archivo " : "Completar tarea"}
          onClick={handleCheckTask}
          className=" p-button-outlined"
        />
      </div>
      <Toast ref={toast}/>
    </div>
    );
}

export function ActionMontoADevolver({rowData, pqrData, toast}) {
  const { updateDocument, addToArray } = useFirebaseCRUD();
  const [quantityValue, setQuantityValue ] = useState(pqrData?.customer_bank_account_values?.quantity_to_refund || 0);

  
  const validationShouldDisableTaskBasedOnPrevious  =  shouldDisableTaskBasedOnPrevious (pqrData, rowData.id)
    const validationShouldDisableTaskBasedOnCompletion =  shouldDisableTaskBasedOnCompletion (pqrData, rowData.id)
    const validationTaskIsCheckedInPqr = validationShouldDisableTaskBasedOnPrevious || validationShouldDisableTaskBasedOnCompletion;
  
  const handleCheckTask = async () => {
    const actionsHistoryData = { ...actionsHistoryObject, task_id: rowData.id, payload: `Solución ${rowData.id} Monto a devolver` };
    try {
    // Agregar datos a Firestore
      await updateDocument("pqrs", pqrData.id, {
        customer_bank_account_values: {
          id: pqrData?.customer_bank_account_values?.id || null,
          created_at: pqrData?.customer_bank_account_values?.created_at || null,
          file: pqrData?.customer_bank_account_values?.file || null,
          file_name: pqrData?.customer_bank_account_values?.file_name || null,
          quantity_to_refund: quantityValue},
      });

      await addToArray("pqrs", pqrData.id, "actions_history", actionsHistoryData);
      await addToArray("pqrs", pqrData.id, "tasks_done", rowData.id); 
      toastFunction(toast, "success", "Tarea completada con exito", "", 3000)
    } catch (error) {
      toastFunction(toast, "error", "La tarea no pudo ser completada con exito", "", 3000)
    }
  };


  const handleProductQuantity = (value) => {
    console.log("value", value, pqrData.total_order_price);

    let newValue = parseInt(value, 10); // Convierte a entero
    
    if(value ==="") newValue = 0
    if (isNaN(newValue)) return; // Si no es número, no hace nada
    if (newValue <=  pqrData.total_order_price) {
      setQuantityValue(newValue);
    } else {
      // setQuantityValue(newValue);
      toastFunction(toast, "warn", "La cantidad no puede superar a la cantidad de la orden original", `cantidad restante  $ ${pqrData.total_order_price}`)
      return; // Detiene la ejecución si se excede el límite
    }
   
  };

  return (
    <div className="flex gap-4 items-center">
      <p className="text-base font-semibold">
        MONTO A DEVOLVER: 
      </p>
      <input
        type="text"
        value={quantityValue}
        onChange={(e) => handleProductQuantity(e.target.value)}
        className="w-full px-3 py-3  border border-blue-400 rounded-full outline-none focus:ring-2 extra-bold  focus:ring-blue-300 transition-all"
        disabled={validationTaskIsCheckedInPqr}
      
      />
      <Button handleCheckTask
        label={ "Completar tarea"}
        onClick={handleCheckTask}
        className=" p-button-outlined"
        disabled={validationTaskIsCheckedInPqr}
        />
    </div>
  );
}


export function ActionCargarComprobanteTransferenciaRetracto({rowData, pqrData, toast}) {
  const [showInputFile, setShowInputFile] = useState(!pqrData?.withdrawal_transfer_confirmation_values?.file ? true :false);
  
  const [withdrawalTransferValues, setWithdrawalTransferValues] = useState({
    id: generateRandomId(),
    created_at: new Date(),
    file: pqrData?.withdrawal_transfer_confirmation_values?.file || null,
    file_name: pqrData?.withdrawal_transfer_confirmation_values?.file_name || null,
  });
  const { updateDocument, addToArray } = useFirebaseCRUD();
  const fileUploadRef = useRef(null);

  
  const handleCheckTask = async () => {
    const downloadURL = await uploadFileToFirebaseStorage(withdrawalTransferValues.file, pqrData.id)
    setWithdrawalTransferValues((prevValues) => ({
      ...prevValues,
      file: downloadURL,
   }));
    const actionsHistoryData = { ...actionsHistoryObject, task_id: rowData.id, payload: `Solución ${rowData.id} cargar cuenta bancaria del cliente` };
    try {
    // Agregar datos a Firestore
      await updateDocument("pqrs", pqrData.id, {
        withdrawal_transfer_confirmation_values: {...withdrawalTransferValues, file: downloadURL},
      });

      await addToArray("pqrs", pqrData.id, "actions_history", actionsHistoryData);
      await addToArray("pqrs", pqrData.id, "tasks_done", rowData.id); 
      toastFunction(toast, "success", "Tarea completada con exito", "", 3000)
    } catch (error) {
      toastFunction(toast, "error", "La tarea no pudo ser completada con exito", "", 3000)
    }
  };

  const uploadFileToFirebaseStorage = async (file, pqrdId) => {
    try {
        const storageRef = ref(storage);
        const validFileName = generateValidObjectName(file?.name || "noname");
        const filePath = `pqrs/${pqrdId}/comprobante_transferencia_retracto/${validFileName}`;
        const fileRef = ref(storageRef, filePath);

       // Borrar toda la carpeta antes de subir el nuevo archivo
        const folderRef = ref(storageRef, `pqrs/${pqrdId}`);
        const folderContent = await listAll(folderRef);
        await Promise.all(folderContent.items.map(item => deleteObject(item)));

        // Subir el nuevo archivo
        await uploadBytes(fileRef, file);
        const downloadURL = await getDownloadURL(fileRef);
        toastFunction(toast, "success", "Archivo subido con exito", "", 3000)
        return downloadURL;
    } catch (error) {
        toastFunction(toast, "error", "El archivo no pudo ser subido con exito", error, 3000)
        console.error('Error al subir el archivo:', error);
        return null;
    }
  };

  const handleFileDelete = () => {
    setShowInputFile(true);
    if (fileUploadRef.current) {
      fileUploadRef.current.clear(); // 🔥 Borra el archivo en el componente
    }
    setWithdrawalTransferValues((prevValues) => ({
        ...prevValues,
        file: null,
        file_name: null,
    }));
  };   

  const openFileInNewTab = (fileUrl) => {
    if (!fileUrl) {
      return;
    }
    window.open(fileUrl, "_blank");
  };

  const handleFileChange = (event) => {
    setShowInputFile(false);
    const uploadedFile = event.files?.[0]; // Obtiene el primer archivo
    if (!uploadedFile) return;

    setWithdrawalTransferValues((prevState) => ({
      ...prevState,
      file: uploadedFile,
      file_name: uploadedFile?.name,
    }));
  
  };

  const validationShouldDisableTaskBasedOnPrevious  =  shouldDisableTaskBasedOnPrevious (pqrData, rowData.id)
  const validationShouldDisableTaskBasedOnCompletion =  shouldDisableTaskBasedOnCompletion (pqrData, rowData.id)
  const validationTaskIsCheckedInPqr = validationShouldDisableTaskBasedOnPrevious || validationShouldDisableTaskBasedOnCompletion;

    return (
    <div className="flex  justify-center items-center gap-2">
      <div>
        {showInputFile && ( <FileUpload
            ref={fileUploadRef}
            mode="basic"
            name="demo[]"
            accept="image/*"
            maxFileSize={1000000}
            customUpload={true}
            auto={false}
            chooseLabel="Seleccionar archivo"
            onSelect={handleFileChange}
          />)}

          {withdrawalTransferValues?.file_name && (
            <div>
              <p className="text-base">Archivo seleccionado: {withdrawalTransferValues?.name || withdrawalTransferValues?.file_name}</p>
            </div>
          )}
      </div>

      <div className="flex gap-4">
        {isValidUrl(withdrawalTransferValues?.file) && (
          <Button
            label="Descargar soporte"
            onClick={() => openFileInNewTab(withdrawalTransferValues?.file)}
            className=" p-button-outlined"
            disabled={!withdrawalTransferValues?.file}
          />
          )
        }
        <Button
          label="Borrar"
          onClick={() => handleFileDelete()}
          className=" p-button-outlined"
          disabled={!withdrawalTransferValues?.file}
        />
        <Button handleCheckTask
          label={validationTaskIsCheckedInPqr ? "Subir archivo " : "Completar tarea"}
          onClick={handleCheckTask}
          className=" p-button-outlined"
        />
      </div>
      <Toast ref={toast}/>
    </div>
    );
}

export function ActionEnviarComprobanteTransferenciaCliente({rowData, pqrData, toast}) {
  const { 
    handleMessageSelect, 
    handleSidebarSelect, 
    setIsWidgetView, 
    setFocusTextArea, 
    setNumberChatDocuments, 
    setTextValue 
  } = useChatMenu();

  const { addToArray } = useFirebaseCRUD();

  const [visibleWhastappCRMModal, setVisibleWhastappCRMModal] = useState(false);

  // Crear una copia local del objeto para evitar modificarlo globalmente
  const actionsHistoryData = { ...actionsHistoryObject, task_id: rowData.id };


  const handleOpenWhatsappCRM = async () => {
    try {
      setNumberChatDocuments(INITIALNUMBEROFCHATS);
      setIsWidgetView(true);
      await handleMessageSelect(pqrData?.relatedOrder?.customer_phone_number, true);
      console.log("Después de handleMessageSelect");
      setVisibleWhastappCRMModal(true);
      setFocusTextArea(true);
      handleSidebarSelect(CHATMESSAGE);
      await addToArray("pqrs", pqrData.id, "actions_history", actionsHistoryData);
      await addToArray("pqrs", pqrData.id, "tasks_done", actionsHistoryData.task_id);
    } catch (error) {
      console.error("Error en handleOpenWhatsappCRM:", error);
      toastFunction(toast, "error", `La tarea ${rowData.id} no pudo ser completada`, error);
    }
  };

  const handleOpenWhatsappCRMWithMessage = async () => {
    setTextValue(`Comprobante de desembolso por retracto  ${pqrData?.order_number ? "para la orden numero: " + pqrData?.order_number : ""} ${pqrData?.withdrawal_transfer_confirmation_values?.file}`);
    await handleOpenWhatsappCRM();
  };

  const validationShouldDisableTaskBasedOnPrevious  =  shouldDisableTaskBasedOnPrevious (pqrData, rowData.id)
  const validationShouldDisableTaskBasedOnCompletion =  shouldDisableTaskBasedOnCompletion (pqrData, rowData.id)
  const validationTaskIsCheckedInPqr = validationShouldDisableTaskBasedOnPrevious || validationShouldDisableTaskBasedOnCompletion;
  // console.log("validationTaskIsCheckedInPqr", validationTaskIsCheckedInPqr)
  return (
   
     <>
     {!validationShouldDisableTaskBasedOnCompletion  ? (
       <div className="flex gap-4">
         <Button
           label="Enviar comprobante"
           onClick={handleOpenWhatsappCRMWithMessage}
           className="p-button-outlined"
           disabled={validationTaskIsCheckedInPqr}
         />

       </div>
     ) : (
       <div>
         <p>Comprobante enviado</p>
       </div>
     )}
     
     <CustomModal
       visible={visibleWhastappCRMModal}
       setVisible={setVisibleWhastappCRMModal}
       object={{}}
       content={<WhatsAppCRM />}
       onClose={() => setVisibleWhastappCRMModal(false)}
     />

     <Toast ref={toast} />
   </>
  );
}

export function ActionCargarConsecutivoNotaCredito({rowData, pqrData, toast}) {
  const { t } = useTranslation("MyOrders");
  const { updateDocument, addToArray  } = useFirebaseCRUD();

  const [consecutiveNumber, setconsecutiveNumber] = useState(pqrData?.consecutive_credit_note || null);

  const handleCheckTask = async () => {
    const actionsHistoryData = { ...actionsHistoryObject, task_id: rowData.id, payload: `Solución ${rowData.id} cargar cuenta bancaria del cliente` };
    try {
    // Agregar datos a Firestore
      await updateDocument("pqrs", pqrData.id, {
        consecutive_credit_note: consecutiveNumber,
      });

      await addToArray("pqrs", pqrData.id, "actions_history", actionsHistoryData);
      await addToArray("pqrs", pqrData.id, "tasks_done", rowData.id); 
      toastFunction(toast, "success", "Tarea completada con exito", "", 3000)
    } catch (error) {
      toastFunction(toast, "error", "La tarea no pudo ser completada con exito", "", 3000)
    }
  }; 
  
  const validationShouldDisableTaskBasedOnPrevious  =  shouldDisableTaskBasedOnPrevious (pqrData, rowData.id)
  const validationShouldDisableTaskBasedOnCompletion =  shouldDisableTaskBasedOnCompletion (pqrData, rowData.id)
  const validationTaskIsCheckedInPqr = validationShouldDisableTaskBasedOnPrevious || validationShouldDisableTaskBasedOnCompletion;
  return (
    <div className="flex gap-4 items-center ">
      <CustomInputText
        value={consecutiveNumber || ""}
        onChange={(e) => setconsecutiveNumber(e.target.value)}
        placeholder={t("input-placeholders.consecutiveNumber")}
        // disabled={!rowEditStates[rowData.id]}
        inColumTable={true}
        disabled={validationTaskIsCheckedInPqr}

      />
      <Button
        label={validationTaskIsCheckedInPqr ? "Completar tarea":"Guardar"}
        onClick={handleCheckTask}
        className=" p-button-outlined"
        disabled={validationTaskIsCheckedInPqr}
      />
    </div>
  );
}
