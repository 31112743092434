import  { createContext, useState, useContext, useRef, useEffect } from 'react';
import { CHATMESSAGE, INITIALNUMBEROFCHATS, SIDEBARMENU } from '../utils/CRMconstants';
import { fetchFromCloudFunction } from '../../../services/cloudFunctinons/fetchFromCloudFunction';
import { getDownloadURL, ref, uploadBytes, uploadString } from 'firebase/storage';
import { db, storage } from '../../../firebase/firebase';
import toastFunction from '../../../utils/toastFunction';
import useShopifyRequest from '../../../hooks/useShopifyRequest';
import { collection, doc, limit, onSnapshot, orderBy, query,   updateDoc,   where } from 'firebase/firestore';
import { useLoaderContext } from '../../../contexts/LoaderContext/LoaderContext';
import formatPhoneNumber from '../utils/formatPhoneNumber';

// Crea el contexto
const ChatMenuContext = createContext();

// Proveedor del contexto
// eslint-disable-next-line react/prop-types
export const ChatMenuProvider = ({ children }) => {
  const [unsubscribeOrderStateSnapshot, setUnsubscribeOrderStateSnapshot] = useState(null);

  const [numberChatDocuments, setNumberChatDocuments] = useState(INITIALNUMBEROFCHATS);


  const [selectedMenuOption, setSelectedMenuOption] = useState(null);
  const [messageData, setMessageData] = useState(null); // Estado auxiliar para almacenar el mensaje previo


  const [ isWidgetView, setIsWidgetView] = useState(false);

  const { setGlobalLoading } = useLoaderContext();
  const [imagePreviewUrls, setImagePreviewUrls] = useState([]);
  const [videoPreviewUrls, setVideoPreviewUrls] = useState([]);
  const [audioPreviewsUrls, setAudioPreviewUrls] = useState([]);
  const [documentFiles, setDocumentFiles] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');

  const { toast } = useShopifyRequest();


  const handleChatMessageMenu = ({ type, messageId, messageText, videoSrc, audioSrc, username, imageSrc, idMessageRepliying, audioDuration}) => {
    console.log("type", type)
    // Siempre actualiza messageData al seleccionar un mensaje
    setMessageData({ messageId, messageText, videoSrc, audioSrc, username, imageSrc, type, idMessageRepliying, audioDuration});

    // Si se selecciona un nuevo mensaje, también podemos configurar la opción inicial a null (o cualquier otra lógica que necesites)
    setSelectedMenuOption((prevState) => ({
      ...prevState,
      option: null, // O mantener la opción anterior si deseas
    }));
  };

  const handleResponseMessage = ({ messageId, messageText, videoSrc, audioSrc, username, imageSrc, type, idMessageRepliying, audioDuration }) => {

    setFocusTextArea(true)
    const isSameMessage =
      selectedMenuOption?.messageId === messageId &&
      selectedMenuOption?.messageText === messageText &&
      selectedMenuOption?.videoSrc === videoSrc &&
      selectedMenuOption?.audioSrc === audioSrc &&
      selectedMenuOption?.imageSrc === imageSrc &&
      selectedMenuOption?.type === type &&
      selectedMenuOption?.idMessageRepliying === idMessageRepliying &&
      selectedMenuOption?.audioDuration ===  audioDuration &&
      selectedMenuOption?.username === username;

    if (!isSameMessage) {
      // Si el mensaje es diferente, actualiza selectedMenuOption y messageData
      setSelectedMenuOption({
        messageId,
        messageText,
        videoSrc,
        audioSrc,
        username,
        imageSrc,
        type,
        idMessageRepliying: messageId,
        context: {id: messageId},
        audioDuration,
        option: 1, // Cambia la opción a 1 para indicar "respuesta"
      });
      setMessageData({ messageId, messageText, videoSrc, audioSrc, imageSrc, username, type, idMessageRepliying: messageId, audioDuration,
        context: {id: messageId},
      });
    } else if (selectedMenuOption?.option !== 1) {
      // Si el mensaje es el mismo, mantiene la opción actual en 1
      setSelectedMenuOption((prevState) => ({
        ...prevState,
        option: 1,
      }));
    }
  };

  const handleFoo = () => {
    setSelectedMenuOption((prevState) => ({
      ...prevState,
      option: 2, // Cambia la opción a 2 para la acción "foo"
    }));
  };

  const menuChatOptions = () => [
    {
      label: "Responder",
      icon: "pi pi-fw pi-reply",
      command: () => handleResponseMessage(messageData),
    },
    {
      label: "foo",
      icon: "pi pi-fw pi-file",
      command: handleFoo,
    },
  ];

  const shortcuts = [
    { title: "saludo", content: "Hola, ¿en qué puedo ayudarte hoy?" },
    { title: "despedida", content: "Gracias por comunicarte, ¡hasta luego!" },
    { title: "preguntaProducto", content: "¿Podrías darme más detalles sobre el producto?" },
    { title: "confirmacionPedido", content: "Tu pedido ha sido confirmado y está en proceso." },
    { title: "seguimientoEnvio", content: "Puedes hacer seguimiento de tu envío con el siguiente enlace." },
    { title: "consultaHorario", content: "Nuestro horario de atención es de 9:00 a 18:00 de lunes a viernes." },
    { title: "informacionPago", content: "Aceptamos pagos mediante tarjeta de crédito, débito y transferencias bancarias." },
    { title: "tiempoEntrega", content: "El tiempo estimado de entrega es de 3 a 5 días hábiles." },
    { title: "devolucionProducto", content: "Puedes realizar la devolución del producto dentro de los 30 días posteriores a la compra." },
    { title: "promocionActual", content: "Actualmente tenemos un 20% de descuento en productos seleccionados." },
    { title: "soporteTecnico", content: "Nuestro equipo de soporte técnico está disponible para ayudarte con cualquier problema." },
    { title: "instruccionesRegistro", content: "Para registrarte, haz clic en el botón de 'Crear cuenta' y completa el formulario." },
    { title: "politicasPrivacidad", content: "Puedes consultar nuestras políticas de privacidad en nuestro sitio web." },
    { title: "ayudaAdicional", content: "Si necesitas ayuda adicional, no dudes en contactarnos por este medio." },
    { title: "felicitacionCumpleaños", content: "¡Feliz cumpleaños! Esperamos que tengas un gran día." },
    { title: "confirmacionCita", content: "Tu cita ha sido confirmada. Te esperamos en la fecha y hora acordadas." },
    { title: "recordatorioRenovacion", content: "Este es un recordatorio de que tu renovación se acerca. ¿Te gustaría renovarlo ahora?" },
    { title: "instruccionesDescarga", content: "Haz clic en el siguiente enlace para descargar el archivo que necesitas." },
    { title: "agradecimientoCompra", content: "Gracias por tu compra. Esperamos que disfrutes de tu producto." },
  ];

  const chatMessagesExample = [
    {
      id: 1,
      type: "text",
      avatarSrc: "https://randomuser.me/api/portraits/lego/1.jpg",
      username: "Cliente 1",
      time: new Date(2024, 10, 22, 9, 18), // 22 de noviembre de 2024, 09:18 AM
      messageText: "Hola buenas para unos zapaticos bien bacanos",
      isSent: false,
    },
    {
      id: 2,
      type: "text",
      avatarSrc: "https://i.pinimg.com/564x/2d/34/f9/2d34f9f6a835ea240b9d6ba4c12dce15.jpg",
      username: "You",
      time: new Date(2024, 10, 22, 9, 19), // 1 minuto después
      messageText: "Se le tienen compadre",
      isSent: true,
    },
    {
      id: 3,
      type: "text",
      avatarSrc: "https://randomuser.me/api/portraits/lego/1.jpg",
      username: "Cliente 1",
      time: new Date(2024, 10, 22, 9, 20), // 1 minuto después
      messageText: (
        <>
          <img src="image.png" alt="Linner" />
          Vi estos en la pagina, muy bacana x cierto, salu2 q valen?
        </>
      ),
      isSent: false,
    },
    {
      id: 4,
      type: "text",
      avatarSrc: "https://randomuser.me/api/portraits/lego/1.jpg",
      username: "Cliente 1",
      time: new Date(2024, 10, 22, 9, 25), // 5 minutos después
      messageText: (
        <div>
          Tienes esta otra referencia? <br />
          <a href="https://www.instagram.com/p/Cq2AzG" target="_blank" rel="noopener noreferrer">
            https://www.instagram.com/p/Cq2AzGsadaayudasdasdasdasdasdasdasdasdasdasdasdasdasdasdasdaayudaasdasdsadasdasdasd
          </a>
        </div>
      ),
      isSent: false,
    },
    {
      id: 5,
      type: "text",
      avatarSrc: "https://i.pinimg.com/564x/2d/34/f9/2d34f9f6a835ea240b9d6ba4c12dce15.jpg",
      username: "You",
      time: new Date(2024, 10, 22, 9, 30), // 5 minutos después
      messageText: "20 millones brother",
      isSent: true,
    },
    {
      id: 6,
      type: "audio",
      avatarSrc: "https://randomuser.me/api/portraits/lego/1.jpg",
      username: "Cliente 1",
      time: new Date(2024, 10, 22, 9, 35), // 5 minutos después
      audioSrc:
        "https://firebasestorage.googleapis.com/v0/b/shoppex-aa709.appspot.com/o/Lil%20Tecca%20-%20DUI%20(Official%20Audio)%20-%20Lil%20Tecca.mp3?alt=media&token=0dde6991-f89a-44b5-bae3-0767ff2a31bb",
      audioDuration: "2:30",
      isSent: false,
    },
    {
      id: 7,
      type: "video",
      avatarSrc: "https://i.pinimg.com/564x/2d/34/f9/2d34f9f6a835ea240b9d6ba4c12dce15.jpg",
      username: "You",
      time: new Date(2024, 10, 22, 9, 40), // 5 minutos después
      messageText: "y que te parece esta otra referencia",
      videoSrc:
        "https://firebasestorage.googleapis.com/v0/b/shoppex-aa709.appspot.com/o/shoppexStores%2FJDfK9GpHVJldVzqFKRGt%2Fsection%2F19DgQGqvLnOKsOqgMU4r%2Farchive?alt=media&token=a7214314-5e4a-4bfc-b854-9037ec14e355",
      isSent: true,
    },
    {
      id: 8,
      type: "image",
      avatarSrc: "https://randomuser.me/api/portraits/lego/1.jpg",
      username: "Cliente 1",
      time: new Date(2024, 10, 22, 11, 35), // 2 horas después
      messageText: "Mira es esta ejemplo ",
      imageSrc:
        "https://firebasestorage.googleapis.com/v0/b/shoppex-aa709.appspot.com/o/products%2F0ObagfvFDbPh1xF7p06g%2Fmain_img%2FHnX4Z55QXegAZhXRp1CM?alt=media&token=e3cad75b-fe77-4422-b365-f68314b10fae",
      isSent: false,
    },
    {
      id: 9,
      type: "image",
      avatarSrc: "https://i.pinimg.com/564x/2d/34/f9/2d34f9f6a835ea240b9d6ba4c12dce15.jpg",
      username: "You",
      time: new Date(2024, 10, 22, 11, 40), // 5 minutos después
      messageText: "nuevo pedido",
      imageSrc:
        "https://firebasestorage.googleapis.com/v0/b/shoppex-aa709.appspot.com/o/products%2F0ObagfvFDbPh1xF7p06g%2Fmain_img%2FHnX4Z55QXegAZhXRp1CM?alt=media&token=e3cad75b-fe77-4422-b365-f68314b10fae",
      isSent: true,
      isRepliying: true,
      typeRepliying: "video",
      videoSrcRepliying:
        "https://firebasestorage.googleapis.com/v0/b/shoppex-aa709.appspot.com/o/shoppexStores%2FJDfK9GpHVJldVzqFKRGt%2Fsection%2F19DgQGqvLnOKsOqgMU4r%2Farchive?alt=media&token=a7214314-5e4a-4bfc-b854-9037ec14e355",
      idMessageRepliying: 7,
    },
    {
      id: 10,
      type: "text",
      avatarSrc: "https://i.pinimg.com/564x/2d/34/f9/2d34f9f6a835ea240b9d6ba4c12dce15.jpg",
      username: "You",
      time: new Date(2024, 10, 22, 11, 45), // 5 minutos después
      messageText: "20 millones brother",
      isSent: true,
      isRepliying: true,
      messageTextRepliying: "Mira es esta ejemplo ",
      typeRepliying: "image",
      imgSrcRepliying:
        "https://firebasestorage.googleapis.com/v0/b/shoppex-aa709.appspot.com/o/products%2F0ObagfvFDbPh1xF7p06g%2Fmain_img%2FHnX4Z55QXegAZhXRp1CM?alt=media&token=e3cad75b-fe77-4422-b365-f68314b10fae",
      idMessageRepliying: 8,
    },
    {
      id: 11,
      type: "text",
      avatarSrc: "https://i.pinimg.com/564x/2d/34/f9/2d34f9f6a835ea240b9d6ba4c12dce15.jpg",
      username: "You",
      time: new Date(2024, 10, 24, 12, 45), // 5 minutos después
      messageText: "20 millones brother",
      isSent: true,
      isRepliying: true,
      typeRepliying:"text",
      messageTextRepliying: "Respuesta",
      idMessageRepliying: 10,
    }
  ];

  const scrollToElementById = (idMessageRepliying) => {
    console.log(idMessageRepliying);
    const element = document.getElementById(idMessageRepliying);
    if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "start" });
    } else {
        console.warn(`Element with ID "${idMessageRepliying}" not found.`);
    }
  }


  const removeImage = (index) => {
    setImagePreviewUrls((prev) => prev.filter((_, i) => i !== index));
  };
  const removeVideo = (index) => {
    setVideoPreviewUrls((prev) => prev.filter((_, i) => i !== index));
  };

  const removeDocument = (index) => {
    setDocumentFiles((prev) => prev.filter((_, i) => i !== index));
  };

  const [voice, setVoice] = useState(false);
  const [visibleMicrophoneHandler, setVisibleMicrophoneHandler] = useState();

  const [ responseMode, setResponseMode ] = useState(chatMessagesExample && selectedMenuOption?.option=== 1 ? true : false);

  const [audioUrl, setAudioUrl] = useState(null); // Estado para almacenar la URL del audio grabado
  const [audioBlobState, setAudioBlobState] = useState([]); // Estado para almacenar la URL del audio grabado
  const [recordBlobLink, setRecordBlobLink] = useState(null);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [loadingMediaDevices, setLoadingMediaDevices] = useState(false);
  const [mediaRecordedState, setMediaRecordedState] = useState(null);
  const [mediaGumState, setMediaGumState] = useState(null);

  const [textValue, setTextValue] = useState('');




  const [chats, setChats] = useState([]);
  const [filteredChats, setFilteredChats ] = useState(chats)
  const [error, setError] = useState(null);

  const opChatOptions = useRef(null);


  const handleOpenChatOptions = (waId, e) => {
    console.log("waId", waId)
    setSelectedWaId(waId)
    opChatOptions.current.toggle(e); // // Abre el overlay
  };

  const handlePinnedChat = async () => {
    console.log("VALIDACION handlePinnedChat",  selectedWaId)
    try {
      if (selectedWaId) {
        const docRef = doc(db, "chats", selectedWaId);
        await updateDoc(docRef, {pinned: !selectedChat.pinned});
        return true;
      }
    } catch (error) {
      console.error("Error en la actualizacion:", error);
      return false;
    }
  }
    // Función para limpiar el estado
  const clearHandle = () => {
    console.log("clearHandle", recordBlobLink)
      setVoice(false);
      setVisibleMicrophoneHandler(false)
      setRecordBlobLink(null);
      setAudioUrl(null)
      setAudioBlobState([])
  };

  const selectDocuments = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.pdf,.doc,.docx,.txt,.xls,.xlsx,.ppt,.pptx';
    input.multiple = true; // Permite seleccionar múltiples archivos
    input.onchange = (event) => {
      const files = event.target.files; // Obtiene todos los archivos seleccionados
      const newDocuments = [];
      let error = false;

      for (const file of files) {
        const fileType = file.type;

        // Verificar si es un archivo compatible
        if (
          fileType === 'application/pdf' ||
          fileType.startsWith('application/vnd.openxmlformats-officedocument') ||
          fileType.startsWith('application/msword') || // Compatibilidad con .doc
          fileType.startsWith('text/plain') || // Compatibilidad con .txt
          fileType.startsWith('application/vnd.ms-excel') || // Compatibilidad con .xls
          fileType.startsWith('application/vnd.ms-powerpoint') // Compatibilidad con .ppt
        ) {
          const newDocument = { name: file.name, url: URL.createObjectURL(file) };
          newDocuments.push(newDocument);
        } else {
          error = true;
          setErrorMessage('Tipo de archivo no admitido. Solo se permiten documentos PDF, DOC, DOCX, TXT, XLS, XLSX, PPT, y PPTX.');
        }
      }

      // Si no hubo errores, actualizamos el estado
      if (!error) {
        setDocumentFiles((prev) => [...prev, ...newDocuments]);
        setErrorMessage('');
      }
    };

    input.click();
  };

  // Función para seleccionar fotos y actualizar el estado
  const selectImages = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*'; // Solo imágenes
    input.multiple = true; // Permite seleccionar múltiples archivos

    input.onchange = (event) => {
      const files = event.target.files;
      const newImagePreviews = [];
      let error = false;

      for (const file of files) {
        const fileType = file.type;
        if (fileType.startsWith('image/')) {
          const reader = new FileReader();
          reader.onloadend = () => {
            newImagePreviews.push(reader.result);
            if (newImagePreviews.length === files.length && !error) {
              setImagePreviewUrls((prev) => [...prev, ...newImagePreviews]);
              setErrorMessage('');
            }
          };
          reader.readAsDataURL(file);
        } else {
          error = true;
          setErrorMessage('Solo se permiten imágenes.');
        }
      }
    };

    input.click();
  };

  const selectVideos = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'video/*'; // Solo videos
    input.multiple = true; // Permite seleccionar múltiples archivos

    input.onchange = (event) => {
      const files = event.target.files;
      const newVideoPreviews = [];
      let error = false;

      for (const file of files) {
        const fileType = file.type;
        if (fileType.startsWith('video/')) {
          const reader = new FileReader();
          reader.onloadend = () => {
            newVideoPreviews.push(reader.result);
            if (newVideoPreviews.length === files.length && !error) {
              setVideoPreviewUrls((prev) => [...prev, ...newVideoPreviews]);
              setErrorMessage('');
            }
          };
          reader.readAsDataURL(file);
        } else {
          error = true;
          setErrorMessage('Solo se permiten videos.');
        }
      }
    };

    input.click();
  };

  const selectAudio = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "audio/*"; // Accept only audio files
    input.multiple = true; // Allow multiple file selection

    input.onchange = (event) => {
      const files = event.target.files;
      const newAudioBlobs = [];
      let error = false;

      for (const file of files) {
        if (file.type.startsWith("audio/")) {
          newAudioBlobs.push(file); // The file itself is a Blob

          if (newAudioBlobs.length === files.length && !error) {
            setAudioPreviewUrls((prev) => [...prev, ...newAudioBlobs]);
            setErrorMessage("");
          }
        } else {
          error = true;
          setErrorMessage("Only audio files are allowed.");
        }
      }
    };

    input.click();
  };

    const [currentView, setCurrentView] = useState(SIDEBARMENU); // 'sidebar', 'messages', 'chat'
    const [isMobile, setIsMobile] = useState(false);
    const [selectedChat, setSelectedChat] = useState(null);
    const [selectedWaId, setSelectedWaId] = useState(null);
    const [waIdValue, setWaIdValue] = useState("");
    const [focusTextArea, setFocusTextArea] = useState(null);

    const messagesEndRef = useRef(null);

    const searchChatByWaId = async ({ formatedWaId }) => {
      try {
        const chatdocRef = doc(db, "chats", formatedWaId);
    
        const result = await new Promise((resolve) => {
          let chatDocuments = [];
          const unsubscribe = onSnapshot(
            chatdocRef,
            (docSnapshot) => {
              if (docSnapshot.exists()) {
                const chatMessagesData = {
                  id: docSnapshot.id,
                  ...docSnapshot.data(),
                };
    
                chatDocuments = [{ ...docSnapshot.data() }];
    
                if (chatDocuments?.length > 0) {
                  const foundedChat = chatDocuments.find(
                    (message) => message.wa_id === formatedWaId
                  );
                  setSelectedChat(foundedChat);
                  console.log("foundedChat", foundedChat);
                }
    
                setChats([chatMessagesData]);
                setFilteredChats([chatMessagesData]);
    
                console.log("Documento del snapshot:", docSnapshot.data());
              } else {
                const newEmptyChat = {
                  id: formatedWaId,
                  wa_id: formatedWaId,
                  creationDate: new Date(),
                  messages: [],
                  statuses: [],
                  read: false,
                  profile_image: null,
                };
    
                setChats([newEmptyChat]);
                setSelectedChat(newEmptyChat);
                console.log("newEmptyChat", newEmptyChat);
              }
    
              resolve(true); // Resolución exitosa
            },
            (err) => {
              console.error("Error fetching messages:", err);
              setError(err.message || "An unknown error occurred.");
              resolve(false); // En caso de error, resolvemos con false
            }
          );
    
          // Guardamos la función para cancelar la suscripción
          setUnsubscribeOrderStateSnapshot(() => unsubscribe);
        });
    
        return result; // Retorna true o false según el resultado
      } catch (error) {
        console.error("Error en la actualización:", error);
        return false; // Devuelve false en caso de error
      } finally {
        setGlobalLoading(false);
      }
    };
    


  // Función para manejar la selección en el SidebarMenu
    const handleSidebarSelect = (menuOption) => {
      setCurrentView(menuOption); // Muestra la lista de mensajes
      setResponseMode(false);
      setSelectedMenuOption(null)
      if(selectedChat) {
        setSelectedChat(null);
      }
    };

    // Función para manejar la selección en el MessagesListWindow
    const handleMessageSelect = async (wa_id, isWidgetViewProp) => {
      setGlobalLoading(true);
      const chatDocuments = [...filteredChats];
      const formatedWaId = formatPhoneNumber(wa_id);
      // const snapshotValidation = unsubscribeOrderStateSnapshot && typeof unsubscribeOrderStateSnapshot === "function"

      // console.log("validacion handleMessageSelect", snapshotValidation)
      // console.log("validacion" , isWidgetView, chatDocuments.length !== 0, chats)

      // if (snapshotValidation) {
      //   unsubscribeOrderStateSnapshot();
      //   setUnsubscribeOrderStateSnapshot(null);
      // }
      let resultSearchByWaId = null;
      if (isWidgetViewProp) {
        resultSearchByWaId = await searchChatByWaId({formatedWaId});
        console.log("resultSearchByWaId", resultSearchByWaId)
      } else {
        if (chatDocuments?.length > 0) {
          const foundedChat = chatDocuments.find(
            (message) => message.wa_id === formatedWaId
          );
          setSelectedChat(foundedChat);
          console.log("foundedChat", foundedChat);
        }
      }
      // console.log("chats", chats);

      setSelectedWaId(formatedWaId);
      setCurrentView(CHATMESSAGE); // Muestra la ventana de chat
      setResponseMode(false);
      setSelectedMenuOption(null);
      setGlobalLoading(false);
      return true
    };

  const sendMessageToWaAPI = async (body) => {
    console.log("body", body);
    const endpoint = "wa_api/out";
    const resultSenMessage = await fetchFromCloudFunction(endpoint, body, "POST");
    console.log("resultSenMessage", resultSenMessage);


  }

  // Manejar el envío de mensajes
  const handleSendMessagge = async (waIdValue) => {
    setGlobalLoading(true);

    const waIdNumber =  typeof waIdValue === "string" ? waIdValue : selectedWaId
    // const waIdNumber = selectedWaId

    // console.log("VALIDACION", messageData?.context)
    const isFirstMessage = imagePreviewUrls.length === 1 || documentFiles.length === 1 || videoPreviewUrls.length === 1
    let firstMessageText =  isFirstMessage ? textValue : ""
    try {

      console.log("audioUrl && textValue  ", audioUrl, textValue === "")

      if (audioPreviewsUrls.length > 0 ) {

        audioPreviewsUrls.forEach(async (audioBlob) => {
          const filePath =  `waChat/${waIdNumber}/${"audios"}/${"fileName"}${Date.now()}.${"mp3"}`
          const url = await uploadAudioToFirebase(audioBlob, filePath);
          console.log("Uploaded file URL:", url);
          const body = {
            userData: { wa_id: waIdNumber },
            type: "audio",
            text: "",

            context: messageData?.context ? messageData?.context :null ,
            file_link: url,
            // file_link: "https://cdn.freesound.org/previews/577/577059_1015240-lq.mp3",
            // file_link: audioFirebaseUrl.data.file_link[0],
            template_content: null,
            interactive_content: null,
          };
          if(url) {
            // console.log("body en cliente antes de enviar el msj", body);
              await sendMessageToWaAPI(body);
            }
        });
        setAudioPreviewUrls([]);
        return
      }
      if(audioUrl && textValue === "") {
      // const audioBlob = await fetchAudioAsBlob(audioUrl); // Obtener el audio como Blob
      // console.log(audioBlob);
      // const audioFixedMp3 = await convertWavToMp3(audioBlobState)
      console.log("validacion",  audioBlobState)
      const audioUrlFirebase = await uploadAudioToFirebase(audioBlobState,  `waChat/${waIdNumber}/${"audios"}/${"fileName"}${Date.now()}.${"mp3"}`)
      console.log("audioUrlFirebase", audioUrlFirebase)

      // const bodyToSaveAudio = {
      //   // media_file: "https://cdn.freesound.org/previews/577/577059_1015240-lq.mp3",
      //   media_file: audioUrlFirebase,

      //   sender_wa_id: waIdNumber, // ID del remitente
      //   media_format: "mp3", // Formato
      //   media_type: "audios", // Tipo
      //   media_file_name: "fileName", // Nombre del archivo

      // }
      // const audioFirebaseUrl = await fetchFromCloudFunction("save_to_storage", bodyToSaveAudio, "POST");

        console.log("audioFirebaseUrl respuesta cloudfunction", audioUrlFirebase);
        const body = {
          userData: { wa_id: waIdNumber },
          type: "audio",
          text: "",
          context: messageData?.context ? messageData?.context :null ,
          file_link: audioUrlFirebase,
          // file_link: "https://cdn.freesound.org/previews/577/577059_1015240-lq.mp3",
          // file_link: audioFirebaseUrl.data.file_link[0],
          template_content: null,
          interactive_content: null,
        };
        setAudioUrl(null);
        setRecordBlobLink(null);
        setVisibleMicrophoneHandler(false)
        if(audioUrlFirebase) {
        // console.log("body en cliente antes de enviar el msj", body);
          await sendMessageToWaAPI(body);
          setAudioBlobState([])
          setMediaRecordedState(null)
        }
      }
      if(textValue !== "" && imagePreviewUrls.length === 0 && documentFiles.length === 0 && videoPreviewUrls.length === 0) {
        const body = {
          userData: { wa_id: waIdNumber },
          type: "text",
          text: textValue,
          file_link: null,
          template_content: null,
          interactive_content: null,
          context: messageData?.context ? messageData?.context :null
        };
        await sendMessageToWaAPI(body);
        setTextValue("");
        toastFunction(toast, "success", "Mensaje enviado con exito", "", 1000);
      } else {
      // Procesar videos
        if (videoPreviewUrls.length > 0) {
          await Promise.all(
            videoPreviewUrls.map(async (videoUrl) => {
              const uploadedVideoLink = await processSingleVideo(videoUrl, waIdNumber);
              // https://cdn.freesound.org/previews/577/577059_1015240-lq.mp3
              if (uploadedVideoLink) {
                // Enviar video
                const body = {
                  userData: { wa_id: selectedWaId },
                  type: "video",
                  text: firstMessageText,
                  file_link: uploadedVideoLink,
                  template_content: null,
                  context: messageData?.context ? messageData?.context : null,
                  interactive_content: null,
                };
                if (isFirstMessage) {
                  setTextValue("");
                  firstMessageText = "";
                }
                await sendMessageToWaAPI(body);
                toastFunction(toast, "success", "Video enviado con éxito", "", 1000);
              }
              return uploadedVideoLink;
            })
          );
          // Limpiar el estado después de procesar
          setVideoPreviewUrls([]);
        }
        // Procesar imágenes
        if (imagePreviewUrls?.length > 0) {
          await Promise.all(
            imagePreviewUrls.map(async (imageUrl) => {
              const uploadedImageLink = await processSingleImage(imageUrl, waIdNumber);
              // console.log("uploadedImageLink", uploadedImageLink, imageUrl)

              if (uploadedImageLink) {
                // Enviar imagen
                const body = {
                  userData: { wa_id: waIdNumber },
                  type: "image",
                  text: firstMessageText,
                  file_link: uploadedImageLink,
                  template_content: null,
                  context: messageData?.context ? messageData?.context :null ,
                  interactive_content: null,
                };
                if(isFirstMessage) {
                  setTextValue("");
                  firstMessageText = "";
                }
                await sendMessageToWaAPI(body);
                toastFunction(toast, "success", "Imagen enviada con exito", "", 1000);
              }
              return uploadedImageLink;
            })
          );
          // Limpiar el estado después de procesar
          setImagePreviewUrls([]);
        }
        // Procesar documentos
        if (documentFiles?.length > 0) {
          await Promise.all(
            documentFiles.map(async (doc) => {
              const uploadedDocumentLink = await processSingleDocument(doc);
              if (uploadedDocumentLink) {
                // Enviar documento
                const body = {
                  userData: { wa_id: waIdNumber },
                  type: "document",
                  text: doc.name || "",
                  file_link: uploadedDocumentLink,
                  context: messageData?.context ? messageData?.context :null ,
                  template_content: null,
                  interactive_content: null,
                };
                await sendMessageToWaAPI(body);
                toastFunction(toast, "success", "Documento enviada con exito", "", 1000);
              }
              return uploadedDocumentLink;
            })
          );
          // Limpiar el estado después de procesar
          setDocumentFiles([]);
        }
      }
      setResponseMode(false)
      setMessageData(null)
    } catch (error) {
      toastFunction(toast, "error", "Error enviando el mensaje ", error, 1000);
      console.error("Error processing files:", error);
    } finally {
      setFocusTextArea(false)
      setGlobalLoading(false);
    }
  };
  // // Procesar la url del audio local a blob
  // const fetchAudioAsBlob = async (audioUrl) => {
  //   try {
  //     const response = await fetch(audioUrl);
  //     if (!response.ok) throw new Error("Error fetching the audio file");
  //     const blob = await response.blob(); // Convertir la respuesta a Blob
  //     return blob;
  //   } catch (error) {
  //     console.error("Error fetching audio:", error);
  //     return null;
  //   }
  // };

  // Procesar el blob del audio
  const uploadAudioToFirebase = async (audioBlob, filePath) => {
    try {
      const storageRef = ref(storage, filePath);  // Define la ruta en Firebase Storage
  
      // Crear los metadatos con el contentType correcto
      const metadata = {
        contentType: 'audio/mpeg',  // Asegura que se guarde como MP3
      };
  
      // Subir el archivo con los metadatos
      const snapshot = await uploadBytes(storageRef, audioBlob, metadata);
  
      // Obtener la URL pública del archivo subido
      const downloadURL = await getDownloadURL(snapshot.ref);
  
      return downloadURL;  // Devuelve la URL pública
  
    } catch (error) {
      console.error("Error uploading audio:", error);
      return null;  // Si ocurre un error, devuelve null
    }
  };
  
  const processSingleVideo = async (videoUrl, waIdNumber) => {
    try {
      const fileName = `waChat/${waIdNumber}/videos/video_${Date.now()}`;
      const storageRef = ref(storage, fileName);
      await uploadString(storageRef, videoUrl, "data_url");
      const downloadURL = await getDownloadURL(storageRef);
      return downloadURL;
    } catch (error) {
      console.error("Error uploading video:", error);
      return null;
    }
  };
  // Procesar una sola imagen
  const processSingleImage = async (imageUrl, waIdNumber) => {
    try {
      const fileName = `waChat/${waIdNumber}/images/image_${Date.now()}`;
      const storageRef = ref(storage, fileName);
      await uploadString(storageRef, imageUrl, "data_url");
      const downloadURL = await getDownloadURL(storageRef);
      return downloadURL;
    } catch (error) {
      console.error("Error uploading image:", error);
      return null;
    }
  };

  // Procesar un solo documento
  const processSingleDocument = async (doc) => {
    try {
      const cleanName = doc.name
        .replace(/[^a-zA-Z0-9.\-_]/g, "") // Eliminar caracteres no permitidos
        .replace(/\s+/g, "_") // Reemplazar espacios por guiones bajos
        .toLowerCase();

      const fileName = `waChat/${selectedWaId}/documents/${Date.now()}_${cleanName}`;

      // Obtener el archivo como Blob
      const response = await fetch(doc.url);
      const blob = await response.blob();

      // Subir el archivo a Firebase Storage
      const storageRef = ref(storage, fileName);
      await uploadBytes(storageRef, blob);
      const downloadURL = await getDownloadURL(storageRef);
      return downloadURL;
    } catch (error) {
      console.error("Error uploading document:", error);
      return null;
    }
  };

  const [firstDoc, setFirstDoc] = useState(null); // Para almacenar el primer documento cargado
  const [lastDoc, setLastDoc] = useState(null); // Estado para guardar el último documento

  const handleChatSearch = (value) => {
    if (value === "") {
      // Si el valor está vacío, mostrar todos los chats previamente cargados
      setFilteredChats(chats);
      setLastDoc(null); // Reseteamos el último documento si no hay búsqueda
      setFirstDoc(null); // Para la página anterior
    } else {
      // Referencia a la colección
      const chatsCollection = collection(db, "chats");

      // Crear la consulta para buscar por `wa_id` y limitar a 20 documentos
      let chatsQuery = query(
        chatsCollection,
        where("wa_id", "==", value),
        limit(1)
      );

      // Configurar el snapshot
      const unsubscribe = onSnapshot(
        chatsQuery,
        (querySnapshot) => {
          const results = querySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          // Actualizamos el estado con los resultados filtrados
          setFilteredChats(results);
          setChats(results)

          // Guardamos el último documento para la siguiente consulta
          if (!querySnapshot.empty) {
            setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
            setFirstDoc(querySnapshot.docs[0]); // Para la página anterior
          }
        },
        (error) => {
          console.error("Error fetching chats by wa_id:", error);
          setFilteredChats([]); // Vaciar los resultados en caso de error
        }
      );

      // Opcional: Cleanup para evitar múltiples suscripciones
      return () => unsubscribe();
    }
  };


  let recorder = null;
  let gumStream = null;


  // Funcionalidad nueva
  const startHandle = async () => {
    if (!window.WebAudioRecorder) {
      console.error("WebAudioRecorder aún no ha sido cargado");
      alert("La grabadora de audio no está disponible.");
      return;
    }

    setElapsedTime(0);
    setVoice(true);
    setLoadingMediaDevices(true);

    try {
      //  Verificar permisos antes de solicitar acceso al micrófono
      const permissionStatus = await navigator.permissions.query({ name: "microphone" });

      if (permissionStatus.state === "denied") {
        console.error("Permiso de micrófono denegado.");
        alert("Debes otorgar permiso al micrófono para grabar audio.");
        setLoadingMediaDevices(false);
        return;
      }

      //  Asegurar que el usuario ha interactuado antes de inicializar el audio
      await new Promise((resolve) => setTimeout(resolve, 500));

      //  Obtener acceso al micrófono
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

      //  Crear contexto de audio (iOS necesita `resume()`)
      const AudioContext = window.AudioContext || window.webkitAudioContext;
      const audioContext = new AudioContext();

      if (audioContext.state === "suspended") {
        await audioContext.resume();
      }

      //  Fuente de audio
      const input = audioContext.createMediaStreamSource(stream);
      gumStream = stream; // Guardamos referencia para detenerlo después

      //  Configurar `WebAudioRecorder` si es compatible
      recorder = new window.WebAudioRecorder(input, {
        workerDir: "/WebAudioRecorder/lib/",
        encoding: "mp3",
        numChannels: 2,
        onEncoderLoading: (rec, encoding) => console.log(`Cargando encoder ${encoding}...`),
        onEncoderLoaded: (rec, encoding) => console.log(`Encoder ${encoding} cargado`),
      });

      recorder.onComplete = (rec, blob) => {
        console.log(" Grabación finalizada");
        const url = URL.createObjectURL(blob);
        setAudioBlobState(blob);
        setAudioUrl(url);
      };

      recorder.setOptions({
        timeLimit: 120,            // ⏳ Duración máxima de la grabación
        encodeAfterRecord: true,   
        mp3: { bitRate: 192 },     // 🎶 Bitrate MP3 a 192 kbps (calidad alta)
        ogg: { quality: 0.9 },     
        wav: { encoding: "PCM" }  
      });

      //  Iniciar grabación
      recorder.startRecording();
      console.log("🎙️ Grabación iniciada...");

      setVisibleMicrophoneHandler(true);
      setIsRunning(true);
      setLoadingMediaDevices(false);
      setMediaRecordedState(recorder);
      setMediaGumState(gumStream);

    } catch (err) {
      console.error(" Error al iniciar la grabación:", err);
      alert("No se pudo acceder al micrófono. Verifica los permisos en la configuración del navegador.");
      setLoadingMediaDevices(false);
    }
  };
  const stopHandle = () => {
    console.log("mediaRecordedState en stop", mediaRecordedState, mediaGumState.getAudioTracks()[0])
    if (mediaGumState) {
      // mediaGumState.getAudioTracks().forEach(track => track.stop());  // Detenemos el flujo de audio
      mediaGumState.getAudioTracks()[0].stop();
    }
  
    mediaRecordedState.finishRecording();

    // No hay método 'stop' en WebAudioRecorder, pero esto detiene el flujo de datos de audio
    console.log("Flujo de audio detenido");
    setVoice(false);
    setIsRunning(false);
  };

  
  useEffect(() => {
    console.log("Se renderizó el efecto inicial", selectedChat);

    if (isWidgetView) return;
    const chatsCollection = collection(db, "chats"); // Referencia a la colección

    // Crear la consulta para ordenar por `lastUpdate` de forma descendente y limitar los documentos
    const chatsQuery = query(
      chatsCollection,
      orderBy("lastUpdate", "desc"),
      limit(numberChatDocuments)
    );

    // Suscripción al snapshot
    const unsubscribe = onSnapshot(
      chatsQuery,
      (querySnapshot) => {
        const chatMessagesData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Actualizamos los chats y el estado relacionado
        console.log("bandera efecto", chatMessagesData)


        setChats(chatMessagesData);
        setFilteredChats(chatMessagesData);

        // Actualizamos los punteros de paginación
        if (!querySnapshot.empty) {
          setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
        }

      },
      (err) => {
        console.error("Error fetching messages:", err);
        setGlobalLoading(false)
        setError(err.message || "An unknown error occurred.");
      }
    );

    // Guardar el unsubscribe en el estado para gestionar desuscripciones previas
    setUnsubscribeOrderStateSnapshot(() => unsubscribe);

    // Cleanup para eliminar la suscripción al desmontar
    return () => {
      console.log("Limpiando suscripción previa...");
      setNumberChatDocuments(INITIALNUMBEROFCHATS)
      // unsubscribe();
    };
  }, []);

// console.log("snapshot guardado", unsubscribeOrderStateSnapshot)
  return (
    <ChatMenuContext.Provider
      value={{
        currentView, setCurrentView,

        menuChatOptions,
        selectedMenuOption,
        setSelectedMenuOption,
        handleChatMessageMenu,
        chatMessagesExample,
        messageData,
        shortcuts,
        scrollToElementById,
        videoPreviewUrls, setVideoPreviewUrls,
        imagePreviewUrls, setImagePreviewUrls,
        audioPreviewsUrls, setAudioPreviewUrls,
        documentFiles, setDocumentFiles,
        errorMessage, setErrorMessage,
        responseMode, setResponseMode,
        removeImage, removeDocument, removeVideo,
        recordBlobLink, setRecordBlobLink,
        clearHandle, voice, setVoice,
        visibleMicrophoneHandler, setVisibleMicrophoneHandler,
        audioUrl, setAudioUrl,
        selectDocuments, selectImages, selectVideos, selectAudio,
        elapsedTime, setElapsedTime,
        isRunning, setIsRunning,
        loadingMediaDevices,


        startHandle, stopHandle,
        // onStop,

        isMobile, setIsMobile, selectedChat, setSelectedChat, selectedWaId, setSelectedWaId, textValue, setTextValue,

        handleSidebarSelect, handleMessageSelect,handleSendMessagge, handleChatSearch,
        toast,
        chats, setChats, filteredChats, setFilteredChats, error,
        lastDoc, setLastDoc,
        firstDoc, setFirstDoc,
        isWidgetView, setIsWidgetView,
        focusTextArea, setFocusTextArea,
        waIdValue, setWaIdValue,
        messagesEndRef,
        numberChatDocuments ,setNumberChatDocuments,
        unsubscribeOrderStateSnapshot, setUnsubscribeOrderStateSnapshot,

        opChatOptions, handleOpenChatOptions, handlePinnedChat
      }}>
      {children}
    </ChatMenuContext.Provider>
  );
};

// Hook personalizado para usar el contexto
export const useChatMenu = () => {
  return useContext(ChatMenuContext);
};
