import styles from './CustomTextArea.module.css';
import { InputTextarea } from 'primereact/inputtextarea';

export default function CustomTextArea({id, floatLabel, type, value, placeholder, onChange, disabled,}) {
  if(floatLabel){
    return (
      <div className={styles.floatLabelContainer}>
        <span className="p-float-label">
          <InputTextarea
            id={id}
            autoResize
            type={type}
            value={value}
            onChange={onChange}
            className={styles.customFormComponent} 
            disabled={disabled}
          />
          <label htmlFor={id}>{placeholder}</label>
        </span>
      </div>
    )
  }else{
    return (
      <InputTextarea 
        id={id}
        autoResize 
        type={type}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        className={styles.customFormComponent} 
        disabled={disabled}
      />
    )
  }
}
