/* eslint-disable react/prop-types */
import { useState } from 'react';
import CustomTable from '../CustomTable/CustomTable';
import { getTasksConfig,  } from '../../pages/PQRs/tasksConfig/tasksConfig';
import formatDate from '../../utils/formatDate';

const PQRCardTables = ({ index, ordersData, orderColumns, pqrTableData, pqrTableColumns, secondRowCheckBox }) => {
  // Estado para manejar las selecciones de cada tabla
  const [rowCheckBox, setRowCheckBox] = useState([]);

  const tareas = getTasksConfig(pqrTableData)[pqrTableData?.PQR_type] || getTasksConfig((pqrTableData)["RECIBIDO"]);

  // console.log(" tareas", tareas)

  // const ejecutarAccion = async (accion) => {
  //   try {
  //     await accion(pqrTableData, { comentario: "Accion ejecutada desde la interfaz"})
  //   } catch (error) {
  //     console.error("Error al ejecutar la accion", error)
  //   }
  // }

  return (
    <div 
      key={`${pqrTableData.id}-${index}`}
      className="card-container p-4 bg-white shadow-md rounded-lg"
        >
          <div className='flex gap-8 items-center mb-4'>
            <p className="text-xl font-semibold ">PQR Info para {pqrTableData?.PQR_type || "Recibido"} </p>
            <p className='font-bold'>{formatDate(pqrTableData?.created_at)}</p>
            <p>Identificador de pedido relacionado :</p><p className='font-bold'>{pqrTableData?.related_order_id}</p>
          </div>
        <CustomTable
          selection={secondRowCheckBox}
          // onSelectionChange={(e) => onRowCheckBoxChange(e.value)}
          className="custom-table"
          columns={pqrTableColumns}
          data={tareas}
          action={()=> {}}
        />
          <CustomTable
            selection={rowCheckBox}
            onSelectionChange={(e) => setRowCheckBox(e.value)}
            className="custom-table mb-6"
            columns={orderColumns}
            data={ordersData}
          />
          
      
    </div>
  );
};

export default PQRCardTables;
