import { Accordion, AccordionTab } from "primereact/accordion";
import styles from "./MyOrders.module.css";
import { Divider } from "primereact/divider";
import { OverlayPanel } from "primereact/overlaypanel";
import { BiChevronLeft } from "react-icons/bi";
import GoBackButton from "../../Components/GoBackBtn/GoBackBtn";
import { useTranslation } from "react-i18next";
import CustomSelect from "../../Components/FormComponents/CustomSelect/CustomSelect";
import { useRef, useState } from "react";
import { useLoaderContext } from "../../contexts/LoaderContext/LoaderContext";
import { useAuthContext } from "../../contexts/Authcontext/Authcontext";
import useShopifyRequest from "../../hooks/useShopifyRequest";
import CustomTable from "../../Components/CustomTable/CustomTable";
import useFirebaseCRUD from "../../hooks/useFirebaseCRUD";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import CustomModal from "../../Components/CustomModal/CustomModal";
import DetailProductItemForm from "../../Components/DetailProductItemForm/DetailProductItemForm";
import VinculateItemsBatch from "../../Components/VinculateItemsBatch/VinculateItemsBatch.jsx";
import { useGlobalContext } from "../../contexts/GlobalContext/GlobalContext";
import MainButton from "../../Components/MainButton/MainButton";
import amountStoresIds from "../../utils/amountStoresIds.js"
import {
  // getAndAgroupItemsNotSyncedInOrders,
  getOrders,
  getOrdersAdmin,
  getOrdersByValue,
  getOrdersByCleanedFilters,
  getOrdersByCleanedFiltersAdmin,
  getSellerProducts,
} from "./scripts/getOrders";
import { MenuItems, OrderGuideLogsTableColumns, OrderTableColumns, OrderitemsTableColumns } from "./scripts/OrderTableColumns";
import { fetchFromCloudFunction } from "../../services/cloudFunctinons/fetchFromCloudFunction";
import { download_pdf_file, print_pdf_file_general, print_pdf_guide } from "../../utils/downloadGuidePdf/pdfBase64";
import { generateRandomId } from "../../utils/generateRandomId.js";
import { transformBase64Guide } from "../../utils/downloadGuidePdf/transformGuide";
import toastFunction from "../../utils/toastFunction";
import OrdersSellerFilters from "../../Components/OrdersSellerFilters/OrdersSellerFilters";
import OrdersAdminFilters from "../../Components/OrdersAdminFilters/OrdersAdminFilters";
import { collection, query, where } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import formatDate from "../../utils/formatDate.js";
import sortArrayByCreatedAt from "../../utils/sortArrayByCreatedAt.js";
import AuxStatusBadget from "../../Components/AuxStatusBadget/AuxStatusBadget.jsx";
import useLoadInitialMyOrdersData from "../../hooks/useLoadInitalMyOrdersData.js";
import useResize from "../../hooks/useResize.js";
import AccordionMyOrdersContent from "./AccordionMyOrdersContent.jsx";
import OrdersConfirmDialogs from "./OrdersConfirmDialogs.jsx";
import useOverlayPanel from "../../hooks/useOverlayPanel.js";
import { useSearchParams } from "react-router-dom";
import CustomCalendar from "../../Components/FormComponents/CustomCalendar/CustomCalendar.jsx";
import CustomInputText from "../../Components/FormComponents/CustomInputText/CustomInputText.jsx";
import { generateItemsConfirmStockRecords, generateItemsDisconfirmStockRecords } from "./scripts/stockRecord.js";
import WhatsAppCRM from "../../Components/WhatsAppCRM/WhatsAppCRM.jsx";
import { useChatMenu } from "../../Components/WhatsAppCRM/ChatWindow/ChatMenuContext.jsx";
import { CHATMESSAGE, INITIALNUMBEROFCHATS } from "../../Components/WhatsAppCRM/utils/CRMconstants.js";

// Componente de pagina que muestra y gestiona los pedidos de las tiendas asociadas al seller a otros sellers cuyas ordenes tengan un producto vinculado
const MyOrders = () => {
  const [searchParams] = useSearchParams();
  const checkoutIdValue = searchParams.get('checkoutId');
  console.log("checkoutIdValue", checkoutIdValue)
  // Si hay checkoutId en el parametro seleccionamos esa opcion como predeterminada
  const [ valueToFindOrder, setValueToFindOrder ] = useState(checkoutIdValue);
  const optionsTofind = [
    {name : "checkoutId", label: "Identificador de Checkout"},
    {name : "orderNumber", label: "Numero de orden"}
  ]
  const [ selectedOptionToFind, setSelectedOptionToFind ] = useState(valueToFindOrder ? optionsTofind[0] : optionsTofind[1]) 
  const { t } = useTranslation("MyOrders");
  const { 
    orderStates, shippingCompanies, orderAttributes, 
    stateColors, stateLabelColors, 
    shippingAddresTypes, reasonsToCancelAnOrder,
    orderPaymentStatus,
  } = useGlobalContext();

  const { updateDocument, deleteDocument, getDocumentsByQuery, addToArray, createDocument } = useFirebaseCRUD();
  const { globalLoading, setGlobalLoading } = useLoaderContext();
  const { userData, userSellerOwnerData } = useAuthContext();
  
  const { toast } = useShopifyRequest();
  const [selectedOrderRowData, setSelectedOrderRowData] = useState({});
  const [filteredSellerStores, setFilteredSellerStores] = useState([]);
  const [selectedStoreName, setSelectedStoreName] = useState({});
  const [orders, setOrders] = useState([]);
  const [zones, setZones] = useState([]);
  const [rowEditStates, setRowEditStates] = useState({});
  const [visibleDetailProductItemForm, setVisibleItemForm] = useState(false);
  const [visibleVinculateItemsBatch, setVisibleVinculateItemsBatch] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [selectedOrderStates, setSelectedOrderStates] = useState([]);
  const [selectedReasonToCancelAnOrder, setSelectedReasonToCancelAnOrder] = useState(null);
  const [unsubscribeOrderStateSnapshot, setUnsubscribeOrderStateSnapshot] = useState(null);

  const [visibleConfirmOrderDialog, setVisibleConfirmOrderDialog] = useState(false);
  const [visibleConfirmFullfillmentDialog, setVisibleConfirmFullfillmentDialog] = useState(false);
  const [visibleCancelAnOrderModal, setVisibleCancelAnOrderModal] = useState(false);
  const [visibleConfirmCancelAnOrderDialog, setVisibleConfirmCancelAnOrderDialog] = useState(false);
  const [visibleConfirmDeleteOrderDialog, setVisibleConfirmDeleteOrderDialog] = useState(false);
  const [visibleConfirmReactivateOrderDialog, setVisibleConfirmReactivateOrderDialog] = useState(false);
  const [visibleConfirmCancelShippingGuideDialog, setVisibleConfirmCancelShippingGuideDialog] = useState(false);
  const [visibleConfirmCreatePQR, setVisibleConfirmCreatePQR] = useState(false);


  const [multiSelectOptions, setMultiSelectOptions] = useState([]);

  const [visibleOrderFilters, setVisibleOrderFilters] = useState(false);


  const initialDate = new Date();
  initialDate.setHours(0, 0, 0, 0);
  const finishDate = new Date();
  finishDate.setHours(23, 59, 59, 999);
  
  const [dates, setDates] = useState([initialDate, finishDate]);


  const [amountSellerStoresIds, setAmountSellerStoresIds] = useState([]);
  const [selectAttributeOrders, setSelectAttributeOrder] = useState(null);

  const [attributesValues, setAttributesValues] = useState([]);
  const [attributesValuesOnBlur, setAttributesValuesOnBlur] = useState([]);

  const [currentlyEditingRowId, setCurrentlyEditingRowId] = useState(null);
  const [checkRelatedSuplierIds, setCheckRelatedSupplierIds] = useState(false);
  const [productsData, setProductsData] = useState([]);
  const [selectedProductOfData, setSelectedProductOfData] = useState(null);
  const [variationsData, setVariationsData] = useState([]);
  const [selectedVariationOfData, setSelectedVariationOfData] = useState([]);

  // Estados para el Insider
  const [sellerBusinessId, setSellerBusinessId] = useState(null);
  const [supplierId, setSupplierId] = useState(null);

  const [rowCheckBox, setRowCheckBox] = useState([]);
  const [selectAllRows, setSelectAllRows] = useState(null);
  const [activeIndex, setActiveIndex] = useState();
  const [hasFilterBySKU, setHasFilterBySKU] = useState(false);
  const [valuesSKUS, setValuesSKUS] = useState([]);
  const [valuesSKUSOnBlur, setValuesSKUSOnBlur] = useState([]);

  const [valueMultiCheckboxGuidesGenerated, setValueMultiCheckboxGuidesGenerated] = useState("Todas");
  const [rowLoading, setRowloading] = useState([]);
  const [sellerOwnerData, setSellerOwnerData] = useState([]);
  const isCoworker = !userData.isAdmin && userData?.related_seller_id && userData?.is_coworker;
  const isInsider = userData?.user_custom_claims?.type === "insider" || userData?.user_custom_claims?.type === "master"  ? true : false;

  const [isMobile, setIsMobile] = useState(window.innerWidth < 767);
  const { opItems, closeOverlayPanel } = useOverlayPanel();

  useLoadInitialMyOrdersData(
  {  selectedOptionToFind,
    valueToFindOrder,
    isInsider,
    isCoworker,
    userSellerOwnerData,
    userData,
    setSellerOwnerData,
    setFilteredSellerStores,
    setGlobalLoading,
    setZones,
    setOrders,
    setProductsData,
    setUnsubscribeOrderStateSnapshot,
    setAmountSellerStoresIds,
    unsubscribeOrderStateSnapshot,
    toast,
    isMobile, setIsMobile,t}
  );
  // console.log("Productos datos", productsData)
  useResize(setIsMobile);

  // console.log("selectedOrderRowData", selectedOrderRowData, zones)
  // const opItems = useRef(null);
  const opOrderStatus = useRef(null);
  const opLogs = useRef(null);
  const msgs = useRef(null);
  const accordionRef = useRef(null);
  const menu = useRef(null);

 
  // Función para manejar el menú de opciones para cada cliente en la tabla
  const optionMenu = (e, rowData) => {
    // Abre el menú de opciones y establece la fila de cliente seleccionada
    menu.current.toggle(e);
    setSelectedOrderRowData(rowData);
  };
  const options = [
    { name: "Ver pedidos de un vendedor en especifico", value: 1 },
    { name: "Ver pedidos con productos de un vendedor en especifico", value: 2 },
  ];
  const handleTransformAndPrintGuide = (rowData) => {
    if (!rowData.shipping_guide_pdf) {
      toastFunction(toast, "warn", "Es necesario generar la guia para visualizar el PDF");
      return;
    }
    const titlePDF = `${rowData.order_number}_${rowData.customer_full_name}`;

    switch (rowData.shipping_company) {
      case 1:
        download_pdf_file(rowData.shipping_guide_pdf, titlePDF);
        break;
      case 2:
        print_pdf_guide(rowData.shipping_guide_pdf, false, "Envia");
        break;
      case 3:
        print_pdf_guide(rowData.shipping_guide_pdf.interr_res_data, () => {}, "Interrapidisimo", titlePDF);
        break;
      default:
        break;
    }
  };
  const handleCreatePQR = async () => {
    const updatedOrderData = {
      ...selectedOrderRowData,
      pqr_generated: true,
    };
    
    
    const pqrObject = {
      id: generateRandomId(),
      created_at: new Date(),
      related_order_id: updatedOrderData?.id,
      state: {
        created_at: new Date(),
        id: generateRandomId(),
        state: 1,
        label: "RECIBIDO"
      },
      PQR_type: "RECIBIDO",
      total_order_price: selectedOrderRowData.total_order_price,
      order_number: selectedOrderRowData.order_number,
      line_items: [],
      checkout_id: selectedOrderRowData?.checkout_id || null,

    };
    const resultUpdate = await updateDocument("orders", updatedOrderData.id, updatedOrderData);
    const resultCreatePQR = await createDocument("pqrs", pqrObject.id, pqrObject);
    resultCreatePQR && resultUpdate
      ? toastFunction(toast, "success", t("toast-summary.createPQRSuccessfull"), `${selectedOrderRowData.order_number}`, 3000)
      : toastFunction(toast, "warn", t("toast-summary.createPQRFail"), 3000);
  };

  // Función para agregar un mensaje de guia al digitar el valor del atributo del filtro
  const addMessage = () => {
    msgs.current.show([
      {
        severity: "info",
        summary: "Info",
        detail: t("toast-detail.infoAttributeValues"),
        sticky: true,
        closable: false,
      },
    ]);
  };

  // Manejador de eventos cuando se enfoca el campo de entrada los atributos del producto
  const handleFocusChipAttributes = (setFunction, values) => {
    addMessage();
    setFunction(values);
  };

  // Manejador de eventos cuando se pierde el foco del componente Chips de atributos
  const handleBlurChipAttributes = (valuesOnBlur, values, setFunction) => {
    msgs.current.clear();
    if (valuesOnBlur == values) {
      setFunction(values);
      toastFunction(toast, "warn", t("toast-summary.failChip"), "", 2000);
    }
  };

  // Función para manejar la apertura del modal de confirmación de la orden y guardar datos de la fila seleccionada
  const handleConfirmOrderModal = (rowData) => {
    setSelectedOrderRowData(rowData); // Guardar los datos de la fila seleccionada en el estado  
    if (rowData.payment_method === 1 ) {
      setVisibleConfirmOrderDialog(true);
    }else if ( rowData.payment_method === 2 &&rowData?.online_payment_status === 2) {
      setVisibleConfirmOrderDialog(true);
    }else {
      toastFunction(toast, "info", "Para confirmar una orden online el pago debe estar aprobado", "", 3000)
    }
  };

  const handleConfirmFullfilmentModal = async (rowData) => {
    if (
      !selectedOrderRowData.order_is_confirmed ||
      !selectedOrderRowData.shipping_guide_pdf ||
      !selectedOrderRowData.guide_generated
    ) {
      toastFunction(
        toast,
        "warn",
        "Para marcar un pedido como Empacado primero debes confirmarlo y generarle una guia",
        "",
        4000
      );
      return;
    }
    let transformed_shipping_guide_pdfbase64;
    setSelectedOrderRowData(rowData); // Guardar los datos de la fila seleccionada en el estado
    switch (rowData?.shipping_company) {
      case 1:
        print_pdf_file_general(rowData.shipping_guide_pdf, () => setVisibleConfirmFullfillmentDialog(true));
        break;
      case 2:
        print_pdf_guide(rowData.shipping_guide_pdf, () => {}, "Envia");

        setVisibleConfirmFullfillmentDialog(true);
        break;
      case 3:
        transformed_shipping_guide_pdfbase64 = await transformBase64Guide(
          rowData.shipping_guide_pdf.interr_res_data,
          rowData.shipping_company_details.name
        );
        // print_pdf_guide(rowData.shipping_guide_pdf.interr_res_data, () => setVisibleConfirmFullfillmentDialog(true), "Interrapidisimo", rowData.order_number);
        print_pdf_file_general(transformed_shipping_guide_pdfbase64, () => setVisibleConfirmFullfillmentDialog(true));

        // print_pdf_file_general(rowData.shipping_guide_pdf.interr_res_data, () => setVisibleConfirmFullfillmentDialog(true))
        break;

      default:
        break;
    }
  };

  const handleCancelAnOrderModal = (rowData) => {
    setVisibleCancelAnOrderModal(true);
    setSelectedOrderRowData(rowData); // Guardar los datos de la fila seleccionada en el estado
  };

  // Función para actualizar una fila de la tabla de las Ordenes OrderTableColumns en la base de datos
  const updateRowOrder = async (rowId) => {
    const orderSeleccionada = {...selectedOrderRowData};
    // evita que se sobreeescriba la confirmacion de la orden y para que los dos procesos sean independientes
    delete orderSeleccionada.order_is_confirmed;
    const resultUpdate = await updateDocument("orders", rowId, orderSeleccionada);
    // Desactiva la edición para la fila correspondiente
    setRowEditStates((prevrowEditStates) => ({
      ...prevrowEditStates,
      [rowId]: false,
    }));
    resultUpdate
      ? toastFunction(toast, "success", t("toast-summary.updateOrderSuccefull"), `${orderSeleccionada.order_number}`, 3000)
      : toastFunction(toast, "warn", t("toast-summary.updateFail"), 3000);
  };

  // Función que maneja el cambio en los datos de una fila (rowData) para un campo específico (fieldName) con un nuevo valor (newValue) de la tabla OrderTableColumns
  const handleRowChange = (rowData, fieldName, newValue) => {
    let updatedRowData = { ...rowData };
    if (fieldName === "shipping_company_details") {
      // Actualiza los datos de la fila según el campo y el nuevo valor
      updatedRowData = {
        ...updatedRowData,
        ["shipping_company"]: newValue.code,
        [fieldName]: newValue,
      };
    } else if (fieldName === " d") {
      updatedRowData = {
        ...updatedRowData,
        ["city_code"]: newValue.city_code,
        [fieldName]: newValue,
      };
    } else {
      updatedRowData = {
        ...updatedRowData,
        [fieldName]: newValue,
      };
    }
    // Actualiza la lista de pedidos con la fila modificada
    const updatedOrders = orders.map((order) => (order.id === rowData.id ? updatedRowData : order));
    // Actualiza el estado de los pedidos y la fila de datos seleccionada
    setOrders(updatedOrders);
    setSelectedOrderRowData(updatedRowData);
  };

  // Cierra el modal del item
  const closeItemModal = () => {
    setVisibleItemForm(false);
  };

  const closeVinculateItemModal = () => {
    setVisibleVinculateItemsBatch(false);
  };

  // Abre el panel de superposición de elementos con los datos de la fila seleccionada
  const openItemOverlayPanel = async (rowData, e) => {
    setSelectedOrderRowData(rowData); // Guardar los datos de la fila seleccionada en el estado
    if (isInsider) {
      const q = query(collection(db, 'seller_users'),
        where("uid", "==", rowData?.related_seller_id),
     );
      const sellerOwnerData = await getDocumentsByQuery(q);
      const sellerOwnerStores = sellerOwnerData[0]?.seller_stores;
      setFilteredSellerStores(sellerOwnerStores);
    }
    opItems.current.toggle(e); // // Abre el overlay
  };

  // Abre el panel de superposición de elementos con los datos de la fila seleccionada
  const openOrderStatusOverlayPanel = async (rowData, e) => {
    setSelectedOrderRowData(rowData); // Guardar los datos de la fila seleccionada en el estado
    opOrderStatus.current.toggle(e); // // Abre el overlay
  };

  // Abre el panel de superposición de elementos con los datos de la fila seleccionada
  const openLogsOverlayPanel = (rowData, e) => {
    setSelectedOrderRowData(rowData); // Guardar los datos de la fila seleccionada en el estado
    opLogs.current.toggle(e); // // Abre el overlay
  };
  // const openConfirmDeleteOrderDialog = (rowData) => {
  //   setSelectedOrderRowData(rowData);
  //   setVisibleConfirmDeleteOrderDialog(true);
  // }
  // Activa/desactiva el modo de edición para una fila dada y muestra un mensaje informativo
  const toggleEdit = (rowData) => {
    // Desactiva la fila actualmente en modo de edición (si la hay)
    if (currentlyEditingRowId !== null) {
      setRowEditStates((prevRowEditStates) => ({
        ...prevRowEditStates,
        [currentlyEditingRowId]: false,
      }));
    }
    // Activa la nueva fila en modo de edición
    setRowEditStates((prevRowEditStates) => ({
      ...prevRowEditStates,
      [rowData.id]: true,
    }));
    setCurrentlyEditingRowId(rowData.id);
    setSelectedOrderRowData(rowData);
    toast.current.show({
      severity: "info",
      summary: t("toast-summary.hasEdit"),
      detail: rowData.order_number,
      life: 3000,
    });
  };
 
  // Maneja la selección de una tienda basado en el id y actualiza los estados correspondientes
  const handleStoreSelect = (store) => {
    setSelectedStoreName(store);
    if (store.id !== 1) {
      // const type = "oneStore";
      const filteredStore = filteredSellerStores.find((stor) => stor.id === store.id);
      const filteredStorArray = [filteredStore];
      const arrayStoresIds = amountStoresIds(filteredStorArray);
      setAmountSellerStoresIds(arrayStoresIds.storesIds);
    } else {
      const arrayStoresIds = amountStoresIds(filteredSellerStores);
      const arrayWithoutDefaultOption = arrayStoresIds.storesIds.filter((id) => {
        return id !== 1;
      });
      setAmountSellerStoresIds(arrayWithoutDefaultOption);
    }
    setRowEditStates([]);
  };

  // Maneja la confirmación/desconfirmación de una orden
  const handleOrderConfirmation = async () => {
    try {
      const { id, order_is_confirmed } = selectedOrderRowData;
      // Toggle de order_is_confirmed
      const updatedOrderIsConfirmed = !order_is_confirmed;
      // Actualiza el documento en Firestore
      const collectionOrdersName = "orders"; // Reemplaza esto con tu nombre real de colección

      const updatedOrderData = {
        ...selectedOrderRowData,
        order_is_confirmed: updatedOrderIsConfirmed,
      };
      
      const resultUpdateDocument = await updateDocument(collectionOrdersName, id, updatedOrderData);
      if (resultUpdateDocument) {
        if (!updatedOrderIsConfirmed) {
          // Aqui iria la creacion de documentos stocks records
          await generateItemsConfirmStockRecords(selectedOrderRowData.line_items || []);
          toastFunction(toast, "success", t("toast-summary.orderDisconfirm"), updatedOrderData.order_number, 3000);
        } else {
          // Aqui iria la creacion de documentos stocks records
          await generateItemsDisconfirmStockRecords((selectedOrderRowData.line_items || []), "Orden desconfirmada");
          await 
          toastFunction(toast, "success", t("toast-summary.orderConfirm"), updatedOrderData.order_number, 3000);
        }
      } else {
        toastFunction(toast, "error", t("toast-summary.updateFail"), updatedOrderData.order_number, 3000);
      }
    } catch (error) {
      console.error(t("toast-summary.updateFail"), error);
    }
  };

  const handleOrderFulfillment = async () => {
    try {
      const { id } = selectedOrderRowData;
      const newState = {
        id: generateRandomId(),
        created_at: new Date(),
        state: 3,
        state_label: "Empacado",
      };
      // Actualiza el documento en Firestore
      const updatedData = {
        ...selectedOrderRowData,
        last_state: 3,
        states: [...selectedOrderRowData.states, newState],
      };
      const resultUpdateDocument = await updateDocument("orders", id, updatedData);
      if (resultUpdateDocument) {
        toastFunction(toast, "success", t("toast-summary.orderFullfillment"), updatedData.order_number, 3000);
      } else {
        toastFunction(toast, "error", t("toast-summary.updateFail"), updatedData.order_number, 3000);
      }
    } catch (error) {
      console.error(t("toast-summary.updateFail"), error);
    }
  };

  const handleConfirmCancelAnOrder = async () => {
    setGlobalLoading(true);
      const { id } = selectedOrderRowData;
      // Actualiza el documento en Firestore
      const newState = {
        id: generateRandomId(),
        created_at: new Date(),
        state: 8,
        state_label: "Cancelado",
      };
      const resultUpdate = await updateDocument("orders", id,
        {
          order_is_cancelled: true,
          reason_to_cancel_order: selectedReasonToCancelAnOrder,
          last_state: newState.state
        }
      );
      const resultUnion = await addToArray("orders", id, "states", newState);
      if (resultUpdate && resultUnion) {
        toastFunction(toast, "success", t("toast-summary.orderCancelSuccess"), selectedOrderRowData.order_number, 3000);
      } else {
        toastFunction(toast, "error", t("toast-summary.updateFail"), selectedOrderRowData.order_number, 3000);
      }
      setGlobalLoading(false);
      setVisibleCancelAnOrderModal(false);
      setSelectedReasonToCancelAnOrder(null);

  };

  // Maneja la edición de un elemento y actualiza el estado correspondiente
  const handleEdit = (rowData) => {
    setVisibleItemForm(true);
    if (rowData.id) {
      setSelectedItem(rowData);
    } else {
      setSelectedItem({});
    }
  };
  // Función para actualizar un campo de promoción en el estado seleccionado de los pedidos
      
  const updatePromotionField = (fieldName, newValue) => {
    setSelectedOrderStates((prevValues) => ({
      ...prevValues,
      [fieldName]: newValue,
    }));
  };

  // console.log("Orden Seleccionada", selectedOrderRowData)
  // Función para manejar la consulta de pedidos
  const handleConsultOrders = async (e) => {
    setGlobalLoading(true)
    if (e) {e.preventDefault();}
    // Desuscribirse de la suscripción actual si existe
    if (unsubscribeOrderStateSnapshot && typeof unsubscribeOrderStateSnapshot === "function") {
      unsubscribeOrderStateSnapshot();
      setUnsubscribeOrderStateSnapshot(null);
    }

    // Iniciar la nueva suscripción dependiendio si es insider o no 
    let newUnsubscribe;
    if (isInsider) {
      newUnsubscribe = await getOrdersAdmin(
        selectedOrderStates,
        sellerBusinessId,
        supplierId,
        dates,
        attributesValues,
        selectAttributeOrders,
        setOrders,
        toast,
        t,
        multiSelectOptions,
        valuesSKUS,
        valueMultiCheckboxGuidesGenerated,
        setVisibleOrderFilters,
      );
    } else if (!isInsider && valueToFindOrder) {
      newUnsubscribe = await getOrdersByValue(dates, selectedOptionToFind, valueToFindOrder, setOrders, setZones, toast, t,);
    }  else {
      newUnsubscribe = await getOrders(
        selectedOrderStates,
        dates,
        amountSellerStoresIds,
        attributesValues,
        selectAttributeOrders,
        setOrders,
        toast,
        t,
        checkRelatedSuplierIds,
        sellerOwnerData.business_id,
        valuesSKUS,
        valueMultiCheckboxGuidesGenerated,
        setVisibleOrderFilters,
        setZones,
        setGlobalLoading
      );
    }
     
    // Establecer la nueva función de desuscripción
    setUnsubscribeOrderStateSnapshot(() => {
      // Desuscribirse de la suscripción actual si existe
      if (
        unsubscribeOrderStateSnapshot &&
        typeof unsubscribeOrderStateSnapshot === "function" 
      ) {
        unsubscribeOrderStateSnapshot();
      }
      return newUnsubscribe;
    });
    setGlobalLoading(false)
  };
  // Función para limpiar los filtros de búsqueda
  const cleanFilters = () => {
    // setSelectedStoreName([]);
    setSelectedOrderStates([]);
    setSelectAttributeOrder(null);
    setAttributesValues([]);
    setDates([]);
    setValuesSKUS("");
    setSelectedVariationOfData(null);
    setSelectedProductOfData(null);
    setRowCheckBox([]);
    setSelectAllRows(null);
    setValueMultiCheckboxGuidesGenerated(null);
    setValueToFindOrder("");
    toastFunction(toast, "info", t("toast-summary.cleanFiltersSuccess"), "", 2000);
  };

// Funcion que control
  const cleanFiltersAndConsultOrders = async () => {
    try {
      let unsubscribeOrders = null;
      cleanFilters();
      // Obtener los pedidos por fecha con onSnapshot
      if (isInsider) {
        unsubscribeOrders = await getOrdersByCleanedFiltersAdmin(setOrders, toast, t, setGlobalLoading);
      } else {
        const arrayStoresIds = amountStoresIds(sellerOwnerData?.seller_stores);
        setAmountSellerStoresIds(arrayStoresIds.storesIds);
    
        unsubscribeOrders = await getOrdersByCleanedFilters(arrayStoresIds, setOrders, toast, t, sellerOwnerData.business_id, setZones, setGlobalLoading);
        await getSellerProducts(sellerOwnerData.business_id, setProductsData, toast, t);
        setVisibleOrderFilters(false);
      }
      return () => {
        if (unsubscribeOrders) {
          unsubscribeOrders(); // Desuscribirse de la consulta al desmontar el componente
        }
      };
    } catch (error) {
      console.error(error)
    }
  }

  const cleanRowsSelection = () => {
    setSelectAllRows(null);
    setRowCheckBox([]);
  };

  const onSelectAllRowsChange = (e) => {
    const isChecked = e.checked;
    setSelectAllRows(isChecked);

    if (isChecked) {
      const allRowIds = orders.map((row) => row.id);
      setRowCheckBox(allRowIds);
      setActiveIndex(0);
    } else {
      setRowCheckBox([]);
      setActiveIndex(null);
    }
  };

  const onRowCheckBoxChange = (e, rowData) => {
    let _rowCheckBox = [...rowCheckBox];

    if (e.checked) {
      _rowCheckBox.push(rowData.id);
      setActiveIndex(0);
    } else {
      _rowCheckBox = _rowCheckBox.filter((id) => id !== rowData.id);
      setSelectAllRows(false);
      // setActiveIndex(null);
    }
    setRowCheckBox(_rowCheckBox);
  };
  
  // Funciones que validan campos en las ordenes
  const checkCityCodeInOrders = (array) => {
    // Verifica si el array es válido y no está vacío
    if (!Array.isArray(array) || array.length === 0) {
      return false;
    }
    // Verifica si todos los objetos tienen la clave shipping_destination.city_code
    const allObjectsHaveCityCode = array.every(
      (obj) => obj && obj.shipping_destination && obj.shipping_destination.city_code !== undefined
    );

    // Si no todos los objetos tienen city_code, muestra el toast y retorna false
    if (!allObjectsHaveCityCode) {
      toastFunction(toast, "warn", t("toast-summary.ordersWhithoutDestiny"), "", 4000);
      return false;
    }
    // Si todos los objetos tienen city_code, retorna true
    return true;
  };

  const checkShippingGuideNumber = (array, shouldHaveShippinGuideNumber) => {
    let result;
    // Verifica si el array es válido y no está vacío
    if (!Array.isArray(array) || array.length === 0) {
      return false;
    }
    // Verifica si algún objeto tiene la clave shipping_guide_number con algún valor
    if (!shouldHaveShippinGuideNumber) {
      const hasShippingGuideNumber = array.some((obj) => obj?.shipping_guide_number !== null);
      // Si algún objeto tiene la clave con valor, muestra una notificación y retorna false
      if (hasShippingGuideNumber) {
        toastFunction(toast, "warn", t("toast-summary.batchOrdersDontHasShippingGuide"), "", 4000);
        result = false;
      } else {
        result = true;
      }
    } else {
      const hasShippingGuideNumber = array.every((obj) => obj?.shipping_guide_number);
      // Si algún objeto tiene la clave con valor, muestra una notificación y retora true
      if (!hasShippingGuideNumber) {
        toastFunction(toast, "warn", t("toast-summary.batchOrdersHasShippingGuide"), "", 5000);
        result = false;
      } else {
        result = true;
      }
    }
    // Si ningún objeto tiene la clave con valor, retorna true
    return result;
  };

  const checkBase64InOrders = (array) => {
    // Verifica si el array es válido y no está vacío
    if (!Array.isArray(array) || array.length === 0) {
      return false;
    }
    // Verifica si todos los objetos tienen la clave shipping_destination.base64
    const allObjectsHaveBase64 = array.every(
      (obj) =>
        obj &&
        obj.shipping_guide_pdf &&
        obj.shipping_guide_pdf !== undefined &&
        obj.shipping_guide_pdf !== null &&
        obj.shipping_guide_pdf !== "" &&
        obj.guide_generated === true
    );

    // Si no todos los objetos tienen base64, muestra el toast y retorna false
    if (!allObjectsHaveBase64) {
      toastFunction(toast, "warn", t("toast-summary.allObjectsHaveBase64"), "", 4000);
      return false;
    }
    // Si todos los objetos tienen el base64 y la guia esta generada, retorna true
    return true;
  };

  const checkOrderIsConfirmed = (array) => {
    // Verifica si el array es válido y no está vacío
    if (!Array.isArray(array) || array.length === 0) {
      return false;
    }
    // Verifica si todos los objetos tienen la clave shipping_destination.city_code
    const allObjectsHaveCityCode = array.every((obj) => obj && obj.order_is_confirmed == true);
    // Si no todos los objetos tienen city_code, muestra el toast y retorna false
    if (!allObjectsHaveCityCode) {
      toastFunction(toast, "warn", t("toast-summary.ordersWhithoutConfirmation"), "", 4000);
      return false;
    }
    // Si todos los objetos tienen city_code, retorna true
    return true;
  };

  const toggleFilterBySKU = () => {
    setHasFilterBySKU((prevHaFilterBySKU) => !prevHaFilterBySKU);
    setValuesSKUS([]);
    // setProductId(null);
    setVariationsData(null);
  };
  
  // Funcion que toma un array de ids y retorna las ordenes correspondientes
  const filterOrdersByIds = (idsArray) => {
    return orders.filter((order) => idsArray.includes(order.id));
  };

  // Funcion para manejar la genera  cion de guias inidividuales
  const handleGenerateIndividualGuide = async (rowData) => {
    let endpoint = "quote";
    if (rowData.guide_generated) {
      toastFunction(toast, "info", "Este pedido ya cuenta con una guia activa", "", 3000);
      return;
    }
    if (rowLoading.includes(rowData.id)) {
      // Si está en estado de carga, eliminar el ID para indicar que la carga ha terminado
      setRowloading((prevLoading) => prevLoading.filter((id) => id !== rowData.id));
      return;
    }
    // Establecer el ID en estado de carga para mostrar el loader
    setRowloading((prevLoading) => [...new Set([...prevLoading, rowData.id])]);

    if (checkCityCodeInOrders([rowData]) && checkOrderIsConfirmed([rowData])) {
      try {
        const quoteRes = await fetchFromCloudFunction(endpoint, rowData, "POST");
        setRowloading((prevLoading) => prevLoading.filter((id) => id !== rowData.id));
        if (quoteRes.data.success === true) {
          toastFunction(
            toast,
            "success",
            `${t("Respuesta exitosa, revisa los registros de la orden")} ${rowData.order_number}`,
            quoteRes.data.msg,
            10000
          );
        } else if (quoteRes.data.error === true) {
          toastFunction(
            toast,
            "error",
            `${t("toast-summary.generateGuideFailed")} ${rowData.order_number}`,
            rowData.order_number,
            10000
          );
        }
      } catch (error) {
        console.error("Error creando la cotizacion:", error);
        if (rowLoading.includes(rowData.id)) {
          // Si está en estado de carga, eliminar el ID para indicar que la carga ha terminado
         toastFunction(toast, "warn", "Datos incorrectos", "", 3000)

          setRowloading((prevLoading) => prevLoading.filter((id) => id !== rowData.id));
          return;
        }
        return { error: "local/error" };
      }
    } else {
      toastFunction(toast, "warn", "Datos incorrectos", "", 3000)
    }
    setRowloading((prevLoading) => prevLoading.filter((id) => id !== rowData.id));
  };

  // Funcion para manejar la generacion de guias por lotes
  const handleGenerateBatchGuides = async () => {
    setGlobalLoading(true);
    let endpoint = "batchUtils/batchOrdersQuote";
    const filteredOrdersByIds = filterOrdersByIds(rowCheckBox);

    if (
      checkShippingGuideNumber(filteredOrdersByIds, false) &&
      checkCityCodeInOrders(filteredOrdersByIds) &&
      checkOrderIsConfirmed(filteredOrdersByIds)
    ) {
      try {
        const batchRes = await fetchFromCloudFunction(endpoint, filteredOrdersByIds, "POST");
        setActiveIndex(null);
        setGlobalLoading(false);
        // setValueMultiCheckboxGuidesGenerated(null);
        if (batchRes.status === 200) {
          try {
              // await generateCoordinadoraGuide();
              toastFunction(toast, "success", t("toast-summary.batchSuccess"), "", 4000);
              toastFunction(
                toast,
                "info",
                t("Tus ordenes estaran siendo procesadas, en un momento podras revisar los registros"),
                "",
                6000
              );
            return { error: null };
          } catch (error) {
            toastFunction(toast, "error", t("Error generando el lote de guias"), error, 5000);
            return error;
          }
        }
        setActiveIndex(null);
        return { guideSuccess: false };
      } catch (error) {
        toastFunction(toast, "error", t("Error creando el lote"), error, 4000);
        return error;
      }
    } else {
      toastFunction(toast, "warn", "Datos incorrectos", "", 3000);
    }
    setGlobalLoading(false);
  };

  const handleDownloadBatchPdfsGuides = () => {
    setGlobalLoading(true);
    const filteredOrdersByIds = filterOrdersByIds(rowCheckBox);

    if (checkBase64InOrders(filteredOrdersByIds) && checkOrderIsConfirmed(filteredOrdersByIds)) {
      for (const index in filteredOrdersByIds) {
        const order = filteredOrdersByIds[index];
        const titlePDF = `${order.order_number}_${order.customer_full_name}`;
        download_pdf_file(order.shipping_guide_pdf, titlePDF);
        toastFunction(toast, "info", t("toast-summary.orderPDFDownload"),  `${order.order_number} descargado`, 4000);
      }
      toastFunction(toast, "success", t("toast-summary.downloadPDFSuccess"), `cantidad : ${filteredOrdersByIds.length}`, 4000);
    }
    setActiveIndex(null);
    setGlobalLoading(false);
  };

  const handleConfirmBatchOrders = async () => {
    setGlobalLoading(true);
    const filteredOrdersByIds = filterOrdersByIds(rowCheckBox);

    let endpoint = "batchUtils/batchOrdersConfirm";
    if (checkCityCodeInOrders(filteredOrdersByIds)) {
      try {
        const batchRes = await fetchFromCloudFunction(endpoint, filteredOrdersByIds, "POST");
        setActiveIndex(null);
        setGlobalLoading(false);
        if (batchRes.status === 200) {
              toastFunction(toast, "success", t("toast-summary.batchSuccess"), "", 4000);
              batchRes.data.results.forEach((result) => {
                toastFunction(toast, "success", result.msg, "", 4000);
              });            
        } else {
            console.error("Error generando el lote de guias", batchRes.status);
        }
        setActiveIndex(null);
        return { guideSuccess: false };
      } catch (error) {
        console.error("Error creando el lote:", error);
        return error;
      }
    } else {
      toastFunction(toast, "warn", "Datos incorrectos", "", 3000);
    }
    setGlobalLoading(false);
  };

  const handleFullfillmentBatchOrders = async () => {
    setGlobalLoading(true);
    let endpoint = "batchUtils/batchOrdersFullfillment";
    const filteredOrdersByIds = filterOrdersByIds(rowCheckBox);

    if (
      checkCityCodeInOrders(filteredOrdersByIds) &&
      checkOrderIsConfirmed(filteredOrdersByIds) &&
      checkShippingGuideNumber(filteredOrdersByIds, true)
    ) {
      try {
        const batchRes = await fetchFromCloudFunction(endpoint, filteredOrdersByIds, "POST");
        setActiveIndex(null);
        setGlobalLoading(false);
        // setValueMultiCheckboxGuidesGenerated(null);
        if (batchRes.status === 200) {
              toastFunction("success", t("toast-summary.batchSuccess"), "", 4000);
              batchRes.data.results.forEach((result) => {
                toastFunction(toast, "success", result.msg, "", 4000);
              }); 
        } else {
            console.error("Error generando el lote de fullfillment", batchRes);
        }
        setActiveIndex(null);
        return { guideSuccess: false };
      } catch (error) {
        console.error("Error creando el lote:", error);
        return error;
      }
    } else {
      toastFunction(toast, "warn", "Datos incorrectos", "", 3000);
    }
    setGlobalLoading(false);
  };

  const handleProductSelect = (e) => {
    setSelectedProductOfData(e);
    setVariationsData(e.variations);
  };

  const handleVariationSelect = (e) => {
    setSelectedVariationOfData(e);
    setValuesSKUS(e.sku);
  };

  const handleConfirmDeleteOrder = async () => {
    const resultDeleteOrder = await deleteDocument("orders", selectedOrderRowData.id);
    if (resultDeleteOrder) {
      toastFunction(toast, "success", "Pedido eliminado con exito", selectedOrderRowData.order_number, 3000);
      setSelectedOrderRowData(null);
    }
  };

  
  const handleReactiveOrder =  async () => {
    setGlobalLoading(true);
    const newState =  { 
      id: generateRandomId(), created_at: new Date(),
      state: 2, state_label: "En preparación"
    };
    const resultUpdate = await updateDocument("orders", selectedOrderRowData.id, {last_state: newState.state, 
      order_is_cancelled: false
    });
    const resultUnion= await addToArray("orders", selectedOrderRowData.id, "states", newState);
    if (resultUpdate && resultUnion) {
      toastFunction(toast, "success", `Orden ${selectedOrderRowData.order_number} reactivada con exito`, "", 3000);
    }else {
      toastFunction(toast, "error", `La orden ${selectedOrderRowData.order_number} no pudo ser desactivada con exito`, "", 3000);
    }
    setGlobalLoading(false);
  };
  
  const handleCancelShippingGuide =  async () => {
    setGlobalLoading(true);
    const canceledGuideData =  { 
      id: generateRandomId(),
      cancellation_date: new Date(),
      shipping_company_details: selectedOrderRowData?.shipping_company_details,
      shipping_destination: selectedOrderRowData?.shipping_destination,
      shipping_quote_cost: selectedOrderRowData?.shipping_quote_cost,
      subtotal_order_price: selectedOrderRowData?.subtotal_order_price,
      shipping_guide_number: selectedOrderRowData?.shipping_guide_number,
      shipping_guide_pdf: selectedOrderRowData?.shipping_guide_pdf,
      shipping_guide_id_remision: selectedOrderRowData?.shipping_guide_id_remision,
    };
    const fieldsToUpdate = {
      shipping_guide_number: null,
      shipping_guide_pdf: null,
      shipping_guide_id_remision: null,
      guide_generated: false,
      guide_cancelled: true,
    }
    const resultUpdate = await updateDocument("orders", selectedOrderRowData.id, {...fieldsToUpdate});
    const resultUnion= await addToArray("orders", selectedOrderRowData.id, "cancelled_guides", canceledGuideData);
    if (resultUpdate && resultUnion) {
      toastFunction(toast, "success", `Guia anulada con exito`, "", 3000);
    }else {
      toastFunction(toast, "error", `La guia no pudo ser anulada`, "", 3000);
    }
    setGlobalLoading(false);
  }

  const menuItems = MenuItems({t,
    selectedOrderRowData,
    handleGenerateIndividualGuide,
    handleTransformAndPrintGuide,
    handleConfirmFullfilmentModal,
    handleCancelAnOrderModal,
    setVisibleConfirmReactivateOrderDialog,
    setVisibleConfirmCancelShippingGuideDialog
  });

  const [visibleWhastappCRMModal, setVisibleWhastappCRMModal] = useState(false)
  const { handleMessageSelect, handleSidebarSelect, setIsWidgetView, setFocusTextArea, setNumberChatDocuments} = useChatMenu();

  const handleOpenWhatsappCRM = async (rowData) => {
    setNumberChatDocuments(INITIALNUMBEROFCHATS)

    setIsWidgetView(true);
    await handleMessageSelect(rowData?.customer_phone_number, true)
    setVisibleWhastappCRMModal(true);
    setFocusTextArea(true)
    handleSidebarSelect(CHATMESSAGE);
  };

  // Definición de columnas para la tabla de pedidos
  const orderColumns = OrderTableColumns({
    t,
    orderStates,
    stateColors,
    stateLabelColors,
    handleRowChange,
    openItemOverlayPanel,
    rowEditStates,
    shippingCompanies,
    handleConfirmOrderModal,
    toggleEdit,
    updateRowOrder,
    zones: zones.zones,
    rowCheckBox,
    onRowCheckBoxChange,
    onSelectAllRowsChange,
    selectAllRows,
    shippingAddresTypes,
    openLogsOverlayPanel,
    rowLoading,
    menu,
    menuItems,
    globalLoading,
    optionMenu,
    openOrderStatusOverlayPanel,
    orderPaymentStatus,
    isMobile,
    opItems,
    closeOverlayPanel,
    handleOpenWhatsappCRM,
    setVisibleConfirmCreatePQR,
    showSelectColumns: true,
    setSelectedOrderRowData,
  }
  );
 
  const orderStatesTableColumns = [
  {
    field: "checkbox",
    filterable: false,
    sortable: false,
    body : ( orderState ) => {
       return (
            <span >{formatDate(orderState?.created_at)}</span>
        );
    }
  },
   {
    field: "checkbox",
    filterable: false,
    sortable: false,
    body : ( orderState ) => {
       return (
            <AuxStatusBadget
                  statusLabel={orderStates
                    .filter((state) => state.code === orderState?.state)
                    .map((matchingState) => (
                      <span
                        key={matchingState?.code}
                        style={{
                          backgroundColor: stateColors[matchingState.code],
                          color: "white",
                          padding: "4px 8px",
                          borderRadius: "4px",
                          margin: "4px 0",
                          display: "flex",
                        }}
                      >
                        {matchingState?.name}
                      </span>
                    ))}
            />
        );
    }
  },
  ];
  console.log("selectedOrderRowData", selectedOrderRowData);

  return (
    <>
      <div
        style={{marginBottom: "40px"}}
      >
        <GoBackButton icon={BiChevronLeft} text={isInsider ? t("labelGoBackBtnInsider") : t("labelGoBackBtnSeller")} />
      </div>
      <div style={{ marginBottom: "40px", display: "grid", gap: "20px" }}>
        <Divider />
        { (sellerOwnerData?.is_supplier || isInsider) ? 
          (<Button
          className={styles.secondaryButton}
          type={"button"}
          label={t("openFiltersToConsult")}
          loading={globalLoading}
          onClick={() => setVisibleOrderFilters(true)}
          />) 
          :
          (<div className="grid">
            <CustomCalendar
               dates={dates}
               setDates={(e) => setDates(e.target.value)}
               selectionMode="range"
               placeholder={t("input-placeholders.calendarRange")}
            />
           <CustomSelect
            floatLabel={true}
            optionLabel={"label"}
            options={optionsTofind}
            value={selectedOptionToFind}
            placeholder={t("input-placeholders.findOrderBy")}
            onChange={(e) => setSelectedOptionToFind(e.value)}
            disabled={!valueToFindOrder}
          />
           <CustomInputText
            floatLabel={true}
            type={"text"}
            value={valueToFindOrder}
            placeholder={t("input-placeholders.id")}
            onChange={(e) => setValueToFindOrder(e.target.value)}
          />
 
            <div className="grid gap-2">
              <MainButton
                  style={{ marginTop: "20px" }}
                  type={"button"}
                  label={t("consultOrders")}
                  loading={globalLoading}
                  onClick={handleConsultOrders}
                  disabled={dates[1] === null}
                  autoFocus
                />
              <Button
                  className={styles.secondaryButton}
                  type={"button"}
                  label={t("cleanFilters")}
                  loading={globalLoading}
                  onClick={cleanFilters}
                />
            </div>
            
           </div>)
        }
        <Divider />
      </div>
      <article className="mb-20 ">
        <CustomTable 
          selection={rowCheckBox}
          onSelectionChange={(e) => setRowCheckBox(e.value)} 
          className="custom-table" columns={orderColumns} data={orders} action={() => {}} />
      </article>
      <div className="dock-demo mt-12">
        {rowCheckBox.length !== 0 && (
          <div
            ref={accordionRef}
            className={`dock-window ${styles.accordionWrapper}`}
          >
          <Accordion activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
              <AccordionTab header={t("batchActions")}>
              <AccordionMyOrdersContent
                rowCheckBox={rowCheckBox}
                cleanRowsSelection={cleanRowsSelection}
                globalLoading={globalLoading}
                handleConfirmBatchOrders={handleConfirmBatchOrders}
                handleGenerateBatchGuides={handleGenerateBatchGuides}
                handleDownloadBatchPdfsGuides={handleDownloadBatchPdfsGuides}
                handleFullfillmentBatchOrders={handleFullfillmentBatchOrders}
                t={t}
                styles={styles}
              />
              </AccordionTab>
          </Accordion>
          </div>
        )}
      </div>
      <Divider />

      <Toast ref={toast} />

      <div className="card flex flex-column align-items-center gap-3">
        <OverlayPanel ref={opItems} showCloseIcon closeOnEscape={false} dismissable={false}>
          <CustomTable 
            selectionMode={"single"}
            className="custom-table"
            columns={OrderitemsTableColumns({t, handleEdit, selectedOrderRowData})}
            data={selectedOrderRowData?.line_items}
            action={() => {}} 
          />

          <div className="ml-10 mt-6 mb-4">
            <Button
              icon="pi pi-plus-circle"
              onClick={handleEdit}
              className="p-button-rounded p-button-outline"
              disabled={selectedOrderRowData?.order_is_cancelled || selectedOrderRowData?.shipping_guide_number}
            />
          </div>
        </OverlayPanel>
      </div>
      <div className="card flex flex-column align-items-center gap-3">
        <OverlayPanel ref={opLogs} showCloseIcon closeOnEscape dismissable={true}>
          <CustomTable 
              selectionMode={"single"}
              className="custom-table"
              columns={OrderGuideLogsTableColumns(t, selectedOrderRowData)}
              data={selectedOrderRowData?.guide_generation_msgs}
              action={() => {}} 
            />
        </OverlayPanel>
      </div>

    <div className="card flex flex-column align-items-center gap-3">
      <OverlayPanel ref={opOrderStatus} showCloseIcon closeOnEscape dismissable={true}>
          <CustomTable
              data={sortArrayByCreatedAt(selectedOrderRowData?.states)}
              action={() => {}}
              columns={orderStatesTableColumns}
              className="custom-table"
            />
      </OverlayPanel>
    </div>
    <div>
      <CustomModal
        visible={visibleDetailProductItemForm}
        setVisible={setVisibleItemForm}
        object={selectedItem}
        editMode={t("editItemProduct")}
        createMode={t("createItemProduct")}
        content={
          <DetailProductItemForm
            filteredSellerStores={filteredSellerStores}
            productItemData={selectedItem}
            toast={toast}
            onClose={closeItemModal}
            selectedOrderRowData={selectedOrderRowData}
            onCloseOverlay={openItemOverlayPanel}
            orders={orders}
            setSelectedOrderRowData={setSelectedOrderRowData}
          />
        }
        onClose={closeItemModal}
      />
      <CustomModal
        visible={visibleVinculateItemsBatch}
        setVisible={setVisibleVinculateItemsBatch}
        createMode={t("vinculateItemsWithShopifyProducts")}
        content={
          <VinculateItemsBatch
            visible={visibleVinculateItemsBatch}
            amountSellerStoresIds={amountSellerStoresIds}
            filteredSellerStores={filteredSellerStores}
            toast={toast}
            onClose={closeVinculateItemModal}
          />
        }
        onClose={closeVinculateItemModal}
      />
      <CustomModal
        visible={visibleCancelAnOrderModal}
        setVisible={setVisibleCancelAnOrderModal}
        object={selectedItem}
        createMode={t("cancelAnOrder")}
        content={
          <form style={{ marginTop: "20px" }}>
            <CustomSelect
            required={true}
              floatLabel={true}
              disabled={globalLoading}
              optionLabel={"name"}
              options={reasonsToCancelAnOrder}
              value={selectedReasonToCancelAnOrder}
              placeholder={t("input-placeholders.selectReasonToCancelAnOrder")}
              onChange={(e) => setSelectedReasonToCancelAnOrder(e.value)}
            />
            <MainButton
              style={{ marginTop: "20px" }}
              type={"button"}
              label={t("cancelAnOrder")}
              disabled={globalLoading}
              loading={globalLoading}
              onClick={() => {
                if (selectedReasonToCancelAnOrder) {
                  setVisibleConfirmCancelAnOrderDialog(true);
                }else {
                  toastFunction(toast, "warn" ,"Porfavor selecciona el motivo de la cancelacion", "", 3000)
                }
              }}
              autoFocus
            />
          </form>
        }
        onClose={() => setVisibleCancelAnOrderModal(false)}
      />
      <CustomModal
        visible={visibleOrderFilters}
        setVisible={setVisibleOrderFilters}
        content={
          isInsider ? (
            <OrdersAdminFilters
              attributesValues={attributesValues}
              attributesValuesOnBlur={attributesValuesOnBlur}
              cleanFilters={cleanFilters}
              cleanFiltersAndConsultOrders={cleanFiltersAndConsultOrders}
              globalLoading={globalLoading}
              dates={dates}
              handleBlurChipAttributes={handleBlurChipAttributes}
              handleConsultOrders={handleConsultOrders}
              handleFocusChipAttributes={handleFocusChipAttributes}
              handleVariationSelect={handleVariationSelect}
              hasFilterBySKU={hasFilterBySKU}
              msgs={msgs}
              multiSelectOptions={multiSelectOptions}
              orderAttributes={orderAttributes}
              orderStates={orderStates}
              selectAttributeOrders={selectAttributeOrders}
              selectedOrderStates={selectedOrderStates}
              selectedVariationOfData={selectedVariationOfData}
              sellerBusinessId={sellerBusinessId}
              setAttributesValues={setAttributesValues}
              setAttributesValuesOnBlur={setAttributesValuesOnBlur}
              setDates={setDates}
              setMultiSelectOptions={setMultiSelectOptions}
              setSelectAttributeOrder={setSelectAttributeOrder}
              setSelectedOrderStates={setSelectedOrderStates}
              setSellerBusinessId={setSellerBusinessId}
              supplierId={supplierId}
              setSupplierId={setSupplierId}
              setValuesSKUS={setValuesSKUS}
              setValuesSKUSOnBlur={setValuesSKUSOnBlur}
              setVariationsData={setVariationsData}
              setVisibleVinculateItemsBatch={setVisibleVinculateItemsBatch}
              t={t}
              options={options}
              toggleFilterBySKU={toggleFilterBySKU}
              toast={toast}
              updatePromotionField={updatePromotionField}
              valueMultiCheckboxGuidesGenerated={valueMultiCheckboxGuidesGenerated}
              valuesSKUS={valuesSKUS}
              valuesSKUSOnBlur={valuesSKUSOnBlur}
              setValueMultiCheckboxGuidesGenerated={setValueMultiCheckboxGuidesGenerated}
              variationsData={variationsData}

            />
          )
           : ( 
            <OrdersSellerFilters
            attributesValues={attributesValues}
            attributesValuesOnBlur={attributesValuesOnBlur}
            cleanFilters={cleanFilters}
            cleanFiltersAndConsultOrders={cleanFiltersAndConsultOrders}
            globalLoading={globalLoading}
            dates={dates}
            filteredSellerStores={filteredSellerStores}
            handleBlurChipAttributes={handleBlurChipAttributes}
            handleConsultOrders={handleConsultOrders}
            handleFocusChipAttributes={handleFocusChipAttributes}
            handleProductSelect={handleProductSelect}
            handleStoreSelect={handleStoreSelect}
            handleVariationSelect={handleVariationSelect}
            hasFilterBySKU={hasFilterBySKU}
            msgs={msgs}
            checkRelatedSuplierIds={checkRelatedSuplierIds}
            orderAttributes={orderAttributes}
            orderStates={orderStates}
            selectAttributeOrders={selectAttributeOrders}
            selectedOrderStates={selectedOrderStates}
            selectedProductOfData={selectedProductOfData}
            selectedStoreName={selectedStoreName}
            selectedVariationOfData={selectedVariationOfData}
            setAttributesValues={setAttributesValues}
            setAttributesValuesOnBlur={setAttributesValuesOnBlur}
            setDates={setDates}
            setCheckRelatedSupplierIds={setCheckRelatedSupplierIds}
            setSelectAttributeOrder={setSelectAttributeOrder}
            setSelectedOrderStates={setSelectedOrderStates}
            setValuesSKUS={setValuesSKUS}
            setValuesSKUSOnBlur={setValuesSKUSOnBlur}
            setVisibleVinculateItemsBatch={setVisibleVinculateItemsBatch}
            t={t}
            toggleFilterBySKU={toggleFilterBySKU}
            updatePromotionField={updatePromotionField}
            valueMultiCheckboxGuidesGenerated={valueMultiCheckboxGuidesGenerated}
            valuesSKUS={valuesSKUS}
            valuesSKUSOnBlur={valuesSKUSOnBlur}
            setValueMultiCheckboxGuidesGenerated={setValueMultiCheckboxGuidesGenerated}
            variationsData={variationsData}
            productsData={productsData}
            
            
          />
          )
        }
        onClose={() => setVisibleOrderFilters(false)}
      />
       <CustomModal
        visible={visibleWhastappCRMModal}
        setVisible={setVisibleWhastappCRMModal}
        object={selectedItem}
        content={
          <WhatsAppCRM/>
        }
        onClose={() =>     setVisibleWhastappCRMModal(false)}/>
     <OrdersConfirmDialogs
        t={t}
        visibleConfirmOrderDialog={visibleConfirmOrderDialog}
        setVisibleConfirmOrderDialog={setVisibleConfirmOrderDialog}
        selectedOrderRowData={selectedOrderRowData}
        handleOrderConfirmation={handleOrderConfirmation}
        visibleConfirmFullfillmentDialog={visibleConfirmFullfillmentDialog}
        setVisibleConfirmFullfillmentDialog={setVisibleConfirmFullfillmentDialog}
        handleOrderFulfillment={handleOrderFulfillment}
        visibleConfirmCancelAnOrderDialog={visibleConfirmCancelAnOrderDialog}
        setVisibleConfirmCancelAnOrderDialog={setVisibleConfirmCancelAnOrderDialog}
        handleConfirmCancelAnOrder={handleConfirmCancelAnOrder}
        visibleConfirmReactivateOrderDialog={visibleConfirmReactivateOrderDialog}
        setVisibleConfirmReactivateOrderDialog={setVisibleConfirmReactivateOrderDialog}
        handleReactiveOrder={handleReactiveOrder}
        visibleConfirmDeleteOrderDialog={visibleConfirmDeleteOrderDialog}
        setVisibleConfirmDeleteOrderDialog={setVisibleConfirmDeleteOrderDialog}
        handleConfirmDeleteOrder={handleConfirmDeleteOrder}
        visibleConfirmCancelShippingGuideDialog={visibleConfirmCancelShippingGuideDialog}
        setVisibleConfirmCancelShippingGuideDialog={setVisibleConfirmCancelShippingGuideDialog}
        handleCancelShippingGuide={handleCancelShippingGuide}
        visibleConfirmCreatePQR={visibleConfirmCreatePQR}
        setVisibleConfirmCreatePQR={setVisibleConfirmCreatePQR}
        handleCreatePQR={handleCreatePQR}
      />
    </div>
    </>
  );
};
export default MyOrders;
