import { useEffect, useState } from 'react';
import amountStoresIds from '../utils/amountStoresIds';
import { getOrdersByValue, getOrdersByIdsDateDefault, getOrdersDateDefaultAdmin, getSellerProducts } from '../pages/MyOrders/scripts/getOrders';

const useLoadInitialMyOrdersData = (
 {   selectedOptionToFind,
    valueToFindOrder,
    isInsider,
    isCoworker,
    userSellerOwnerData,
    userData,
    setSellerOwnerData,
    setFilteredSellerStores,
    setGlobalLoading,
    setZones,
    setOrders,
    setProductsData,
    setUnsubscribeOrderStateSnapshot,
    setAmountSellerStoresIds,
    unsubscribeOrderStateSnapshot,
    toast,
    setIsMobile,
    t,}
  ) => {
    
    const [unsubscribeOrders, setUnsubscribeOrders] = useState(null);
        
  useEffect(() => {
    setGlobalLoading(true)
    let userOwnerData;
    console.log("Se activo el hook de mis ordenes")

    if (!isInsider) {
      if (isCoworker && userSellerOwnerData) {
        userOwnerData = userSellerOwnerData;
        setSellerOwnerData(userSellerOwnerData);
      } else {
        userOwnerData = userData;
        setSellerOwnerData(userData);
      }
      const sellerOptionsWithGenericOption = userOwnerData?.seller_stores 
        ? [...userOwnerData.seller_stores, { id: 1, name: "Todas las tiendas" }] 
        : [{ id: 1, name: "Todas las tiendas" }];
      setFilteredSellerStores(sellerOptionsWithGenericOption);
    }

    const fetchInitialData = async () => {
      try {
        // Desuscribirse de la suscripción actual si existe
        if (unsubscribeOrderStateSnapshot && typeof unsubscribeOrderStateSnapshot === "function") {
          unsubscribeOrderStateSnapshot();
        }

        let newUnsubscribeOrders;
        if (isInsider) {
          newUnsubscribeOrders = await getOrdersDateDefaultAdmin(null, null, setZones, setOrders,  toast, t);
        } else if (!isInsider && valueToFindOrder) {
          newUnsubscribeOrders = await getOrdersByValue(null, selectedOptionToFind, valueToFindOrder, setOrders, setZones, toast, t,);
        } else if ( valueToFindOrder) {
          newUnsubscribeOrders = await getOrdersByValue(null, selectedOptionToFind, valueToFindOrder, setOrders, setZones, toast, t,);

        } else {
          const arrayStoresIds = amountStoresIds(userOwnerData.seller_stores);
          setAmountSellerStoresIds(arrayStoresIds.storesIds);
          newUnsubscribeOrders = await getOrdersByIdsDateDefault(arrayStoresIds.storesIds, setZones, setOrders, toast, t, userOwnerData.business_id);
          await getSellerProducts(userOwnerData.business_id, setProductsData, toast, t);
        }

        setUnsubscribeOrders(() => {
          if (unsubscribeOrderStateSnapshot && typeof unsubscribeOrderStateSnapshot === "function") {
            unsubscribeOrderStateSnapshot();
          }
          return newUnsubscribeOrders;
        });
      } catch (error) {
        console.error("Error al obtener los pedidos:", error);
      } finally {
        setGlobalLoading(false);
      }
    };
    setGlobalLoading(false);

    if (userData && !unsubscribeOrders) {
      fetchInitialData();
    }

    const handleResize = () => {
      setIsMobile(window.innerWidth < 767);
    };


    // Efecto de limpieza para desuscribirse de la consulta al desmontar el componente
    return () => {
      window.removeEventListener('resize', handleResize);

      if (unsubscribeOrders) {
        unsubscribeOrders(); // Desuscribirse de la consulta al desmontar el componente
      }
    };
  }, [userSellerOwnerData, unsubscribeOrderStateSnapshot]);

  return unsubscribeOrders;
};

export default useLoadInitialMyOrdersData;
