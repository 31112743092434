import {
    ActionAñadirSolucionPedido,
    ActionCargarComprobanteTransferenciaRetracto,
    ActionCargarConsecutivoNotaCredito,
    ActionCargarCuentaBancariaCliente,
    ActionCargarGuiaEnviadaHaciaCliente,
    ActionCargarGuiaEnviadaPorCliente,
    ActionCerrarPorTiempoEspera,
    ActionContactarCliente,
    ActionEmpacar,
    ActionEnviarComprobanteTransferenciaCliente,
    ActionEnviarGuiaEnvioHaciaCliente,
    ActionIngresarItems,
    ActionMontoADevolver,
    ActionPoliticaEnvioPQR,
    ActionProcedePQR,
    ActionRevisarCambio,
    ActionSeleccionarTipoPQR } from "./actions";

    export const getTasksConfig = (pqrTableData) => {

        const pqrHasSolution = pqrTableData?.solution?.solution;
    
        const config = {
            RECIBIDO: [
                {
                    id: "1",
                    label: "Contactar cliente",
                    actions: [ActionContactarCliente]
                },
                {
                    id: "2",
                    label: "Seleccionar tipo de PQR",
                    actions: [ActionSeleccionarTipoPQR]
                },
                {
                    id: "3",
                    label: "Cerrar por exceso de tiempo de espera",
                    actions: [ActionCerrarPorTiempoEspera]
                },
            ],
             CAMBIO : [
                {
                    id: "1",
                    label: "Contactar cliente",
                    actions: [ActionContactarCliente]
                },
                {
                    id: "2",
                    label: "¿Añadir solución a nuevo pedido?",
                    actions: [ActionAñadirSolucionPedido]
                },
                ...(!pqrHasSolution ? [
                    {
                        id: "3",
                        label: "Ingresar items requeridos",
                        actions: [ActionIngresarItems]
                    },
                    {
                        id: "4",
                        label: "Política de envío PQR",
                        actions: [ActionPoliticaEnvioPQR]
                    },
                    {
                        id: "5",
                        label: "Cargar guía enviada por cliente",
                        actions: [ActionCargarGuiaEnviadaPorCliente]
                    },
                    {
                        id: "6",
                        label: "¿Procede el PQR?",
                        actions: [ActionProcedePQR]
                    },
                    {
                        id: "7",
                        label: "Empacar",
                        actions: [ActionEmpacar]
                    },
                    {
                        id: "8",
                        label: "Cargar guía envío hacia cliente",
                        actions: [ActionCargarGuiaEnviadaHaciaCliente]
                    },
                    {
                        id: "9",
                        label: "Enviar guía envío hacia cliente",
                        actions: [ActionEnviarGuiaEnvioHaciaCliente]
                    },
                    {
                        id: "10",
                        label: "Cerrar por exceso de tiempo de espera",
                        actions: [ActionCerrarPorTiempoEspera]
                    }
                ] : [
                    {
                        id: "6",
                        label: "¿Procede el PQR?",
                        actions: [ActionProcedePQR]
                    }
                ])
            ],
            
            GARANTIA: [
                {
                    id: "1",
                    label: "Contactar cliente",
                    actions: [ActionContactarCliente]
                },
                {
                    id: "2",
                    label: "¿Añadir solucion a nuevo pedido?",
                    actions: [ActionAñadirSolucionPedido]
                },
                {
                    id: "3",
                    label: "Ingresar items requeridos",
                    actions: [ActionIngresarItems]
                },
                {
                    id: "4",
                    label: "Politica de envio PQR",
                    actions: [ActionPoliticaEnvioPQR]
                },
                {
                    id: "5",
                    label: "Cargar guia enviada por cliente",
                    actions: [ActionCargarGuiaEnviadaPorCliente]
                },
                {
                    id: "6",
                    label: "¿Procede el PQR?",
                    actions: [ActionProcedePQR]
                },
                {
                    id: "7",
                    label: "Empacar",
                    actions: [ActionEmpacar]
                },
                {
                    id: "8",
                    label: "Cargar guia envio hacia cliente",
                    actions: [ActionCargarGuiaEnviadaHaciaCliente]
                },
                {
                    id: "9",
                    label: "Enviar guia envio hacia cliente",
                    actions: [ActionEnviarGuiaEnvioHaciaCliente]
                },
                {
                    id: "10",
                    label: "Cerrar por exceso de tiempo de espera",
                    actions: [ActionCerrarPorTiempoEspera]
                },
            ],
            RETRACTO: [
                {
                    id: "1",
                    label: "Contactar cliente",
                    actions: [ActionContactarCliente]
                },
                {
                    id: "2",
                    label: "Cargar guia enviada por cliente",
                    actions: [ActionCargarGuiaEnviadaPorCliente]
                },
                {
                    id: "3",
                    label: "¿Procede el PQR?",
                    actions: [ActionProcedePQR]
                },
                {
                    id: "4",
                    label: "Cargar cuenta bancaria cliente",
                    actions: [ActionCargarCuentaBancariaCliente]
                },
                {
                    id: "5",
                    label: "Monto a devolver",
                    actions: [ActionMontoADevolver]
                },
                {
                    id: "6",
                    label: "Cargar comprobante transferencia de retracto",
                    actions: [ActionCargarComprobanteTransferenciaRetracto]
                },
                {
                    id: "7",
                    label: "Enviar comprobante de transferencia al cliente",
                    actions: [ActionEnviarComprobanteTransferenciaCliente]
                },
                {
                    id: "8",
                    label: "Cargar consecutivo NOTA CREDITO",
                    actions: [ActionCargarConsecutivoNotaCredito]
                },
                {
                    id: "9",
                    label: "Cerrar por exceso de tiempo de espera",
                    actions: [ActionCerrarPorTiempoEspera]
                },
            ],
        };
    
        
        return config;
    };
    