import { useTranslation } from "react-i18next";
import { useLoaderContext } from "../../contexts/LoaderContext/LoaderContext";

import { useEffect, useMemo, useRef, useState } from "react";
import CustomSelect from "../../Components/FormComponents/CustomSelect/CustomSelect";
import { Toast } from "primereact/toast";
import { useGlobalContext } from "../../contexts/GlobalContext/GlobalContext";
import { collection, doc, getDoc, onSnapshot, orderBy, query, where } from "firebase/firestore";
import { db } from "../../firebase/firebase";
import { OrderGuideLogsTableColumns, OrderitemsTableColumns, OrderTableColumns, PQRTableColumns } from "../MyOrders/scripts/OrderTableColumns";
import PQRCardTables from "../../Components/PQRCardTables/PQRCardTables";
import useOverlayPanel from "../../hooks/useOverlayPanel";
import { OverlayPanel } from "primereact/overlaypanel";
import CustomTable from "../../Components/CustomTable/CustomTable";
import { Button } from "primereact/button";
import sortArrayByCreatedAt from "../../utils/sortArrayByCreatedAt";
import formatDate from "../../utils/formatDate";
import AuxStatusBadget from "../../Components/AuxStatusBadget/AuxStatusBadget";
import useShopifyRequest from "../../hooks/useShopifyRequest";

export default function PQRs() {
    const { t } = useTranslation("MyOrders");
    
    const { 
        globalLoading, 
        setGlobalLoading
    } = useLoaderContext();

    const { toast } = useShopifyRequest();
    const { pqrQuerys } = useGlobalContext();
    const [selectedPQRType, setSelectedPQRType] = useState();
    const [unsubscribePQRs, setUnsubscribePQRs] = useState();
    const [combinedPQRData, setCombinedPQRData] = useState([]);
    const { opItems } = useOverlayPanel();
    const [selectedOrderRowData, setSelectedOrderRowData] = useState({});
    const opOrderStatus = useRef(null);
    const opLogs = useRef(null);
    const [zones, setZones] = useState([]);

    const { 
        orderStates, shippingCompanies, 
        stateColors, stateLabelColors, 
        shippingAddresTypes,
        orderPaymentStatus,
      } = useGlobalContext();

        console.log(globalLoading)

    const handleSelectPQRQuery = (type) => {
        console.log(globalLoading)
        try {
            setSelectedPQRType(type);
            console.log("type", type);
    
            if (unsubscribePQRs) unsubscribePQRs();
    
            let field = null;
            let fieldValue = null;
            if (type.code === 1 || type.code === 5) {
                field = "state.state";
                fieldValue = type.code
            } else if (type.code === 2) {
                field = "PQR_type";
                fieldValue = "CAMBIO"
            } else if (type.code === 3) {
            field = "PQR_type";
            fieldValue =  "GARANTIA"
            } else if (type.code === 4) {
                field = "PQR_type";
                fieldValue =  "RETRACTO"
            } else {
                console.error("Tipo desconocido");
                return;
            }
    
            const pqrsRef = collection(db, "pqrs");
            const pqrsQuery = query(pqrsRef, where(field, "==", fieldValue), orderBy("created_at", "desc"));
    
            const unsubscribe = onSnapshot(pqrsQuery, (snapshot) => {
                try {
                    const data = snapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
                    const relatedIds = data.map(doc => doc.related_order_id);
    
                    if (relatedIds.length > 0) {
                        const ordersRef = collection(db, "orders");
                        const ordersQuery = query(ordersRef, where("id", "in", relatedIds));
    
                        onSnapshot(ordersQuery, (ordersSnapshot) => {
                            try {
                                const relatedOrders = ordersSnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }));
    
                                const combinedData = data.map(pqr => {
                                    const relatedOrder = relatedOrders.find(order => order.id === pqr.related_order_id);
                                    return {
                                        ...pqr,
                                        relatedOrder: relatedOrder || null
                                    };
                                });
    
                                setCombinedPQRData(combinedData);
                                console.log("Combined PQR and Order Data:", combinedData);
                            } catch (error) {
                                console.error("Error procesando órdenes relacionadas:", error);
                            }
                        });
                    }
                } catch (error) {
                    console.error("Error procesando datos de PQR:", error);
                }
            });
            setGlobalLoading(false);
    
            setUnsubscribePQRs(() => unsubscribe);
        } catch (error) {
            console.error("Error en handleSelectPQRQuery:", error);
            setGlobalLoading(false);
        } finally {

            setGlobalLoading(false);
        }
    };
    const openItemOverlayPanel = async (rowData, e) => {
        setSelectedOrderRowData(rowData)
        console.log("rowData", rowData)
        opItems.current.toggle(e); // // Abre el overlay
    };
    const openOrderStatusOverlayPanel = async (rowData, e) => {
        setSelectedOrderRowData(rowData); // Guardar los datos de la fila seleccionada en el estado
        opOrderStatus.current.toggle(e); // // Abre el overlay
    };
    const openLogsOverlayPanel = (rowData, e) => {
        console.log("rowData", rowData)
        setSelectedOrderRowData(rowData); // Guardar los datos de la fila seleccionada en el estado
        opLogs.current.toggle(e); // // Abre el overlay
    };


    useEffect(() => {
        setGlobalLoading(true);
        const fetchZones = async () => {
            await handleSelectPQRQuery({code: 1, name: 'Recibidos'})
            const configZonesCollection = collection(db, "config");
            const zonesDocRef = doc(configZonesCollection, "zones");
            const docSnapshot = await getDoc(zonesDocRef);
            const zonesData = docSnapshot.data();
            setZones(zonesData.zones)
        };
        fetchZones()
        setGlobalLoading(false);
        return () => {
            if (unsubscribePQRs) unsubscribePQRs();
        };
    }, []);

    const orderColumns = OrderTableColumns({
        t,
        orderStates: [],
        stateColors: [],
        stateLabelColors,
        handleRowChange: () => {},
        openItemOverlayPanel,
        rowEditStates: [],
        shippingCompanies,
        handleConfirmOrderModal: () => {},
        toggleEdit: () => {},
        updateRowOrder: () => {},
        zones,
        rowCheckBox: [],
        onRowCheckBoxChange: () => {},
        onSelectAllRowsChange: () => {},
        selectAllRows: false,
        shippingAddresTypes,
        openLogsOverlayPanel,
        rowLoading: [],
        menu: [],
        menuItems: [],
        globalLoading,
        optionMenu: [],
        openOrderStatusOverlayPanel,
        orderPaymentStatus,
        isMobile: false,
        opItems: [],
        closeOverlayPanel: () => {},
        handleOpenWhatsappCRM: () => {},
        setVisibleConfirmCreatePQR: () => {}
    });

    const orderStatesTableColumns = [
        {
          field: "checkbox",
          filterable: false,
          sortable: false,
          body : ( orderState ) => {
             return (
                  <span >{formatDate(orderState?.created_at)}</span>
              );
          }
        },
         {
          field: "checkbox",
          filterable: false,
          sortable: false,
          body : ( orderState ) => {
             return (
                  <AuxStatusBadget
                        statusLabel={orderStates
                          .filter((state) => state.code === orderState?.state)
                          .map((matchingState) => (
                            <span
                              key={matchingState?.code}
                              style={{
                                backgroundColor: stateColors[matchingState.code],
                                color: "white",
                                padding: "4px 8px",
                                borderRadius: "4px",
                                margin: "4px 0",
                                display: "flex",
                              }}
                            >
                              {matchingState?.name}
                            </span>
                          ))}
                  />
              );
          }
        },
    ];

    console.log("combinedPQRData", combinedPQRData)
    const memoizedRowData = useMemo(() => combinedPQRData, [combinedPQRData]);
    
    return (
        <div className="grid gap-9 grid-cols-1 mb-14">
            <div className="mb-12">
                <CustomSelect
                    floatLabel={true}
                    optionLabel={"name"}
                    options={pqrQuerys}
                    value={selectedPQRType}
                    placeholder={t("input-placeholders.PQRQuery")}
                    onChange={(e) => handleSelectPQRQuery(e.value)}
                    required={true}
                />
            </div>
            
            {memoizedRowData?.map((pqrData, index) => {
                const pqrTableColumns = PQRTableColumns({t, pqrData, toast});
                return (
                <div  
                    key={`${pqrData.id}-${index}`}
                >
                    <PQRCardTables
                                    // Asegurar que la key tenga un fallback
                            ordersData={[pqrData.relatedOrder]} 
                            orderColumns={orderColumns}
                            pqrTableData={pqrData} 
                            pqrTableColumns={pqrTableColumns}
                        />
                        <div className="card flex flex-column align-items-center gap-3">
                            <OverlayPanel ref={opItems} showCloseIcon closeOnEscape={false} dismissable={false}>
                                <CustomTable 
                                    // index={`${pqrData.id}-${index}`}
                                    className="custom-table"
                                    columns={OrderitemsTableColumns({t, handleEdit: () => {}, selectedOrderRowData})}
                                    data={selectedOrderRowData?.line_items || []}
                                    action={() => {}} 
                                />

                                <div className="ml-10 mt-6 mb-4">
                                    <Button
                                        icon="pi pi-plus-circle"
                                        //   onClick={handleEdit}
                                        className="p-button-rounded p-button-outline"
                                    />
                                </div>
                            </OverlayPanel>
                            <div className="card flex flex-column align-items-center gap-3">
                                <OverlayPanel ref={opOrderStatus} showCloseIcon closeOnEscape dismissable={true}>
                                    <CustomTable
                                        // index={`${pqrData.id}-${index}`}
                                        data={sortArrayByCreatedAt(selectedOrderRowData?.states)}
                                        action={() => {}}
                                        columns={orderStatesTableColumns}
                                        className="custom-table"
                                    />
                                </OverlayPanel>
                            </div>
                        </div>
                    </div>)
            })}
            <div className="card flex flex-column align-items-center gap-3">
            <OverlayPanel ref={opLogs} showCloseIcon closeOnEscape dismissable={true}>
            <CustomTable 
                selectionMode={"single"}
                className="custom-table"
                columns={OrderGuideLogsTableColumns(t, selectedOrderRowData)}
                data={selectedOrderRowData?.guide_generation_msgs}
                action={() => {}} 
                />
            </OverlayPanel>
        </div>
            <Toast toast={toast}/>
        </div>
    );
}
