import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { db } from "../../../firebase/firebase";
import toastFunction from "../../../utils/toastFunction";
import getZones from "../../../utils/getZones.js";


// Función para combinar arrays y eliminar duplicados basados en una clave específica
const combineAndRemoveDuplicates = async (shippingDestinies, key) => {
  const combinedArray = shippingDestinies.concat(await getZones());

  // Utilizar un Map para eliminar duplicados basados en la clave específica
  const uniqueMap = new Map();

  combinedArray.forEach(item => {
    uniqueMap.set(item[key], item);
  });

  // Convertir el Map de vuelta a un array
  return Array.from(uniqueMap.values());
};

// Funciones para el modulo mis pedidos  "myOrders"
const getOrdersByValue = async (
  dates,
  selectedOptionToFind,
  valueToFindOrder,
  setOrders,
  setZones,
  toast,
  t,
) => {
  try {
    const ordersCol = collection(db, "orders");
    
    const configZonesCollection = collection(db, "config");
    const zonesDocRef = doc(configZonesCollection, "zones");
    const docSnapshot = await getDoc(zonesDocRef);
    const zonesData = docSnapshot.data();
    setZones(zonesData);


    let conditions = [];

    // Verificación del tipo de búsqueda
    if (selectedOptionToFind?.name === "checkoutId") {
      conditions.push(where('checkout_id', '==', valueToFindOrder));
    } else if (selectedOptionToFind?.name === "orderNumber") {
      conditions.push(where('order_number', '==', valueToFindOrder));
    }

    // Agregar rango de fechas si está disponible
    if (dates && dates.length === 2) {
      const startDate = dates[0];
      const endDate = dates[1];

      // Ajustar la fecha de inicio al comienzo del día
      const startDateAdjusted = new Date(startDate.setHours(0, 0, 0, 0));
      // Ajustar la fecha de fin al final del día
      const endDateAdjusted = new Date(endDate.setHours(23, 59, 59, 999));

      conditions.push(
        where("created_at", ">=", startDateAdjusted),
        where("created_at", "<=", endDateAdjusted)
      );
    }

    // Añadir siempre el orderBy después de las condiciones
    const q = query(ordersCol, ...conditions, orderBy("created_at", "asc"));

    // Suscribirse a la consulta de Firebase
    const unsubscribe = onSnapshot(q, async (snapshot) => {
      const orderDocuments = [];
      const shippingDestinies = [];

      snapshot.forEach((doc) => {
        const order = doc.data();
        if (order.shipping_destination) {
          shippingDestinies.push(order.shipping_destination);
        }
        orderDocuments.push(order);
      });
      // Actualizar los pedidos en el estado
      setOrders(orderDocuments);
    });

    return unsubscribe;
  } catch (error) {
    toastFunction(toast, "error", t("toast-summary.consultError"), "", 4000);
    return () => {}; // En caso de error, retorna una función vacía
  }
};


const getOrders = async (
  selectedOrderStates,
  dates,
  amountSellerStoresIds,
  attributesValues,
  selectAttributeOrders,
  setOrders,
  toast,
  t,
  checkRelatedSuplierIds,
  userBusinessId,
  valuesSKUS,
  valueMultiCheckboxGuidesGenerated,
  setVisibleOrderFilters,
  setZones,
  setGlobalLoading
) => {
  // console.log("Entre a getOrders")
  setGlobalLoading(true);
  let statesIds = selectedOrderStates?.map((state) => state.code);
  const limitStates = 20;

  if (statesIds && statesIds.length >= limitStates) {
    toastFunction(toast, "warn", `${t("toast-summary.warnStatesPart1")} ${limitStates} ${t("toast-summary.warnStatesPart2")}`,"",7000 );
    return;
  }

  const ordersRef = collection(db, "orders");
  let q = [];
  if (checkRelatedSuplierIds) {
    // console.log("LA CONSULTA SERA POR LOS PEDIDOS DE OTROS SELLERS ", userBusinessId);
    q = [...q, where("related_suppliers_ids", "array-contains", userBusinessId)]
  } else {
    // console.log("LA CONSULTA SERA POR MIS PEDIDOS ", userBusinessId);
    q = [...q, where("store_id", "in", amountSellerStoresIds)]
  }
  if (dates && dates.length === 2) {
    const startDate = dates[0];
    const endDate = dates[1];
    // Ajustar la fecha de inicio al comienzo del día
    const startDateAdjusted = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 0, 0, 0, 0);
    // Ajustar la fecha de fin al final del día
    const endDateAdjusted = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 23, 59, 59, 999);
    // Utilizar las fechas ajustadas en tu lógica
    const startDateFirebase = new Date(startDateAdjusted.getTime());
    const endDateFirebase = new Date(endDateAdjusted.getTime());
    // // console.log("Hay fechas");
    q = [
      ...q,
      where("created_at", ">=", startDateFirebase),
      where("created_at", "<=", endDateFirebase),
    ];
  }
  if (statesIds?.length > 0 ) {
    // console.log( "Hay estados ", amountSellerStoresIds,statesIds );
    q = [...q, where("last_state", "in", statesIds)];
  }
  if (selectAttributeOrders?.code === 3 && attributesValues !== "" ) {
    // console.log("Hay numeros de pedido ", attributesValues,);
    q = [...q, where("id", "==", attributesValues)];
  }
  if (selectAttributeOrders?.code === 2 &&attributesValues.length > 0 ) {
      // console.log("Hay numeros de pedido ", attributesValues,);
      q = [...q, where("order_number", "in", attributesValues)];
  }
  if (selectAttributeOrders?.code === 1 &&attributesValues.length > 0 ) {
      // console.log( "Hay numeros de guia", attributesValues, amountSellerStoresIds );
    q = [...q, where("shipping_guide_number", "in", attributesValues)];
  }

  if(valueMultiCheckboxGuidesGenerated !== "Todas"){
    if(valueMultiCheckboxGuidesGenerated === "Generadas"){
      // console.log("la conuslta sera se tambien por GUIDE GENERATED? ", valueMultiCheckboxGuidesGenerated)
      q = [...q, where("guide_generated", "==", true) ];
    }else if(valueMultiCheckboxGuidesGenerated === "Sin generar") {
      // console.log("la conuslta sera se tambien por GUIDE GENERATED? ", valueMultiCheckboxGuidesGenerated)
      q = [ ...q, where("guide_generated", "==", false) ];
    }
  }


 
  try {
    q = query(ordersRef, ...q, orderBy("created_at", "asc"));
    console.log("QUERY -> ", attributesValues, amountSellerStoresIds);
    console.log("QUERY -> ", q);

    const unsubscribe = onSnapshot(q, async (querySnapshot) => {
      const orderDocuments = [];
      const shippingDestinies = [];

      querySnapshot.forEach((docSnapshot) => {
        const order = docSnapshot.data();
        orderDocuments.push(order);
        if (order.shipping_destination) {
          shippingDestinies.push(order.shipping_destination);
        }
      });

      const uniqueZones = await combineAndRemoveDuplicates(shippingDestinies, 'city_name');
      setZones({ zones: uniqueZones });
      // console.log("Documentos ordenes", orderDocuments);

      // Filtramos los documentos por sku en el cliente si es necesario
      let filteredOrderDocuments = orderDocuments;
      if (valuesSKUS && valuesSKUS !== null && (valuesSKUS?.length !== 0 || typeof valuesSKUS === 'string')) {
        // console.log("la consulta sera tambien por SKU", valuesSKUS, Array.isArray(valuesSKUS));
        if (!Array.isArray(valuesSKUS)) {
          filteredOrderDocuments = orderDocuments.filter(order => order.line_items_skus.includes(valuesSKUS));
        } else {
          filteredOrderDocuments = orderDocuments.filter(order => valuesSKUS.some(sku => order.line_items_skus.includes(sku)));
        }
      }

      setOrders(filteredOrderDocuments);
      setGlobalLoading(false);
      if (filteredOrderDocuments.length === 0) {
        toastFunction(toast, "warn", t("toast-summary.consultEnough"), "", 4000);
      } else if (filteredOrderDocuments.length > 0) {
        setVisibleOrderFilters(false);
      }
    });
    return unsubscribe;
  } catch (error) {
    toastFunction(toast ,"error", t("toast-summary.consultError"), "", 4000,);
    setGlobalLoading(false);
    // console.error("Error en la consulta:", error);
  }
  
};
const getAndAgroupItemsNotSyncedInOrders = async (amountSellerStoresIds, setItemsDataTable) => {
  try {
    const q = query(
      collection(db, "orders"),
      where("store_id", "in", amountSellerStoresIds),
      where("order_with_no_synced_items", "==", true),
      orderBy("created_at", "asc")
    );
    let unsubscribeOrders = await onSnapshot(q, (querySnapshot) => {
    const groupedItems = {};

      if (querySnapshot.size !== 0) {
        const orders = querySnapshot.docs.map((docSnapshot) => docSnapshot.data());

        orders.forEach((order) => {
          order.line_items.forEach((item) => {
            if (!item.product_success_match) {
              const keyShopifyProductId = item.synced_product_id;
              const shopifyProductName = item.product_title;
              if (!groupedItems[keyShopifyProductId]) {
                groupedItems[keyShopifyProductId] = [];
              }
              groupedItems[keyShopifyProductId].push({ item, orderId: order.id, shopifyProductName});
            }
          });
        });
        const groupItemsDataTableArray = Object.entries(groupedItems).map(([shopifyId, itemsArray]) => {
          const shopifyProductName = itemsArray[0]?.shopifyProductName; // Obten el nombre del producto del primer elemento del array
          return {
            id: shopifyId,
            size: itemsArray.length,
            productToVinculate: {},
            itemsArray,
            name: `${shopifyProductName} - ${shopifyId}`, // Agrega el nombre del producto al nombre del grupo
          };
        });

        const itemsNoSynced = Object.values(groupItemsDataTableArray);
        // console.log("ANTES DE ACTUALIZAR ordenes e itemtabladata", orders, itemsNoSynced);
        setItemsDataTable(itemsNoSynced);
      } else {
        // // console.log("badnera ELSE");
        // Si no hay pedidos con elementos no sincronizados, establece itemsNoSynced en un array vacío
        setItemsDataTable([]);
      }
    });
    return unsubscribeOrders
  } catch (error) {
    console.error("Error al obtener los pedidos:", error);
    throw error; // Puedes manejar el error según tus necesidades
  }
};
const getOrdersByCleanedFilters = async (
  arrayStoresIds,
  setOrders,
  toast,
  t,
  businessId,
  setZones,
  setGlobalLoading
) => {
  try {
    const ordersCol = collection(db, "orders");
    setGlobalLoading(true)

    // Si no se proporcionan fechas, usar el rango de "hoy"
    let q = [];

    if (arrayStoresIds && arrayStoresIds.storesIds.length > 0) {
      if(arrayStoresIds.storesIds.length < 20) {
        q = query(
          ordersCol,
          where("store_id", "in", arrayStoresIds.storesIds),
          orderBy("created_at", "asc")
        );
      } else {
        q = query(
          ordersCol,
          where("related_seller_business_id", "==", businessId),
          orderBy("created_at", "asc")
        );
      }
    }

    // Aquí envolvemos el onSnapshot en una promesa
    const unsubscribe = onSnapshot(q, async (snapshot) => {
      const ordersDocuments = [];
      const shippingDestinies = [];

      snapshot.forEach((doc) => {
        const order = doc.data();
        if (order.shipping_destination) {
          shippingDestinies.push(order.shipping_destination);
        }
        ordersDocuments.push(order);
      });

      const uniqueZones = await combineAndRemoveDuplicates(shippingDestinies, 'city_name');
      setZones({ zones: uniqueZones });

      // Esperamos a que setOrders termine
      await new Promise((resolve) => {
        setGlobalLoading(false)
        setOrders(ordersDocuments);
        resolve();
      });
    });

    return unsubscribe;
  } catch (error) {
    toastFunction(toast, "error", t("toast-summary.consultError"), "", 4000);
    return () => {}; // En caso de error, retorna una función vacía
  }
};

const getOrdersByIdsDateDefault = async (
  arrayStoresIds,
  setZones,
  setOrders,
  toast,
  t,
   businessId,
) => {
  try {

    const configZonesCollection = collection(db, "config");
    const zonesDocRef = doc(configZonesCollection, "zones");
    const docSnapshot = await getDoc(zonesDocRef);
    const zonesData = docSnapshot.data();
    const ordersCol = collection(db, "orders");
    // Si no se proporcionan fechas, usar el rango de "hoy"
    const hoy = new Date();
    let initialDate = hoy;
    const finDelDia = new Date();
    hoy.setHours(0, 0, 0, 0); // Establecer hora a las 00:00:00
    finDelDia.setHours(23, 59, 59, 999); // Establecer hora a las 23:59:59
    let finishDate = finDelDia;
    let q = [
      where("created_at", ">=", initialDate),
      where("created_at", "<=", finishDate)];

    if (arrayStoresIds && arrayStoresIds?.length > 0) {
      if(arrayStoresIds?.length < 20) {
        q = query(
          ordersCol,
          ...q,
          where("store_id", "in", arrayStoresIds),
          orderBy("created_at", "asc")
        );
      }else {
        q = query(
          ordersCol,
          ...q,
          where("related_seller_business_id", "==", businessId),
          orderBy("created_at", "asc")
        );
      }

    }
    const unsubscribe = onSnapshot(q, async (snapshot) => {
      const orderDocuments = [];
      const shippingDestinies = [];
      snapshot.forEach((doc) => {
        const order = doc.data();
        orderDocuments.push(order);
        if (order.shipping_destination) {
          shippingDestinies.push(order.shipping_destination);
        }
      });
      // Actualizar el estado con los arrays únicos
      setZones(zonesData);
      setOrders(orderDocuments); // Actualizar el estado de los pedidos (si es necesario)
    });
    return unsubscribe
  } catch (error) {
    // console.log(error, arrayStoresIds)
    // toastFunction(toast, "error", t("toast-summary.consultError"), error.message, 4000);
    return () => {}; // En caso de error, retorna una función vacía
  }
};
const getTrackedNews = async (
  isDefault,
  initialDate,
  finishDate,
  setOrderNews,
  toast,
  t,
  businessId,
  guideNumber,
  shippingCompanyCode,
  shippingNewCode,
  stateNewCode,
) => {
  try {
    const ordersCol = collection(db, "traked_orders");
    // Si no se proporcionan fechas, usar el rango de "hoy"
    let q = [ where("related_seller_business_id", "==", businessId)];
      // // console.log("Validacion",  stateNewCode, businessId, isDefault,initialDate,finishDate, guideNumber, shippingCompanyCode, shippingNewCode)
      // Si el estado es "Aprobado" no se buscan documentos con novedades por resolver
      if(stateNewCode && stateNewCode ===3 ) {
          q = [...q, where("order_with_unresolved_shipping_news", "==", false)]
      }else {
          q = [...q, where("order_with_unresolved_shipping_news", "==", true)]
      }
      if (!isDefault) {
        if(guideNumber || guideNumber !== "" ) {
          q = [...q, where("shipping_guide_number", "==", guideNumber)]
        }
        if(shippingCompanyCode) {
          q = [...q, where("shipping_company", "==", shippingCompanyCode)]
        }
        if(shippingNewCode) {
          q = [...q, where("last_shipping_new.new.code", "==", shippingNewCode)]
        }
        if(stateNewCode) {
          q = [...q, where("last_shipping_new.state.code", "==", stateNewCode)]
        }
      }
      if (initialDate || finishDate) {
        q = [...q , where("last_shipping_new.created_at", ">=", initialDate), where("created_at", "<=", finishDate)];
        q = query( ordersCol, ...q , orderBy("last_shipping_new.created_at", "asc"));

      }else {
        q = query( ordersCol, ...q , orderBy("created_at", "asc"));
      }
    const unsubscribe = onSnapshot(q, async (snapshot) => {
      const orderNews = [];
      snapshot.forEach((doc) => {
        const order = doc.data();
        if (order.last_shipping_new) {
          orderNews.push(order.last_shipping_new);
        }
      });
      setOrderNews(orderNews); // Actualizar el estado de los pedidos (si es necesario)
    });
    // setUnsubscribeOrderStateSnapshot(unsubscribe);
    return unsubscribe;
  } catch (error) {
    toastFunction(toast, "error", t("toast-summary.consultError"), "", 4000);
    return () => {}; // En caso de error, retorna una función vacía
  }
};
const getSellerProducts = async (bussinesId, setProductsData, toast, t) => {
  // console.log("bussinesId", bussinesId)
  try {
    const productsCol = collection(db, "products");
    const q = query(
      productsCol,
      where("commercial_partner_id", "==", bussinesId),
      orderBy("created_at", "desc")
    );
    const unsubscribe = onSnapshot(q, async (snapshot) => {
      const sellerProducts = [];
      snapshot.forEach((doc) => {
        const product = doc.data();
        if (product.id) {
          sellerProducts.push(product);
        }
      });
      setProductsData(sellerProducts); // Actualizar el estado de los pedidos (si es necesario)
    });
    return () => unsubscribe
  } catch (error) {
    toastFunction(toast, "error", t("toast-summary.consultError"), "", 4000);
    return () => {}; // En caso de error, retorna una función vacía
  }
};


// Funciones para pedidos consolidados "consolidatedOrders"
const getConsolidatedOrders = async (setConsolidatedOrdersData, businessId) => {
  const ordersCol = collection(db, "consolidated_orders");
  let q = [
    where("commercial_partner_id", "==", businessId)
  ];
  // // console.log("businessId", businessId)
  q.push(orderBy("created_at", "asc"));
  const finalQuery = query(ordersCol, ...q);

  const unsubscribe = onSnapshot(finalQuery, async (snapshot) => {
    const consolidatedOrders = [];
    snapshot.forEach((doc) => {
      const order = doc.data();
    // // console.log("order", order)

      consolidatedOrders.push(order);
    });
    // // console.log("consolidatedOrders", consolidatedOrders)
    setConsolidatedOrdersData(consolidatedOrders);
})
  return unsubscribe;
}
const getOrdersToConsolidate = async (
  arrayStoresIds,
  setZonesData,
  setOrdersData,
  businessId,
  toast, t
) => {
  try {
  const ordersCol = collection(db, "orders");
  let q = [
    where("last_state", "in", [1, 2]), // ordenes cuyo ultimo estado estado sea "Recibido" o "en preparacion"
    where("business_with_consolidate_pending", "array-contains", businessId), // cuyo bisness id aun tiene items por consolidar
    where("order_is_confirmed", "==", true) // y se encuentra confirmada
  ];
  if (arrayStoresIds && arrayStoresIds?.length > 0 ) {
    if (arrayStoresIds.length <= 10) {
      q.push(where("store_id", "in", arrayStoresIds)); // Buscar por tiendas
    }
    //  else {   
    //   q.push(where("related_seller_business_id", "==", businessId)); // Buscar por business id
    // }
  }
  q.push(orderBy("created_at", "asc"));

  const finalQuery = query(ordersCol, ...q);

  const unsubscribe = onSnapshot(finalQuery, async (snapshot) => {
    const ordersToConsolidate = [];
    const shippingDestinies = [];
    snapshot.forEach((doc) => {
      const order = doc.data();
      const relatedItems = order?.line_items.filter((item) => (item?.related_commercial_partner_id === businessId && !item?.consolidated_item) );

      if (relatedItems.length > 0) {
        const orderWithRelatedItems = {...order, line_items : relatedItems}
        ordersToConsolidate.push(orderWithRelatedItems);
      }
      if (order.shipping_destination) {
        shippingDestinies.push(order.shipping_destination);
      }
    });

    const uniqueZones = await combineAndRemoveDuplicates(shippingDestinies, 'city_name');
    setZonesData({ zones: uniqueZones });
    setOrdersData(ordersToConsolidate);
    if(ordersToConsolidate.length === 0) {
      toastFunction(toast, "info", t("No hay ordenes para consolidar con esta consulta"), "", 3000);
    }
  });

  return unsubscribe;
} catch (error) {
  toastFunction(toast, "error", t("toast-summary.consultError"), "", 4000);
  return () => {}; // En caso de error, retorna una función vacía
}

};

const getSellerUsersOwners = async (setSellerUsersData) => {
  const sellerColl = collection(db, "seller_users");
  let q = [
    where("uid", "!=", null),
    where("isAdmin", "==", true)
  ];
  q.push(orderBy("created_at", "asc"));
  const finalQuery = query(sellerColl, ...q);

  const unsubscribe = onSnapshot(finalQuery, async (snapshot) => {
    const sellerUsersDocuments = [];
    snapshot.forEach((doc) => {
      const sellerUser = doc.data();
      sellerUsersDocuments.push(sellerUser);
    });

    setSellerUsersData(sellerUsersDocuments);
})
  return unsubscribe;
}


const getOrdersAdmin = async (
  selectedOrderStates,
  sellerBusinessId,
  supplierId,
  dates,
  attributesValues,
  selectAttributeOrders,
  setOrders,
  toast,
  t,
  multiSelectOptions,
  valuesSKUS,
  valueMultiCheckboxGuidesGenerated,
  setVisibleOrderFilters
) => {
  // console.log("entre a getOrdersAd")
  let statesIds = selectedOrderStates?.map((state) => state.code);
  const limitStates = 4;
  if (statesIds && statesIds.length >= limitStates) {
    toastFunction(toast, "warn", `${t("toast-summary.warnStatesPart1")} ${limitStates} ${t("toast-summary.warnStatesPart2")}`,  "",7000);
    return;
  }
  const ordersRef = collection(db, "orders");

  let q = [];
  let newQuery = [];
  let qMultiSelect = [];
  let querySellerId = [];
  let queryBySellerIdAndRelatedSupplierIds = false;
  try {
    if (multiSelectOptions.includes(1) && !multiSelectOptions.includes(2)  && sellerBusinessId && !supplierId) {
      // console.log("LA CONSULTA SERA SOLO POR SELLER ID");
      querySellerId = [where("related_seller_business_id", "==", sellerBusinessId)];

    } else if ( !multiSelectOptions.includes(1) && multiSelectOptions.includes(2)  && sellerBusinessId && !supplierId) {
      // console.log("LA CONSULTA SERA SOLO POR RELATED SUPPLIER IDS");
      qMultiSelect = [where("related_suppliers_ids", "array-contains", supplierId )];

    } else if (multiSelectOptions.includes(1 && 2) && sellerBusinessId && supplierId) {
      // console.log("LA CONSULTA SERA POR SellerId y por relatedSupplier");
      qMultiSelect = [
        where("related_suppliers_ids", "array-contains", supplierId ),
        ];
      querySellerId = [
        where("related_seller_business_id", "==", sellerBusinessId)
      ];
      queryBySellerIdAndRelatedSupplierIds = true;

    } else if (!multiSelectOptions.includes(1 && 2) && !sellerBusinessId && !supplierId) {
      // console.log("EL QUERY NO TENDRA EN CUENTA EL SELECT")
    }

    if (dates && dates.length === 2) {
      // console.log("Si hay fechas");
      const startDate = dates[0];
      const endDate = dates[1];
      // Ajustar la fecha de inicio al comienzo del día
      const startDateAdjusted = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 0, 0, 0, 0);
      // Ajustar la fecha de fin al final del día
      const endDateAdjusted = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 23, 59, 59, 999);
      // Utilizar las fechas ajustadas en tu lógica
      const startDateFirebase = new Date(startDateAdjusted.getTime());
      const endDateFirebase = new Date(endDateAdjusted.getTime());

      newQuery = [ ...newQuery, where("created_at", ">=", startDateFirebase), where("created_at", "<=", endDateFirebase),];
      q = [ ...q, where("created_at", ">=", startDateFirebase), where("created_at", "<=", endDateFirebase) ];
    }
    if (statesIds?.length > 0 ) {
      // console.log("Hay estados", statesIds);
      newQuery = [...newQuery, where("last_state", "in", statesIds)];
      q = [...q, where("last_state", "in", statesIds)];
    }
    if (selectAttributeOrders?.code === 2 && attributesValues?.length > 0) {
        // console.log("Hay  numeros de pedido", attributesValues);

        newQuery = [...newQuery, where("order_number", "in", attributesValues)];
        q = [ ...q , where("order_number", "in", attributesValues)];
    }
    else if (selectAttributeOrders?.code === 1 && attributesValues?.length > 0) {
        console.log( "Hay numeros de guia", attributesValues, sellerBusinessId );

        newQuery = [...newQuery, where("shipping_guide_number", "in", attributesValues),];
        q = [...q, where("shipping_guide_number", "in", attributesValues),];
    }
    if(valuesSKUS && valuesSKUS !== null && (valuesSKUS?.length !== 0 ||  typeof valuesSKUS === 'string')){
      console.log("la consulta sera tambien por SKU", valuesSKUS, Array.isArray(valuesSKUS))
      if(!Array.isArray(valuesSKUS)){
        q = [...q, where("line_items_skus", "array-contains", valuesSKUS)];
      }else {
        q = [...q, where("line_items_skus", "array-contains-any", valuesSKUS)];
      }
    }
    if(valueMultiCheckboxGuidesGenerated !== "Todas"){
      if(valueMultiCheckboxGuidesGenerated === "Generadas"){
        console.log("la conuslta sera se tambien por GUIDE GENERATED? ", valueMultiCheckboxGuidesGenerated)

        q = [...q, where("guide_generated", "==", true)];
        newQuery = [...newQuery,  where("guide_generated", "==", true)]
      }else if(valueMultiCheckboxGuidesGenerated === "Sin generar") {
        console.log("la conuslta sera se tambien por GUIDE GENERATED? ", valueMultiCheckboxGuidesGenerated)

        q = [...q, where("guide_generated", "==", false)];
        newQuery = [...newQuery,  where("guide_generated", "==", false)]
      }
    }

    try {
      if (queryBySellerIdAndRelatedSupplierIds) {
        console.log("Entre al query de queryBySellerIdAndRelatedSupplierIds", queryBySellerIdAndRelatedSupplierIds);
    
        const queryFilterStores = query(ordersRef, ...newQuery, ...querySellerId, orderBy("created_at", "asc"));
        const queryMultiSelect = query(ordersRef, ...newQuery, ...qMultiSelect, orderBy("created_at", "asc"));
    
        let unsubscribeSellerBusinessId;
        let unsubscribeMultiSelect;
    
        const handleSnapshot = (resultFilterStores, resultMultiSelect) => {
          const idSet = new Set();
          let documents = [];
    
          resultFilterStores.docs.forEach((doc) => {
            const data = doc.data();
            if (!idSet.has(data.id)) {
              idSet.add(data.id);
              documents.push(data);
            }
          });
    
          resultMultiSelect.docs.forEach((doc) => {
            const data = doc.data();
            if (!idSet.has(data.id)) {
              idSet.add(data.id);
              documents.push(data);
            }
          });
    
          const uniqueDocuments = Array.from(new Set(documents.map(order => order.id)))
            .map(id => documents.find(order => order.id === id));
    
          let filteredOrders = uniqueDocuments.filter(order => idSet.has(order.id));
          filteredOrders.sort((a, b) => a.created_at - b.created_at);
    
          if (valuesSKUS && valuesSKUS.length > 0) {
            filteredOrders = filteredOrders.filter((document) => {
              return document.line_items_skus.some((sku) => valuesSKUS.includes(sku));
            });
          }
    
          // console.log("Documentos ordenes filtradas master >", filteredOrders);
          setOrders(filteredOrders);
    
          if (documents.length === 0) {
            toastFunction(toast, "warn", t("toast-summary.consultEnough"), "", 4000);
          } else if (documents.length > 0) {
            setVisibleOrderFilters(false);
          }
        };
    
        unsubscribeSellerBusinessId = onSnapshot(queryFilterStores, (resultFilterStores) => {
          unsubscribeMultiSelect = onSnapshot(queryMultiSelect, (resultMultiSelect) => {
            handleSnapshot(resultFilterStores, resultMultiSelect);
          });
        });
    
        return () => {
          if (unsubscribeSellerBusinessId) unsubscribeSellerBusinessId();
          if (unsubscribeMultiSelect) unsubscribeMultiSelect();
        };
      } else {
        // console.log("Entre al query por default ", q, qMultiSelect);
        q = query(ordersRef, ...q, ...qMultiSelect, orderBy("created_at", "asc"));
        // console.log(q);
        
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
          // console.log("Cambios en el snapshot individual ADMIN");
          const documents = [];
          querySnapshot.forEach((docSnapshot) => {
            documents.push(docSnapshot.data());
          });
          setOrders(documents);
    
          if (documents.length === 0) {
            toastFunction(toast, "warn", t("toast-summary.consultEnough"), "", 4000);
          } else if (documents.length > 0) {
            setVisibleOrderFilters(false);
          }
          // console.log("Entre al query de else, solo mis pedidos", qMultiSelect, q);
        });
    
        return unsubscribe;
      }
    } catch (error) {
      // console.error("Error en la consulta:", error);
    }
    
  } catch (error) {
    toastFunction(toast,  "error", t("toast-summary.consultError"), "", 4000);
    throw new Error("Error al obtener documentos");
  }
};
const getOrdersToConsolidateAdmin = async (
  selectedSellerBusinessId,
  setZonesData,
  setOrdersData,
  toast,
  t,
) => {
  try {
  const ordersCol = collection(db, "orders");
  let q = [
    where("last_state", "in", [1, 2]), // Si el el ultimo estado de la orden es "Recibio" o "En preparacion"
    where("order_is_confirmed", "==", true),
    where("business_with_consolidate_pending", "array-contains", selectedSellerBusinessId)
  ];

  q.push(orderBy("created_at", "asc"));

  const finalQuery = query(ordersCol, ...q);
  const unsubscribe = onSnapshot(finalQuery, async (snapshot) => {
    const ordersToConsolidate = [];
    const shippingDestinies = [];
    snapshot.forEach((doc) => {
      const order = doc.data();
      const relatedItems = order?.line_items.filter((item) => (item?.related_commercial_partner_id === selectedSellerBusinessId && !item?.consolidated_item) );

      if (relatedItems.length > 0) {
        const orderWithRelatedItems = {...order, line_items : relatedItems}
        ordersToConsolidate.push(orderWithRelatedItems);
      }
      if (order.shipping_destination) {
        shippingDestinies.push(order.shipping_destination);
      }
    });

    const uniqueZones = await combineAndRemoveDuplicates(shippingDestinies, 'city_name');

    setZonesData({ zones: uniqueZones });
    setOrdersData(ordersToConsolidate);
  });

  return unsubscribe;
} catch (error) {
  toastFunction(toast, "error", t("toast-summary.consultError"), "", 4000);
  return () => {}; // En caso de error, retorna una función vacía
}

};
const getConsolidatedOrdersAdmin = async (selectedSellerBusinessId, setConsolidatedOrdersData ) => {
  const ordersCol = collection(db, "consolidated_orders");
  let q = [
    where("commercial_partner_id", "==", selectedSellerBusinessId)

  ];
  q.push(orderBy("created_at", "asc"));
  const finalQuery = query(ordersCol, ...q);

  const unsubscribe = onSnapshot(finalQuery, async (snapshot) => {
    const consolidatedOrders = [];
    snapshot.forEach((doc) => {
      const order = doc.data();
      consolidatedOrders.push(order);
    });
    setConsolidatedOrdersData(consolidatedOrders);
})

  return unsubscribe;
}

// Funciones para el Insider
const getOrdersByCleanedFiltersAdmin = async (
  setOrders,
  toast,
  t,
  setGlobalLoading
) => {
  try {
    setGlobalLoading(true)
    const ordersCol = collection(db, "orders");
    let q;
    q = query(
      ordersCol,
      orderBy("created_at", "asc")
    );

    // Envolvemos el onSnapshot en una promesa para esperar que setOrders termine
    const unsubscribe = onSnapshot(q, async (snapshot) => {
      const orderDocuments = [];

      snapshot.forEach((doc) => {
        const order = doc.data();
        orderDocuments.push(order);
        console.log(order);
      });

      if (orderDocuments.length === 0) {            
        toastFunction(toast, "warn", t("toast-summary.consultEnough"), "", 4000);
      }

      // Esperamos a que setOrders termine de actualizar el estado
      await new Promise((resolve) => {
        setGlobalLoading(false)
        setOrders(orderDocuments);
        resolve();
      });
    });

    return unsubscribe;
  } catch (error) {
    setGlobalLoading(false)
    toastFunction(toast, "error",  t("toast-summary.consultError"), "", 4000);
    return () => {}; // En caso de error, retorna una función vacía
  }
};

const getOrdersDateDefaultAdmin = async (
  initialDate,
  finishDate,
  setZones,
  setOrders,
  toast,
  t,
) => {
 
  try {
    const configZonesCollection = collection(db, "config");
    const zonesDocRef = doc(configZonesCollection, "zones");
    const docSnapshot = await getDoc(zonesDocRef);
    const ordersCol = collection(db, "orders");
    // Si no se proporcionan fechas, usar el rango de "hoy"
    if (!initialDate || !finishDate) {
      const hoy = new Date();
      hoy.setHours(0, 0, 0, 0); // Establecer hora a las 00:00:00
      initialDate = hoy;
      const finDelDia = new Date();
      finDelDia.setHours(23, 59, 59, 999); // Establecer hora a las 23:59:59
      finishDate = finDelDia;
    }
    let q;
      q = query(
        ordersCol,
        where("created_at", ">=", initialDate),
        where("created_at", "<=", finishDate),
        orderBy("created_at", "asc")
      );
    const unsubscribe = onSnapshot(q, async (snapshot) => {
      const orderDocuments = [];
      const shippingDestinies = [];
      snapshot.forEach((doc) => {
        const order = doc.data();
        orderDocuments.push(order);
        if (order.shipping_destiny) {
          shippingDestinies.push(order.shipping_destiny);
        }
      });

      const zonesData = docSnapshot.data();
      const combinedArray = shippingDestinies.concat(zonesData.zones);
      // Eliminar elementos duplicados del array combinado
      const uniqueZones = Array.from(
        new Set(combinedArray.map(JSON.stringify))
      ).map(JSON.parse);
      // Actualizar el estado con los arrays únicos
      setZones({ zones: uniqueZones });
      setOrders(orderDocuments); // Actualizar el estado de los pedidos (si es necesario)
    });
    return unsubscribe;
  } catch (error) {
    toastFunction(toast, "error",  t("toast-summary.consultError"), "", 4000);
    return () => {}; // En caso de error, retorna una función vacía
  }
};
const getSellerProductByIdAdmin = async (productId, setProductsData, setVariationsData, toast, t) => {
  try {
      const productRef = doc(db, "products", productId);
      const productSnapshot = await getDoc(productRef);
      if (productSnapshot.exists()) {
          const productData = productSnapshot.data();
          setProductsData([productData]); // Actualizar el estado del producto
          if (productData.variations) {
              setVariationsData(productData.variations);
          }

      } else {
          // No se encontró el producto con el ID dado
          // console.log("No se encontró el producto con el ID:", productId);
      }
  } catch (error) {
      // Manejo de errores
      // console.error("Error al obtener el producto:", error);
      toastFunction(toast, "error", t("toast-summary.consultError"), "", 4000);
  }
};


export { 
  getOrdersByValue,
  getOrders,
  getOrdersByIdsDateDefault,
  getSellerProducts,
  getAndAgroupItemsNotSyncedInOrders,
  getOrdersDateDefaultAdmin,
  getSellerProductByIdAdmin,
  getOrdersAdmin,
  getOrdersByCleanedFilters,
  getOrdersByCleanedFiltersAdmin,
  getTrackedNews,
  getOrdersToConsolidate,
  getOrdersToConsolidateAdmin,
  getConsolidatedOrders,
  getConsolidatedOrdersAdmin,
  getSellerUsersOwners
};
