import  { useState } from 'react'
import { useGlobalContext } from '../../contexts/GlobalContext/GlobalContext';
import styles from './Login.module.css';
import CustomInputText from '../../Components/FormComponents/CustomInputText/CustomInputText';
import CustomSelect from '../../Components/FormComponents/CustomSelect/CustomSelect';
import CustomConfirmDialog from '../../Components/OverlayComponents/CustomConfirmDialog/CustomConfirmDialog';
import MainButton from '../../Components/MainButton/MainButton';
import ThirdButton from '../../Components/ThirdButton/ThirdButton';
import { Toast } from 'primereact/toast';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useLoaderContext } from '../../contexts/LoaderContext/LoaderContext';
import useAuth from '../../hooks/useAuth';
// import SecondaryButton from '../../Components/SecondaryButton/SecondaryButton';
import ContinueWithGoogleBtn from '../../Components/ContinueWithGoogleBtn/ContinueWithGoogleBtn';

export default function Login({recaptchaVerifier}) {
  const { toast, type, smsCodeAsk, setSmsCodeAsk, countdown, isResendDisabled, signInWithGoogle, signInWithEmailHook, resetPassword, signInWithPhoneHook, verifySMSHook, createUser } = useAuth();
  const { t } = useTranslation('Login');
  const navigate = useNavigate();
  const {countries} = useGlobalContext();
  const {globalLoading, setGlobalLoading} = useLoaderContext();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmDialogVisible, setConfirmDialogVisible] = useState(false);
  const [country, setCountry] = useState('');
  const [cellphone, setCellphone] = useState('');
  const [smsCode, setSmsCode] = useState("");
  const [signupWithPhone, setSignupWithPhone] = useState(false);
  const userData = {
    type,
    country,
    country_name: country.name?? null,
    country_code: country.code?? null,
    signupWithPhone,
    cellphone,
    ux_type: "online_seller"
  }

  const signInWithEmail = async (e) => {
    e.preventDefault();
    setGlobalLoading(true)
    console.log("antes de signupWithPhone", userData, email, password)
    await signInWithEmailHook(email, password)
    setGlobalLoading(false);
  }

  const signUpWithPhoneNumber = async (e) => {
    e.preventDefault();
    setGlobalLoading(true)
    const phoneNumberWithCountryCode = `${country.code}${cellphone}`
    await signInWithPhoneHook(phoneNumberWithCountryCode, recaptchaVerifier)
  };

  const resendSMSCode = (e) => {
    signUpWithPhoneNumber(e);
  };

  const verifySMS = async (e) => {
    e.preventDefault();
    let res = await verifySMSHook(smsCode)
    if(res.success){
      userData.uid = res.uid;
      await createUser("auth/signupWithPhone", userData);
    }
  };

  const confirmResetPassword = async () => {
    setConfirmDialogVisible(false)
    await resetPassword(email)
  };

  const rejectResetPassword = () => {
    setConfirmDialogVisible(false)
  };

  return (
    <div className={styles.loginContainer}>
      <h2>Iniciar sesion</h2>

      {
        !smsCodeAsk?
        <>
          <form onSubmit={signupWithPhone?signUpWithPhoneNumber:signInWithEmail}>

            {
              signupWithPhone===true?
              <>
                <CustomSelect    required={false} floatLabel={true} optionLabel="name" options={countries} valueTemplate="" itemTemplate="" value={country} placeholder="Pais" onChange={(e) => setCountry(e.value)} />
                <CustomInputText required={true} floatLabel={true} type="number" value={cellphone} placeholder={`${country.code??""} Celular`} onChange={(e) => setCellphone(e.target.value)} />
              </>
              :
              <>
                <CustomInputText data-testid="input-email" required={true} floatLabel={true} type="email" value={email} placeholder="Correo" onChange={(e) => setEmail(e.target.value)} />
                <CustomInputText data-testid="input-password" required={true} floatLabel={true} type="password" value={password} placeholder="Contraseña" onChange={(e) => setPassword(e.target.value)} />
              </>
            }
            <div style={{marginTop:"1rem"}} className={`clickable`}>
              <MainButton data-testid="btn-ingresar" loading={globalLoading} type={"submit"} label={"INGRESAR"}/>
            </div>
          </form>

          <ContinueWithGoogleBtn icon={"pi pi-google"} onClick={()=>signInWithGoogle()} label={"CONTINUAR CON GOOGLE"} />

          <div style={{marginLeft:"auto", marginRight:"auto", textAlign:"center"}}>
            {
              signupWithPhone===true?
              <div>
                <ThirdButton style={{maxWidth:"max-content"}} onClick={() => setSignupWithPhone(false)} label={"Ingresar con email"} />
              </div>
              :
              <div>
                <ThirdButton style={{maxWidth:"max-content"}} onClick={() => setSignupWithPhone(true)} label={"Ingresar con celular"} />
              </div>
            }
            <div>
              <ThirdButton style={{maxWidth:"max-content"}} onClick={() => setConfirmDialogVisible(true)} label={"Olvide mi contraseña"} />
            </div>
            <div>
              <ThirdButton style={{maxWidth:"max-content"}} onClick={() => navigate('/signup')} label={"Registrarme"} />
            </div>
          </div>
        </>

        :

        <>
          <form onSubmit={verifySMS}>
              <h4 style={{marginBottom:"10px"}}>Ingresa el codigo que recibiste en {country.code} {cellphone}</h4>
              <CustomInputText required={true} floatLabel={true} type="number" value={smsCode} placeholder="Codigo SMS" onChange={(e) => setSmsCode(e.target.value)} />
              <div className={`fixed-footer clickable`}>
                  <MainButton loading={globalLoading} type={"submit"} label={"VERIFICAR CODIGO"}/>
              </div>
          </form>
          <div style={{marginLeft:"auto", marginRight:"auto", textAlign:"center"}}>
              <div>
                  <ThirdButton style={{maxWidth:"max-content"}} onClick={(e)=>resendSMSCode(e)} label={countdown > 0 ? `Reenviar código (${countdown} s)` : "Reenviar código"} disabled={isResendDisabled}/>
              </div>
              <div>
                  <ThirdButton style={{maxWidth:"max-content"}} onClick={()=>setSmsCodeAsk(false)} label={"Cambiar numero telefonico"} />
              </div>
          </div>
        </>
      }

      <Toast ref={toast} />

      <CustomConfirmDialog
        visible={confirmDialogVisible}
        onHide={() => setConfirmDialogVisible(false)}
        header="Restablecer contraseña"
        message="Recibiras un correo electronico con instrucciones para cambiar tu contraseña"
        icon="pi pi-info-circle"
        accept={confirmResetPassword}
        reject={rejectResetPassword}
        acceptLabel='ENVIAR CORREO'
        rejectLabel='VOLVER'
        />
    </div>
  )
}
