import { useState } from "react";

export default function CustomInputControlled({ label, value, onChange, placeholder }) {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <div className="relative w-full">
      <input
        type="text"
        value={value}
        onChange={onChange}
        onFocus={() => setIsFocused(true)}
        onBlur={(e) => setIsFocused(e.target.value !== "")}
        className="w-full px-3 pt-5 pb-2 border border-green-400 rounded-full outline-none focus:ring-2 extra-bold  focus:ring-green-300 transition-all"
      />
      <label
        className={` absolute left-3 top-1/2 transform -translate-y-1/2 text-green-500 extra-bold transition-all ${
          isFocused || value ? "text-sm top-2 text-green-500" : "text-base top-1/2"
        }`}
      >
        {label}
      </label>
    </div>
  );
}
